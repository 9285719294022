import React from 'react'
import HeroImage from '../../../images/public/15.png'

const Hero = () => {
  return (
    <div className='public_home_hero_wrapper colorprimarybg'>
      <div className='public_home_hero_container'>
        <div className='public_home_hero_row'>
            <div className='public_home_hero_text'>
                <h3 className='colorwhite'>Customer Support</h3>
                <p className='colorwhite'>We're here to assist you and provide the support you need. Whether you have questions, concerns, or require assistance with our products or services, our dedicated team is ready to help.</p>
            </div>
            <div className='public_home_hero_image'>
                <img src={HeroImage} alt='FAQ Hero'/>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
