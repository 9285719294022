import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Functions from './funtions'
import Table from './userTable'
import Edit from './edit'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
    const [userList,setUserList] = useState([]);
    const [selectedUserID,setSelectedUserID] = useState(null); 
    const [showEdit,setShowEdit] = useState(false); 
    useEffect(()=>{
        Functions.Get(setUserList);
    },[])
  return (
    <div className='admin_users_container'>
      <Title Value={'Users'}/>
      <Table List={userList} setSelectedUserID={setSelectedUserID} setShowEdit={setShowEdit}/>
      {showEdit &&
        <Edit selectedUserID={selectedUserID} toast={toast} setList={setUserList} setShowEdit={setShowEdit}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default Index
