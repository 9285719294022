import React from 'react'
import HeroImage from '../../../images/public/14.png'

const Hero = () => {
  return (
    <div className='public_home_hero_wrapper colorprimarybg'>
      <div className='public_home_hero_container'>
        <div className='public_home_hero_row'>
            <div className='public_home_hero_text'>
                <h3 className='colorwhite'>Frequently Asked Questions</h3>
            </div>
            <div className='public_home_hero_image'>
                <img src={HeroImage} alt='FAQ Hero'/>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
