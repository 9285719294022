import React from 'react'
import './index.css'

const Index = () => {
  return (
    <div className='public_about_craftsmanship_container'>
      <h3>Craftsmanship Meets Technology</h3>
      <p>Our laboratory stands at the intersection of traditional craftsmanship and state-of-the-art technology. With a team of highly skilled dental artisans and technicians, combined with the latest digital tools, we are dedicated to pushing the boundaries of dental aesthetics and functionality. We believe that each dental restoration is a work of art, and our meticulous attention to detail ensures that every piece leaving our laboratory is a testament to our commitment to excellence.</p>
    </div>
  )
}

export default Index
