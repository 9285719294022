import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Input from '../../../components/panelcontrols/panelinput'
import TextArea from '../../../components/panelcontrols/paneltextarea'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import Select from '../../../components/panelcontrols/panelselect'
import './index.css'
import Table from './materialTable'
import Functions from './functions'
import { ToastContainer, toast } from 'react-toastify';
import Edit from './edit'

const Index = () => {
    const [typename,setTypeName] = useState('');
    const [typeremarks,setTypeRemarks] = useState('');
    const [title,setTitle] = useState('');
    const [description,setDescription] = useState('');
    const [type,setType] = useState({value:0,name:'Select type'});
    const [typelist,setTypeList] = useState([]);
    const [weight,setWeight] = useState(0);
    const [List,setList] = useState([]);
    const [selectedMaterial,setSelectedMaterial] = useState(null); 
    const [showEdit,setShowEdit] = useState(false); 
    const CreateMaterialTypeList = (data)=>{
        var sendData = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          sendData.push({
            value: parseInt(element.id),
            name: element.name
          });
        }
        setTypeList(sendData);
    }
    useEffect(()=>{
      Functions.GetMaterialType((data)=>{
        CreateMaterialTypeList(data);
      })
      Functions.GetMaterial(setList)
    },[])
    const resetMaterialType = ()=>{
      setTypeName('');
      setTypeRemarks('');
    }
    const resetMaterial = ()=>{
      setTitle('');
      setWeight(0);
      setType({value:0,name:'Select type'});
      setDescription('');
    }
  return (
    <div className='labadmin_material_container'>
        <Title Value={'Materials'}/>
        <div className='admin_material_row'>
            <div className='admin_material_col50'>
                <div className='admin_material_formbox'>
                    <h3>New material type</h3>
                    <Input Lable={'Name'} Type={'text'} Value={typename} setValue={setTypeName}/>
                    <TextArea Lable={'Remarks'} Type={'text'} Value={typeremarks} setValue={setTypeRemarks}/>
                    <Button Value={'Save'} Action={()=>{
                        const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                        if(typename === ''){
                          toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                          return;
                        }
                        Functions.PostMaterialType({
                          name: typename,
                          remarks: typeremarks
                        },CreateMaterialTypeList,toast,toastid,resetMaterialType)
                    }}/>
                </div>
            </div>
            <div className='admin_material_col50'>
                <div className='admin_material_formbox'>
                    <h3>New material</h3>
                    <Input Lable={'Title'} Type={'text'} Value={title} setValue={setTitle}/>
                    <Input Lable={'Weight (Grams)'} Type={'number'} Value={weight} setValue={setWeight}/>
                    <Select Lable={'Type'} Value={type} setValue={setType} Options={typelist}/>
                    <TextArea Lable={'Description'} Type={'text'} Value={description} setValue={setDescription}/>
                    <Button Value={'Save'} Action={()=>{
                        const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                        if(title === '' || type.value === 0){
                          toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                          return;
                        }
                        Functions.PostMaterial({
                          title: title,
                          weight: weight,
                          type: type.value,
                          description: description
                        },setList,toast,toastid,resetMaterial)
                    }}/>
                </div>
            </div>
        </div>
        <Table List={List} setList={setList} setSelectedMaterial={setSelectedMaterial} setShowEdit={setShowEdit} toast={toast}/>
        {showEdit &&
          <Edit selectedMaterial={selectedMaterial} setSelectedMaterial={setSelectedMaterial} toast={toast} setList={setList} setShowEdit={setShowEdit}/>
        }
        <ToastContainer/>
    </div>
  )
}

export default Index
