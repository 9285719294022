import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './index.css'

const Index = ({ResData,setShowEmailPostRes}) => {
  return (
    <div className='public_subscriber_res_wrapper'>
      <div className='public_subscriber_res_container'>
        <div className='public_subscriber_res_close'>
            <button onClick={()=>{
                setShowEmailPostRes(false);
            }}><FontAwesomeIcon icon={faXmark}/></button>
        </div>
        <div className='public_subscriber_res_message'>
            {ResData.success && 
                <div className='public_subscriber_res_icon_success'>
                    <FontAwesomeIcon icon={faCheck}/>
                </div>
            }
            {!ResData.success && 
                <div className='public_subscriber_res_icon_failed'>
                    <FontAwesomeIcon icon={faXmark}/>
                </div>
            }
            <p>{ResData.response}</p>
        </div>
      </div>
    </div>
  )
}

export default Index
