import React,{useState,useEffect} from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faPrint, faSearch } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../../components/panelcontrols/panelbuttontable'

const Index = ({List}) => {
  const [filterList,setfilterList] = useState(List);
  function filterFunction(value){
    if(value === ""){
      setfilterList(List);
    }
    else{
      const filtedList = List.filter((obj)=>{
        return Object.values(obj)
        .join(" ").toLowerCase()
        .includes(value.toLowerCase());
      })
      setfilterList(filtedList);
    }
  }
  useEffect(()=>{
      setfilterList(List);
  },[List])
  return (
    <div className='user_table_wrapper'>
      <div className='user_table_search'>
        <input onChange={(e)=>{filterFunction(e.target.value);}}/>
        <div className='user_table_search_icon'>
          <FontAwesomeIcon icon={faSearch}/>
        </div>
      </div>
      <div className='user_table_container'>
        <div className='_table'>
          <div className='_thead'>
            <div className='_tr'>
              <div className='_th' style={{width:"7%"}}>Invoice</div>
              <div className='_th' style={{width:"15%"}}>Client</div>
              <div className='_th' style={{width:"10%"}}>Status</div>
              <div className='_th' style={{width:"14%"}}>Department</div>
              <div className='_th' style={{width:"12%"}}>Date</div>
              <div className='_th' style={{width:"12%",textAlign:"end"}}>Net Total</div>
              <div className='_th' style={{width:"30%",textAlign:"end"}}>Actions</div>
            </div>
          </div>
          <div className='_tbody'>
            {filterList.length > 0 &&
              filterList.map((item,index)=>{
                return(
                  <div className='_tr' key={index}>
                    <div className='_td' style={{width:"7%"}}>OR{item.id}</div>
                    <div className='_td' style={{width:"15%"}}>{item.doctorname}</div>
                    <div className='_td' style={{width:"10%"}}>{item.statusname}</div>
                    <div className='_td' style={{width:"14%"}}>{item.departmentname}</div>
                    <div className='_td' style={{width:"12%"}}>
                      {item.date.split('',10)}
                    </div>
                    <div className='_td' style={{width:"12%",textAlign:"end"}}>{item.nettotal}</div>
                    <div className='_td' style={{width:"30%",display:"flex", flexWrap:"wrap", justifyContent:"flex-end"}}>
                      {/*
                      
                      <div style={{margin:"2px"}}>
                        <Button Value={<><FontAwesomeIcon icon={faEye}/><p>View</p></>} Action={()=>{
                          window.open("/doctorreport/jobcardnp/"+item.id, "_blank", "noreferrer");
                        }}/>
                      </div>
                      <div style={{margin:"2px"}}>
                        <Button Value={<><FontAwesomeIcon icon={faPrint}/><p>Job card</p></>} Action={()=>{
                          window.open("/doctorreport/jobcard/"+item.id, "_blank", "noreferrer");
                        }}/>
                      </div>
                      <div style={{margin:"2px"}}>
                        <Button Value={<><FontAwesomeIcon icon={faPrint}/><p>Lable</p></>} Action={()=>{
                          window.open("/doctorreport/label/"+item.id, "_blank", "noreferrer");
                        }}/>
                      </div>
                       */}
                    </div>
                  </div>
                )
              })
            }
            {filterList.length <= 0 &&
              <div className='table_nodata'>
                <p>No data available</p>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
