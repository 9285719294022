import ApiUrl from "../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = (setValues) => {
    fetch(ApiUrl.Admin_Dashboard,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        setValues(data);
    });
}


export default {Get}