import React, { useEffect, useState } from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Table from './table'
import Functions from './functions'

const Index = () => {
    const [list,setList] = useState([]);
    useEffect(()=>{
        Functions.Get(setList);
    },[])
  return (
    <div className='admin_subscribers_container'>
      <Title Value={'Subscriber'}/>
        <Table List={list}/>
    </div>
  )
}

export default Index
