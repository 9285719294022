import React from 'react'
import './index.css'

const Index = ({Value}) => {
  return (
    <div className='admin_title_container'>
      <div className='admin_title_lable'>
        <h1>{Value}</h1>
      </div>
    </div>
  )
}

export default Index
