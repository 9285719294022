import React from 'react'
import Hero from './hero'
import Craft from './craftsmanship'
import Innovative from './innovative'
import Personalized from './personalized'
import Quality from './quality'
import Labinfo from '../home/labs'
import Experience from './experience'

const Index = () => {
  return (
    <div>
      <Hero/>
      <Craft/>
      <Innovative/>
      <Personalized/>
      <Quality/>
      <div style={{backgroundColor:"white",zIndex:"99",padding:"100px 0px", borderRadius:"50px"}}>
      <Experience/>
      </div>
        <Labinfo/>
    </div>
  )
}

export default Index
