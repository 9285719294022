import React from 'react'
import FactsBgImage from '../../../images/public/34.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserDoctor,faHospital,faFileMedical, faTooth } from '@fortawesome/free-solid-svg-icons'

const Facts = () => {
  return (
    <div className='public_home_facts_background' style={{backgroundImage:`url(${FactsBgImage})`}}>
      <div className='public_home_facts_wrapper'>
        <div className='public_home_facts_container'>
            <div className='public_home_facts_title'>
                <h3 className='colorprimary'>YOU ARE IMPORTANT TO US</h3>
                <p>At N Vision Digital Dental Laboratory, we provide unparalleled dental restorations at the most affordable prices. "Your patients are our priority". We strive to build lasting relationships with dental professionals through quality products and excellent customer services.</p>
            </div>
            <div className='public_home_facts_items'>
                <div className='public_home_facts_item'>
                    <div className='public_home_facts_item_image'>
                        <FontAwesomeIcon icon={faUserDoctor} />
                    </div>
                    <div className='public_home_facts_item_about'>
                        <h2>100+</h2>
                        <h3>Dentists</h3>
                    </div>
                </div>
                <div className='public_home_facts_item'>
                    <div className='public_home_facts_item_image'>
                        <FontAwesomeIcon icon={faHospital} />
                    </div>
                    <div className='public_home_facts_item_about'>
                        <h2>70+</h2>
                        <h3>Clinics</h3>
                    </div>
                </div>
                <div className='public_home_facts_item'>
                    <div className='public_home_facts_item_image'>
                        <FontAwesomeIcon icon={faTooth} />
                    </div>
                    <div className='public_home_facts_item_about'>
                        <h2>5000+</h2>
                        <h3>Cases</h3>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Facts
