import React,{useState,useEffect} from 'react'
import Input from '../../../../../components/panelcontrols/panelinput'
import TextArea from '../../../../../components/panelcontrols/paneltextarea'
import Button from '../../../../../components/panelcontrols/panelbuttonmain'
import Functions from '../function'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import City from '../citySelector'

const Index = ({selectedClinic,setSelectedClinic,toast,setList,setShowEdit}) => {
    const [name,setName] = useState('');
    const [contact,setContact] = useState('');
    const [address,setAddress] = useState('');
    const [city,setCity] = useState({value:0,name:'Select city'});
    useEffect(()=>{
        if(selectedClinic){
            setName(selectedClinic.name);
            setContact(selectedClinic.contact);
            setAddress(selectedClinic.address);
            setCity({
                value: parseInt(selectedClinic.city),
                name: selectedClinic.cityname
            });
        }
        else{
            setName('');
            setContact('');
            setAddress('');
            setCity({value:0,name:'Select city'})
        }
    },[selectedClinic])
    const reset = ()=>{
        setSelectedClinic(null)
        setShowEdit(false)
    }
  return (
    <div className='labadmin_product_edit_wrapper'>
        <div className='labadmin_product_edit_container'>
            <div className='labadmin_product_edit_close'>
                <button onClick={()=>{
                   reset(); 
                }}>
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            <div className='labadmin_product_formbox'>
                <h3>Edit Clinic</h3>
                <div className='labadmin_doctors_clinics_row'>
                <div className='labadmin_doctors_clinics_col50'>
                    <Input Lable={'Name'} Type={'text'} Value={name} setValue={setName}/>
                </div>
                <div className='labadmin_doctors_clinics_col50'>
                    <Input Lable={'Contact'} Type={'text'} Value={contact} setValue={setContact}/>
                </div>
                </div>
                <City city={city} setCity={setCity} />
                <TextArea Lable={'Address'} Type={'text'} Value={address} setValue={setAddress}/>
                <Button Value={'Save'} Action={()=>{
                    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                    if(name === '' || city.value === 0){
                      toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                      return;
                    }
                    Functions.Put(selectedClinic.id,{
                        name: name, 
                        contact : contact,
                        address : address,
                        city : city.value
                    },setList,toast,toastid,reset)
                }}/>
            </div>
        </div>
    </div>
  )
}

export default Index
