import React,{useState,useEffect} from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import './index.css'
import Table from './vouchersTable'

const Index = () => {
    const [list,setList] = useState([]);
  return (
    <div className='labadmin_vouchers_container'>
        <Title Value={'Vouchers'}/>
        <Table List={list}/>
    </div>
  )
}

export default Index
