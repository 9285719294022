import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.css'

const Index = ({Item}) => {
    const currentRoute = useLocation().pathname.toLowerCase();
  return (
    <>
        <a href={Item.url}>
            <div className={currentRoute.includes(Item.slug)? 'admin_layout_leftbar_navigation_item active': 'admin_layout_leftbar_navigation_item'}>
                <div className='admin_layout_leftbar_navigation_icon'>
                    <FontAwesomeIcon icon={Item.icon}/>
                </div>
                <div className='admin_layout_leftbar_navigation_lable'>
                    <p>{Item.name}</p>
                </div>
                {currentRoute.includes(Item.slug) &&
                    <div className='admin_layout_leftbar_navigation_item_bar'></div>
                }
            </div>
        </a>
        {currentRoute.includes(Item.slug) && Item.subnav.length > 0 &&
            <div className='admin_layout_leftbar_navigation_item_subnav_container'>
                {
                    Item.subnav.map((nav,index)=>{
                        return(
                            <a href={nav.url} key={index}>
                                <div className={currentRoute.includes(nav.slug)? 'admin_layout_leftbar_navigation_item_subnav_item active':'admin_layout_leftbar_navigation_item_subnav_item'}>
                                    <p>{nav.name}</p>
                                </div>
                            </a>
                        )
                    })
                }
            </div>
        }
    </>
  )
}

export default Index
