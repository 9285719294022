import { faPerson } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import './index.css'
import MillingImage from '../../../../images/public2/6.png'
import DesigningImage from '../../../../images/public2/7.png'
import SuppliesImage from '../../../../images/public/31.png'

const Index = () => {
  return (
    <div className='public_whatwe_support_container'>
      <div className='public_whatwe_support_details'>
        <h3>What we support</h3>
        <p>Welcome to N Vision Lab! We proudly introduce ourselves as a worldwide dental laboratory dedicated to providing dental professionals with impeccably designed restorations. At our platform, you can seamlessly manage your cases online, all in one place. In addition to offering top-notch dental Intra oral 3D scanning, CAD/CAM designing and manufacturing over milling, 3D printing machine services, we also provide a comprehensive range of dental supplies. We invite you to become a part of our thriving 'Dentistry World' community, where you can expand your understanding of dental cases and knowledge. Join us today!</p>
      </div>
      <div className='public_whatwe_support_row'>
        <div className='public_whatwe_support_col33'>
            <div className='public_whatwe_support_category'>
                <Link to={'mailto:info@nvisiondental.com'}>
                    <div className='public_whatwe_support_image'>
                        <img src={MillingImage}/>
                    </div>
                    <h3>Milling Center</h3>
                </Link>
            </div>
        </div>
        <div className='public_whatwe_support_col33'>
            <div className='public_whatwe_support_category'>
                <Link to={'mailto:info@nvisiondental.com'}>
                    <div className='public_whatwe_support_image'>
                        <img src={DesigningImage}/>
                    </div>
                    <h3>CAD Designing</h3>
                </Link>
            </div>
        </div>
        <div className='public_whatwe_support_col33'>
            <div className='public_whatwe_support_category'>
                <Link to={'mailto:info@nvisiondental.com'}>
                    <div className='public_whatwe_support_image' style={{border:"4px solid #2f1c60", borderRadius:"50%",padding:"10px"}}>
                        <img src={SuppliesImage}/>
                    </div>
                    <h3>Dental Supplies</h3>
                </Link>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Index
