import ApiUrl from "../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = (from,to,setValues) => {
    fetch(ApiUrl.LabAdminPrint_Jobcard_Salesbydate+`?from=${from}&to=${to}`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        setValues(data.response);
    });
}

export default {Get};