import ApiUrl from "../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = (setValues) => {
    fetch(ApiUrl.Admin_Labs,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        setValues(data.response);
    });
}

const Post = (body,setValues) => {
    fetch(ApiUrl.Admin_Labs,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            Get(setValues);
        }
        else{
            alert(data.response);
        }
    });
}


export default {Get,Post}