import React,{useState,useEffect} from 'react'
import Functions from './function'
import { useParams } from 'react-router-dom';
import { QRCodeCanvas } from "qrcode.react";

const Index = () => {
  const [orderdata,setOrderData] = useState(null);
  const {id} = useParams();
  let inprint = false;
  useEffect(()=>{
      Functions.Get(id,(data)=>{
          setOrderData(data);
          if(!inprint){
              setTimeout(() => {
                  window.print();
                  window.close();
              }, 400);
          }
          inprint = true;
      });
  },[])
  return (
  <>
    {orderdata &&
      <div>
          <div style={{display:"flex", justifyContent:"space-between", margin:"10px 0px"}}>
            <div style={{width:"70%"}}>
              <div>
                <p style={{fontSize:"18px"}}>{orderdata.doctor}</p>
              </div>
              <div>
                <p><b>Address: {orderdata.address}</b></p>
              </div>
              <div>
                <p><b>Email: {orderdata.email}</b></p>
              </div>
              <div style={{borderBottom:"2px solid black"}}>
                <p><b>Contact: {orderdata.contact}</b></p>
              </div>
              <div style={{borderBottom:"2px solid black", margin:"5px 0px"}}>
                <p style={{fontSize:"18px"}}>Patient: {orderdata.patient}</p>
              </div>
            </div>
            <div style={{width:"30%",display:"flex", justifyContent:"center", alignItems:"flex-start"}}>
              <div>
                <QRCodeCanvas
                    id="qrCode"
                    value={id}
                    bgColor={"#ffffff"}
                    level={"H"}
                    size={100}
                />
                <p style={{fontSize:"16px", textAlign:"center"}}>{id}</p>
              </div>
            </div>
          </div>
          {orderdata.lineItems.map((item,index)=>{
            return(
              <div key={index} style={{padding:"5px 0px"}}>
                <div style={{borderBottom:"2px solid black", backgroundColor:"#922724", margin:"5px 0px"}}>
                  <p style={{fontSize:"24px",color:"white"}}>Due Date: {item.duedate.split('',10)}</p>
                </div>
                <div style={{borderBottom:"2px solid black", margin:"5px 0px"}}>
                  <p><b style={{fontSize:"18px", fontWeight:"500"}}>Enclosed:</b> {item.enclosed}</p>
                </div>
                <div style={{borderBottom:"2px solid black", margin:"5px 0px"}}>
                  <p><b style={{fontSize:"18px", fontWeight:"500"}}>Product/Service: </b>{item.productname} / {item.servicename}</p>
                </div>
                <div style={{width:'70%',display:"flex",flexWrap:"wrap"}}>
                  <div style={{width:"50%",borderRight:"2px solid black",borderBottom:"2px solid black",height:"50px", display:"flex", alignItems:"flex-end", justifyContent:"flex-end"}}>
                    <p style={{padding:"5px"}}>{item.sections.S1 !== 0 &&
                      item.sections.S1
                    }</p>
                  </div>
                  <div style={{width:"50%",borderBottom:"2px solid black",height:"50px", display:"flex", alignItems:"flex-end", justifyContent:"flex-start"}}>
                    <p style={{padding:"5px"}}>{item.sections.S2 !== 0 &&
                      item.sections.S2
                    }</p>
                  </div>
                  <div style={{width:"50%",borderRight:"2px solid black",height:"50px", display:"flex", alignItems:"flex-start", justifyContent:"flex-end"}}>
                    <p style={{padding:"5px"}}>{item.sections.S3 !== 0 &&
                      item.sections.S3
                    }</p>
                  </div>
                  <div style={{width:"50%",height:"50px", display:"flex", alignItems:"flex-start", justifyContent:"flex-start"}}>
                    <p style={{padding:"5px"}}>{item.sections.S4 !== 0 &&
                      item.sections.S4
                    }</p>
                  </div>
                </div>
                <div style={{borderTop:"2px solid black", margin:"5px 0px"}}>
                  <p><b style={{fontSize:"18px", fontWeight:"500"}}>SHADE: </b>{item.shades}</p>
                </div>
              </div>
            )
          })

          }
      </div>
    }
  </>
  )
}

export default Index
