import React from 'react'
import ImageA from '../../../images/public/17.jpg'
import ImageB from '../../../images/public/28.jpg'

const Aligners = () => {
  return (
    <div className='public_servic_aligners_wrapper colorwhitebg'>
      <div className='public_servic_aligners_container'>
        <div className='public_servic_aligners_row'>
            <div className='public_servic_aligners_description'>
                <h3 className='colorprimary'>Aligners</h3>
                <p>
                    Aligners are orthodontic devices based on a series of transparent aligner to realign the patient’s teeth as an alternative to wires and brackets as in regular dental braces.<br></br>
                    Aligner technology is an alternative to orthodontic braces. <b><span>NVIS</span>ALIGN</b> is one example of this technology. <br></br>
                    It uses thin, transparent plastic shells to move teeth in small increments when worn consistently.
                </p>
            </div>
            <div className='public_servic_aligners_images'>
                <div className='public_servic_aligners_imageA' style={{backgroundImage:`url(${ImageA})`}}></div>
                <div className='public_servic_aligners_imageB' style={{backgroundImage:`url(${ImageB})`}}></div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Aligners
