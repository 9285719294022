import React, { useEffect, useState } from 'react'
import Logo from '../../../images/public/websitefooter.png'
import { useSearchParams } from 'react-router-dom';
import Functions from './function';

const Index = () => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    const [searchParams] = useSearchParams();
    const [billingdata,setBillingData]= useState(null);
    const [month, setMonth] = useState("");

    let inprint = false;
    useEffect(()=>{
        var doctor = searchParams.get('doctor') || "0";
        var month = searchParams.get('month') || "0";
        const monthNumber = parseInt(month);
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const monthName = monthNames[monthNumber - 1] || "";
        setMonth(monthName)
        Functions.Get(doctor,month,(Data)=>{
            setBillingData(Data);
            if(!inprint){
                setTimeout(() => {
                    window.print();
                    //window.close();
                }, 400);
            }
            inprint = true;
        });
    },[
        searchParams.get('doctor'),
        searchParams.get('month')
    ])
  return (
    <div style={{margin:"10px 40px"}}>
    {billingdata &&
        <>
        <div style={{borderBottom:"3px dashed black",padding:"0px 0px"}}>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <div>
                    <p style={{fontSize:"12px", padding:"0px 0px"}}><b style={{fontWeight:"500"}}>Clinic Name</b> {billingdata.clinicname}</p>
                    <p style={{fontSize:"12px", padding:"0px 0px"}}><b style={{fontWeight:"500"}}>Doctor Name</b> {billingdata.doctorname}</p>
                    <p style={{fontSize:"12px", padding:"0px 0px"}}><b style={{fontWeight:"500"}}>Address</b> {billingdata.address}</p>
                    <p style={{fontSize:"12px", padding:"0px 0px"}}><b style={{fontWeight:"500"}}>Email</b> {billingdata.email} <b style={{fontWeight:"500"}}>Phone #</b> {billingdata.contact}</p>
                </div>
                <div style={{width:"40%"}}>
                    <div style={{border:"1px solid black", backgroundColor:"#2F1C60", borderRadius:"5px", margin:"5px 0px",padding:"5px 5px"}}>
                        <p style={{textAlign:"center",fontSize:"16px", color:"white"}}><b style={{fontWeight:"500", color:"white"}}>Amount due</b> PKR {billingdata.payables}/-</p>
                    </div>
                    <div style={{border:"1px solid black", backgroundColor:"#922724", borderRadius:"5px", margin:"5px 0px",padding:"5px 5px"}}>
                        <p style={{textAlign:"center",fontSize:"16px", color:"white"}}><b style={{fontWeight:"500", color:"white"}}>Pay before</b> {searchParams.get('duedate').split('',10)}</p>
                    </div>
                </div>
            </div>
            <p style={{fontSize:"12px", textAlign:"right"}}>Return this portion with your payment</p>
        </div>
        <div style={{width:"200px",margin:"5px auto 0px auto"}}>
            <img src={Logo} style={{width:"100%"}}/>
        </div>
        <div style={{display:"flex",justifyContent:"space-between",padding:"0px 0px"}}>
            <h3 style={{fontSize:"16px",fontWeight:"500", color:"#2F1C60"}}>Monthly Statement of {month}</h3>
            <h3 style={{fontSize:"16px",fontWeight:"500", color:"#922724"}}>Due Date: {searchParams.get('duedate').split('',10)}</h3>
        </div>
        <div style={{display:"flex",justifyContent:"space-between", alignItems:"stretch"}}>
            <div style={{width:"49%",border:"2px solid #2F1C60",padding:"2px", borderRadius:"5px"}}>
                <h3 style={{fontSize:"18px", fontWeight:"500"}}>Bill To</h3>
                <p style={{lineHeight:"1", fontSize:"12px"}}>
                    <b style={{lineHeight:"1", fontSize:"12px", fontWeight:"600"}}>{billingdata.clinicname} ({billingdata.doctorname})</b><br></br>
                    Address: {billingdata.address}<br></br>
                    Contact: {billingdata.contact}<br></br>
                    Email: {billingdata.email}
                </p>
            </div>
            <div style={{width:"49%",border:"2px solid #2F1C60",padding:"2px", borderRadius:"5px"}}>
                <h3 style={{fontSize:"18px", fontWeight:"500"}}>Bill From</h3>
                <p style={{lineHeight:"1", fontSize:"12px"}}>
                    <b style={{lineHeight:"1", fontSize:"12px", fontWeight:"600"}}>N-vision Digital Dental Laboratory ({User.username})</b><br></br>
                    Address: P-369 Block B Street#2 Alnajaf Colony Faisalabad Pakistan. 38000<br></br>
                    Contact: +92 (300) 460 - 1314<br></br>
                    Email: fsd@nvisiondental.com
                </p>
            </div>
        </div>
        <div style={{backgroundColor:"#2F1C60",height:"5px",margin:"10px 0px"}}></div>
        <div style={{display:"flex",border:"1px solid black"}}>
            <div style={{width:"6%", borderRight:"1px solid black", padding:"5px"}}>
                <h3 style={{fontSize:"12px",fontWeight:"500"}}>In #</h3>
            </div>
            <div style={{width:"11%", borderRight:"1px solid black", padding:"5px"}}>
                <h3 style={{fontSize:"12px",fontWeight:"500"}}>Date</h3>
            </div>
            <div style={{width:"15%", borderRight:"1px solid black", padding:"5px"}}>
                <h3 style={{fontSize:"12px",fontWeight:"500"}}>Patient</h3>
            </div>
            <div style={{width:"60%", display:"flex"}}>
                <div style={{width:"40%", borderRight:"1px solid black", padding:"5px"}}>
                    <h3 style={{fontSize:"12px",fontWeight:"500"}}>Work type</h3>
                </div>
                <div style={{width:"53%", borderRight:"1px solid black", padding:"5px"}}>
                    <h3 style={{fontSize:"12px",fontWeight:"500"}}>Tooth #</h3>
                </div>
                <div style={{width:"7%", borderRight:"1px solid black", padding:"5px"}}>
                    <h3 style={{fontSize:"12px",fontWeight:"500"}}>Ut</h3>
                </div>
            </div>
            <div style={{width:"8%", padding:"5px"}}>
                <h3 style={{fontSize:"12px",fontWeight:"500",textAlign:"right"}}>Amt</h3>
            </div>
        </div>
        {billingdata.orders.length === 0 &&
            <div style={{display:"flex",justifyContent:"center", padding:"5px"}}>
                <p>No Data found</p>
            </div>
        }
        {billingdata.orders.length !== 0 &&
            <>
            {
                billingdata.orders.map((item,index)=>{
                    const dateObject = new Date(item.date);
                    // Extract day, month, and year components
                    const day = dateObject.getDate();
                    // Month starts from 0, so we add 1 to get the correct month
                    const month = dateObject.getMonth() + 1;
                    const year = dateObject.getFullYear();
                    // Format the date in "DD-MM-YYYY" format
                    const formattedDate = `${day}-${month}-${year}`;
                    return(
                        <div key={index} style={{display:"flex",borderLeft:"1px solid black",borderRight:"1px solid black"}}>
                            <div style={{width:"6%",borderBottom:"1px solid black", borderRight:"1px solid black", padding:"5px"}}>
                                <h3 style={{fontSize:"10px",fontWeight:"500"}}>{item.id}</h3>
                            </div>
                            <div style={{width:"11%",borderBottom:"1px solid black", borderRight:"1px solid black", padding:"5px"}}>
                                <h3 style={{fontSize:"10px",fontWeight:"500"}}>{formattedDate}</h3>
                            </div>
                            <div style={{width:"15%",borderBottom:"1px solid black", borderRight:"1px solid black", padding:"5px"}}>
                                <h3 style={{fontSize:"10px",fontWeight:"500"}}>{item.patient}</h3>
                            </div>
                            <div style={{width:"60%"}}>
                            {
                                item.items.map((iteminitem,index2)=>{
                                    return(
                                        <div key={index2} style={{display:"flex", borderBottom:"1px solid black"}}>
                                            <div style={{width:"40%", borderRight:"1px solid black", padding:"5px"}}>
                                                <h3 style={{fontSize:"10px",fontWeight:"500"}}>{iteminitem.productname} / {iteminitem.servicename}</h3>
                                            </div>
                                            <div style={{width:"53%", borderRight:"1px solid black", padding:"5px"}}>
                                                <div style={{width:'100%',display:"flex",flexWrap:"wrap"}}>
                                                    <div style={{width:"50%",borderRight:"1px solid black",borderBottom:"1px solid black",height:"15px", display:"flex", alignItems:"flex-end", justifyContent:"flex-end"}}>
                                                        <p style={{padding:"1px", fontSize:"10px"}}>{iteminitem.sections.S1 !== 0 &&
                                                                iteminitem.sections.S1.slice().reverse().map((number, index, array) =>
                                                                    index !== array.length - 1 ? `${number - 10}, ` : number - 10
                                                                )
                                                            }
                                                        </p>
                                                    </div>
                                                    <div style={{width:"50%",borderBottom:"1px solid black",height:"15px", display:"flex", alignItems:"flex-end", justifyContent:"flex-start"}}>
                                                        <p style={{padding:"1px", fontSize:"10px"}}>{iteminitem.sections.S2 !== 0 &&
                                                        iteminitem.sections.S2.map((number, index, array) => 
                                                            index !== array.length - 1 ? `${number - 20}, ` : number - 20
                                                        )
                                                        }</p>
                                                    </div>
                                                    <div style={{width:"50%",borderRight:"1px solid black",height:"15px", display:"flex", alignItems:"flex-start", justifyContent:"flex-end"}}>
                                                        <p style={{padding:"1px", fontSize:"10px"}}>{iteminitem.sections.S3 !== 0 &&
                                                        iteminitem.sections.S3.slice().reverse().map((number, index, array) =>
                                                        index !== array.length - 1 ? `${number - 30}, ` : number - 30
                                                    )
                                                        }</p>
                                                    </div>
                                                    <div style={{width:"50%",height:"15px", display:"flex", alignItems:"flex-start", justifyContent:"flex-start"}}>
                                                        <p style={{padding:"1px", fontSize:"10px"}}>{iteminitem.sections.S4 !== 0 &&
                                                        iteminitem.sections.S4.map((number, index, array) => 
                                                            index !== array.length - 1 ? `${number - 40}, ` : number - 40
                                                        )
                                                        }</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{width:"7%", borderRight:"1px solid black", padding:"5px"}}>
                                                <h3 style={{fontSize:"10px",fontWeight:"500"}}>{
                                                    parseInt(parseInt(iteminitem.sections.S1.length)+parseInt(iteminitem.sections.S2.length)+parseInt(iteminitem.sections.S3.length)+parseInt(iteminitem.sections.S4.length))
                                                }</h3>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                            <div style={{width:"8%",borderBottom:"1px solid black", padding:"5px"}}>
                                <h3 style={{fontSize:"10px",fontWeight:"500",textAlign:"right"}}>{item.subtotal}</h3>
                            </div>
                        </div>
                    )
                })

            }
            </>
        }
        <div style={{height:"5px",margin:"10px 0px"}}></div>
        {billingdata.payments.length > 0 &&
            <>
                <h1 style={{fontSize:"18px", fontWeight:"500"}}>Payments</h1>
                <div style={{display:"flex",border:"1px solid black"}}>
                    <div style={{width:"10%", borderRight:"1px solid black", padding:"5px"}}>
                        <h3 style={{fontSize:"12px",fontWeight:"500"}}>#</h3>
                    </div>
                    <div style={{width:"15%", borderRight:"1px solid black", padding:"5px"}}>
                        <h3 style={{fontSize:"12px",fontWeight:"500"}}>Date</h3>
                    </div>
                    <div style={{width:"65%", borderRight:"1px solid black", padding:"5px"}}>
                        <h3 style={{fontSize:"12px",fontWeight:"500"}}>Description</h3>
                    </div>
                    <div style={{width:"10%", padding:"5px"}}>
                        <h3 style={{fontSize:"12px",fontWeight:"500",textAlign:"right"}}>Amount</h3>
                    </div>
                </div>
                {
                    billingdata.payments.map((item,index)=>{
                        return(
                            <div key={index} style={{display:"flex",borderBottom:"1px solid black",borderLeft:"1px solid black",borderRight:"1px solid black"}}>
                                <div style={{width:"10%", borderRight:"1px solid black", padding:"5px"}}>
                                    <h3 style={{fontSize:"12px",fontWeight:"500"}}>{item.id}</h3>
                                </div>
                                <div style={{width:"15%", borderRight:"1px solid black", padding:"5px"}}>
                                    <h3 style={{fontSize:"12px",fontWeight:"500"}}>{item.date.split('',10)}</h3>
                                </div>
                                <div style={{width:"65%", borderRight:"1px solid black", padding:"5px"}}>
                                    <h3 style={{fontSize:"12px",fontWeight:"500"}}>{item.description}</h3>
                                </div>
                                <div style={{width:"10%", padding:"5px"}}>
                                    <h3 style={{fontSize:"12px",fontWeight:"500",textAlign:"right"}}>{item.amount}</h3>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        }
        <div style={{display:"flex",justifyContent:"space-between",margin:"10px 0px"}}>
            <div style={{width:"50%"}}>
                <h3 style={{fontSize:"16px", fontWeight:"500"}}>Important notes</h3>
                <p style={{fontSize:"12px"}}>
                    1. If any payment is missing from this statement, please let us know as soon as possible. Kindly pay the amount with in Due Date ({searchParams.get('duedate').split('',10)}). If you have paid the Amount(s) after Month, It will be shown in next Bill<br></br>
                    2. Thank you very much. We really appreciate your business. Please send payments as soon.<br></br>
                </p>
            </div>
            <div style={{width:"40%"}}>
                <div style={{border:"1px solid black", display:"flex"}}>
                    <div style={{width:"60%",padding:"5px", borderRight:"1px solid black"}}>
                        <h3 style={{fontSize:"12px", fontWeight:"500"}}>Remaining Balance</h3>
                    </div>
                    <div style={{width:"40%",padding:"5px"}}>
                        <h3 style={{fontSize:"12px", fontWeight:"400",textAlign:"right"}}>PKR {billingdata.remaining}/-</h3>
                    </div>
                </div>
                {billingdata.payments.length > 0 &&
                    <div style={{borderBottom:"1px solid black",borderLeft:"1px solid black",borderRight:"1px solid black", display:"flex"}}>
                        <div style={{width:"60%",padding:"5px", borderRight:"1px solid black"}}>
                            <h3 style={{fontSize:"12px", fontWeight:"500"}}>Paid this month</h3>
                        </div>
                        <div style={{width:"40%",padding:"5px"}}>
                            <h3 style={{fontSize:"12px", fontWeight:"400",textAlign:"right"}}>PKR {billingdata.totalpaidthismonth}/-</h3>
                        </div>
                    </div>
                }
                <div style={{borderBottom:"1px solid black",borderLeft:"1px solid black",borderRight:"1px solid black", display:"flex"}}>
                    <div style={{width:"60%",padding:"5px", borderRight:"1px solid black"}}>
                        <h3 style={{fontSize:"12px", fontWeight:"500"}}>Total Amount</h3>
                    </div>
                    <div style={{width:"40%",padding:"5px"}}>
                        <h3 style={{fontSize:"12px", fontWeight:"400",textAlign:"right"}}>PKR {billingdata.totalamount}/-</h3>
                    </div>
                </div>
                <div style={{borderBottom:"1px solid black",borderLeft:"1px solid black",borderRight:"1px solid black", display:"flex"}}>
                    <div style={{width:"60%",padding:"5px", borderRight:"1px solid black"}}>
                        <h3 style={{fontSize:"12px", fontWeight:"500"}}>Discount</h3>
                    </div>
                    <div style={{width:"40%",padding:"5px"}}>
                        <h3 style={{fontSize:"12px", fontWeight:"400",textAlign:"right"}}>PKR {billingdata.discount}/-</h3>
                    </div>
                </div>
                <div style={{borderBottom:"1px solid black",borderLeft:"1px solid black",borderRight:"1px solid black", display:"flex"}}>
                    <div style={{width:"60%",padding:"5px", borderRight:"1px solid black"}}>
                        <h3 style={{fontSize:"12px", fontWeight:"500"}}>Total after discount</h3>
                    </div>
                    <div style={{width:"40%",padding:"5px"}}>
                        <h3 style={{fontSize:"12px", fontWeight:"400",textAlign:"right"}}>PKR {billingdata.totalamountafterdiscount}/-</h3>
                    </div>
                </div>
                <div style={{borderBottom:"1px solid black",borderLeft:"1px solid black",borderRight:"1px solid black", display:"flex"}}>
                    <div style={{width:"60%",padding:"5px", borderRight:"1px solid black"}}>
                        <h3 style={{fontSize:"12px", fontWeight:"500"}}>Total Payables</h3>
                    </div>
                    <div style={{width:"40%",padding:"5px"}}>
                        <h3 style={{fontSize:"12px", fontWeight:"400",textAlign:"right"}}>PKR {billingdata.payables}/-</h3>
                    </div>
                </div>
            </div>
        </div>
        <div style={{height:"2px", backgroundColor:"black",margin:"10px 0px"}}></div>
        </>
    }   
    </div>
  )
}

export default Index
