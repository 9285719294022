import React from 'react'

const Primaryaltbtn = ({Value,Action}) => {
  return (
    <div className='public_primaryalt_btn'>
      <button onClick={Action}>
        <div className='public_primaryalt_btn_container'>
            <div className='public_primaryalt_btn_text'>
                <p>{Value}</p>
            </div>
            <div className='public_primaryalt_btn_background'></div>
        </div>
      </button>
    </div>
  )
}

export default Primaryaltbtn
