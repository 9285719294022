import React from 'react'
import { useRef, useState, useEffect } from 'react'
import './index.css'

const Index = ({Lable,Value,setValue,Options}) => {
    const selectBox = useRef(null);
    const [filterList,setfilterList] = useState(Options);
    function filterFunction(value){
      if(value === ""){
        setfilterList(Options);
      }
      else{
        const filtedList = Options.filter((obj)=>{
          return Object.values(obj)
          .join(" ").toLowerCase()
          .includes(value.toLowerCase());
        })
        setfilterList(filtedList);
      }
    }
    function Toggle(){
        if(selectBox.current.className.includes('active')){
            selectBox.current.className = 'panelcontrols_select_wrapper';
        }
        else{
            selectBox.current.className = 'panelcontrols_select_wrapper active';
        }
    }
    useEffect(()=>{
        setfilterList(Options);
    },[Options])
  return (
    <div className='panelcontrols_select_main_container'>
        <label>{Lable}</label>
        <div ref={selectBox} className='panelcontrols_select_wrapper'>
            <div className='panelcontrols_select_container'>
                <div className='panelcontrols_select_value' onClick={Toggle}>
                    <h6>{Value.name}</h6>
                </div>
                <div className='panelcontrols_select_options'>
                    <input style={
                        {
                            padding:"5px 2px",
                            width:"100%"
                        }
                    } onChange={(e) => {
                        filterFunction(e.target.value);
                    }}/>
                    {
                        filterList.map((item,index)=>{
                            return(
                                <div className='panelcontrols_select_option' onClick={()=>{setValue(item); Toggle();}} key={index}>
                                    <h6>{item.name}</h6>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Index
