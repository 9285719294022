import React from 'react'
import './index.css'
import SampleImage1 from '../../../../images/public2/2.jpg'
import SampleImage2 from '../../../../images/public2/3.jpg'
import SampleImage3 from '../../../../images/public2/4.jpg'
import SampleImage4 from '../../../../images/public2/5.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay  } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const Index = () => {
    const slides = [
        SampleImage1,
        SampleImage2,
        SampleImage3,
        SampleImage4
    ]
  return (
    <div className='public_home_samples_container'>
      <div className='public_home_samples_row'>
        <div className='public_home_samples_col35'>
          <div className='public_home_samples_details_container'>
            <h3>SOME EXAMPLES OF OUR PROFESSION</h3>
            <p>At N Vision Lab, we are passionate about transforming smiles and improving dental health through our exceptional craftsmanship and dedication to excellence. Our team of highly skilled and experienced technicians works diligently to produce dental restorations that are both functional and aesthetically pleasing.</p>
          </div>
        </div>
        <div className='public_home_samples_col60'>
          <div className='public_home_samples_Images_container'>
            <Swiper
              modules={[Navigation,Autoplay]}
              slidesPerView={1}
              navigation={true}
              scrollbar={{ draggable: true }}
              autoplay={{
                  delay: 5000,
                  disableOnInteraction: false
              }}
            >
            {
                slides.map((item,index)=>{
                    return(
                    <SwiperSlide key={index}>
                        <div className='public_home_samples_Image_container'>
                            <img src={item}/>
                        </div>
                    </SwiperSlide>
                    )
                })
            }
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
