import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Input from '../../../components/panelcontrols/panelinput'
import TextArea from '../../../components/panelcontrols/paneltextarea'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import './index.css'
import Table from './expenseTable'
import Functions from './functions'
import { ToastContainer, toast } from 'react-toastify';
import Edit from './edit'

const Index = () => {
    const [title,setTitle] = useState('');
    const [description,setDescription] = useState('');
    const [qty,setQty] = useState(0);
    const [amount,setAmount] = useState(0);
    const [total,setTotal] = useState(0);
    const [date,setDate] = useState('');
    const [List,setList] = useState([]);
    const [selectedExpense,setSelectedExpense] = useState(null); 
    const [showEdit,setShowEdit] = useState(false); 
    useEffect(()=>{
        if(qty === '' || amount === ''){
            setTotal(0);
        }
        else{
            setTotal(qty * amount);
        }
    },[amount,qty])
    useEffect(()=>{
      Functions.Get(setList);
    },[])
    const reset = ()=>{
      setTitle('');
      setDescription('')
      setQty(0)
      setAmount(0)
      setDate('')
    }
  return (
    <div className='labadmin_expense_container'>
      <Title Value={'Expenses'}/>
      <div className='admin_expense_formbox'>
        <div className='admin_expense_row'>
            <div className='admin_expense_col50'>
                <Input Lable={'Title'} Type={'text'} Value={title} setValue={setTitle}/>
                <TextArea Lable={'Description'} Type={'text'} Value={description} setValue={setDescription}/>
            </div>
            <div className='admin_expense_col50'>
              <div className='admin_expense_row'>
                <div className='admin_expense_col50'>
                  <Input Lable={'Qty'} Type={'number'} Value={qty} setValue={setQty}/>
                </div>
                <div className='admin_expense_col50'>
                  <Input Lable={'Amount'} Type={'number'} Value={amount} setValue={setAmount}/>
                </div>
              </div>
              <Input Lable={'Total'} Type={'number'} Value={total} setValue={setTotal} readonly={true}/>
              <Input Lable={'Date'} Type={'date'} Value={date} setValue={setDate}/>
            </div>
        </div>
        <Button Value={'Save'} Action={()=>{
          const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
          if(title === '' || amount === 0 || qty === 0 || date === ''){
            toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            return;
          }
          Functions.Post({
            title : title,
            description : description,
            amount : amount,
            qty: qty,
            total : total,
            date : date
          },setList,toast,toastid,reset)
        }}/>
      </div>
      <Table List={List} setList={setList} setSelectedExpense={setSelectedExpense} setShowEdit={setShowEdit} toast={toast}/>
      {showEdit &&
        <Edit selectedExpense={selectedExpense} setSelectedExpense={setSelectedExpense} toast={toast} setList={setList} setShowEdit={setShowEdit}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default Index
