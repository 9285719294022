import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Functions from './function'
import Logo from '../../../images/public/websitefooter.png'

const Index = () => {
    const [searchParams] = useSearchParams();
    const [formData, setFormData]= useState(null);
    useEffect(()=>{
        Functions.Get(searchParams.get('fdate'),searchParams.get('tdate'),setFormData);
    },[])
  return (
    <div style={{margin:"20px"}}>
        <div style={{width:"200px",margin:"20px auto"}}>
            <img src={Logo} style={{width:"100%"}}/>
        </div>
        <h1 style={{padding:"20px 0px", textAlign:"center", fontSize:"24px", fontWeight:"500"}}>Ranged Sales Report Dated : {searchParams.get('fdate')} - {searchParams.get('tdate')}</h1>
        {formData &&
            <>
            <div style={{display:"flex",border:"1px solid black"}}>
                <div style={{width:"6%", borderRight:"1px solid black", padding:"5px"}}>
                    <h3 style={{fontSize:"12px",fontWeight:"500"}}>In #</h3>
                </div>
                <div style={{width:"11%", borderRight:"1px solid black", padding:"5px"}}>
                    <h3 style={{fontSize:"12px",fontWeight:"500"}}>Doctor</h3>
                </div>
                <div style={{width:"15%", borderRight:"1px solid black", padding:"5px"}}>
                    <h3 style={{fontSize:"12px",fontWeight:"500"}}>Patient</h3>
                </div>
                <div style={{width:"60%", display:"flex"}}>
                    <div style={{width:"40%", borderRight:"1px solid black", padding:"5px"}}>
                        <h3 style={{fontSize:"12px",fontWeight:"500"}}>Work type</h3>
                    </div>
                    <div style={{width:"53%", borderRight:"1px solid black", padding:"5px"}}>
                        <h3 style={{fontSize:"12px",fontWeight:"500"}}>Tooth #</h3>
                    </div>
                    <div style={{width:"7%", borderRight:"1px solid black", padding:"5px"}}>
                        <h3 style={{fontSize:"12px",fontWeight:"500"}}>Ut</h3>
                    </div>
                </div>
                <div style={{width:"8%", padding:"5px"}}>
                    <h3 style={{fontSize:"12px",fontWeight:"500",textAlign:"right"}}>Amt</h3>
                </div>
            </div>
            {formData.list.length === 0 &&
                <div style={{display:"flex",justifyContent:"center", padding:"5px"}}>
                    <p>No Data found</p>
                </div>
            }
            {formData.list.length !== 0 &&
                <>
                {
                    formData.list.map((item,index)=>{
                        return(
                            <div key={index} style={{display:"flex",borderLeft:"1px solid black",borderRight:"1px solid black"}}>
                                <div style={{width:"6%",borderBottom:"1px solid black", borderRight:"1px solid black", padding:"5px"}}>
                                    <h3 style={{fontSize:"10px",fontWeight:"500"}}>{item.id}</h3>
                                </div>
                                <div style={{width:"11%",borderBottom:"1px solid black", borderRight:"1px solid black", padding:"5px"}}>
                                    <h3 style={{fontSize:"10px",fontWeight:"500"}}>{item.doctorname}</h3>
                                </div>
                                <div style={{width:"15%",borderBottom:"1px solid black", borderRight:"1px solid black", padding:"5px"}}>
                                    <h3 style={{fontSize:"10px",fontWeight:"500"}}>{item.patient}</h3>
                                </div>
                                <div style={{width:"60%"}}>
                                {
                                    item.items.map((iteminitem,index2)=>{
                                        return(
                                            <div key={index2} style={{display:"flex", borderBottom:"1px solid black"}}>
                                                <div style={{width:"40%", borderRight:"1px solid black", padding:"5px"}}>
                                                    <h3 style={{fontSize:"10px",fontWeight:"500"}}>{iteminitem.productname} / {iteminitem.servicename}</h3>
                                                </div>
                                                <div style={{width:"53%", borderRight:"1px solid black", padding:"5px"}}>
                                                    <div style={{width:'100%',display:"flex",flexWrap:"wrap"}}>
                                                        <div style={{width:"50%",borderRight:"1px solid black",borderBottom:"1px solid black",height:"15px", display:"flex", alignItems:"flex-end", justifyContent:"flex-end"}}>
                                                            <p style={{padding:"1px", fontSize:"10px"}}>{iteminitem.sections.S1 !== 0 &&
                                                            iteminitem.sections.S1
                                                            }</p>
                                                        </div>
                                                        <div style={{width:"50%",borderBottom:"1px solid black",height:"15px", display:"flex", alignItems:"flex-end", justifyContent:"flex-start"}}>
                                                            <p style={{padding:"1px", fontSize:"10px"}}>{iteminitem.sections.S2 !== 0 &&
                                                            iteminitem.sections.S2
                                                            }</p>
                                                        </div>
                                                        <div style={{width:"50%",borderRight:"1px solid black",height:"15px", display:"flex", alignItems:"flex-start", justifyContent:"flex-end"}}>
                                                            <p style={{padding:"1px", fontSize:"10px"}}>{iteminitem.sections.S3 !== 0 &&
                                                            iteminitem.sections.S3
                                                            }</p>
                                                        </div>
                                                        <div style={{width:"50%",height:"15px", display:"flex", alignItems:"flex-start", justifyContent:"flex-start"}}>
                                                            <p style={{padding:"1px", fontSize:"10px"}}>{iteminitem.sections.S4 !== 0 &&
                                                            iteminitem.sections.S4
                                                            }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{width:"7%", borderRight:"1px solid black", padding:"5px"}}>
                                                    <h3 style={{fontSize:"10px",fontWeight:"500"}}>{
                                                        parseInt(parseInt(iteminitem.sections.S1)+parseInt(iteminitem.sections.S2)+parseInt(iteminitem.sections.S3)+parseInt(iteminitem.sections.S4))
                                                    }</h3>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                                <div style={{width:"8%",borderBottom:"1px solid black", padding:"5px"}}>
                                    <h3 style={{fontSize:"10px",fontWeight:"500",textAlign:"right"}}>{item.subtotal}</h3>
                                </div>
                            </div>
                        )
                    })
                }
                <div style={{display:"flex", justifyContent:"flex-end"}}>
                    <div style={{borderBottom:"1px solid black", padding:"10px 0px"}}>
                        <p style={{fontSize:"18px"}}><b style={{fontSize:"18px", fontWeight:"500"}}>Total: </b>{formData.closingbalance}</p>
                    </div>
                </div>
                </>
            }
            </>
        }
    </div>
  )
}

export default Index
