import React from 'react'
import './index.css'

const Index = () => {
  return (
    <div className='public_privacypolicy_content_container'>
      <h3>Information We Collect</h3>
      <p>We may collect personal information from dental professionals and dental clinics (our "Clients") to provide and improve our Services. This information may include:</p>
      <ul>
        <li>Contact Information: Names, email addresses, phone numbers, and postal addresses.</li>
        <li>Professional Details: Professional titles, dental practice affiliations, and credentials.</li>
        <li>Transaction Information: Information related to orders, payments, and invoices.</li>
        <li>Technical Information: Usage details, IP addresses, browser types, and device identifiers.</li>
      </ul>
      <h3>How We Use Your Information</h3>
      <p>We use the collected information for various purposes, including:</p>
      <ul>
        <li>Providing and improving our Services, including digital dental design and manufacturing.</li>
        <li>Communicating with you about orders, inquiries, and updates.</li>
        <li>Enhancing user experience and customer support.</li>
        <li>Complying with legal obligations and protecting our rights.</li>
      </ul>
      <h3>Information Sharing and Disclosure</h3>
      <p>We do not share personal information with third parties for their direct marketing purposes. However, we may share information with service providers and business partners who assist in our operations. Your information may also be shared to comply with legal obligations or to protect our rights and the rights of others.</p>
      <h3>Data Security</h3>
      <p>We employ industry-standard measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. Despite our efforts, no data transmission over the Internet or electronic storage method is entirely secure. Therefore, we cannot guarantee absolute security.</p>
      <h3>Your Choices</h3>
      <p>You have the right to access, correct, and delete your personal information. You can update your preferences regarding communications from us. To, exercise these rights or for any inquiries, please contact us at [privacy@nvisiondental.com].</p>
      <h3>Cookies and Tracking Technologies</h3>
      <p>We may use cookies and similar technologies to enhance your experience on our website. You can modify your browser settings to refuse cookies or notify you when they are being used.</p>
      <h3>Changes to This Privacy Policy</h3>
      <p>We may update this Privacy Policy to reflect changes in our practices or legal requirements. Updates will be posted on our website and the "Last updated" date will be revised accordingly.</p>
      <h3>Contact Us</h3>
      <p>For any questions, concerns, or requests regarding this Privacy Policy, please contact our privacy team at [privacy@nvisiondental.com].<br></br>Please ensure that you review and adapt this privacy policy according to your specific business practices and legal requirements. If you have any further questions or need additional assistance, feel free to ask.</p>


    </div>
  )
}

export default Index
