import React from 'react'
import './index.css'

const Index = () => {
  return (
    <div className='public_about_innovative_wrapper'>
        <div className='public_about_innovative_title'>
            <h3>Innovative Digital Solutions</h3>
        </div>
      <div className='public_about_innovative_background'>
        <div className='public_about_innovative_container'>
            <p>N-Vision Digital Dental Laboratory is at the forefront of digital dentistry revolution. By embracing advanced digital techniques such as CAD/CAM design, 3D printing, and computer-guided milling, we have streamlined the dental restoration process, resulting in faster turnaround times without compromising on quality. Our digital solutions also provide an enhanced level of accuracy, ensuring a seamless fit and optimal comfort for patients.</p>
        </div>
      </div>
    </div>
  )
}

export default Index
