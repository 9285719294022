import React, { useEffect, useRef, useState } from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import './index.css'
import Functions from './functions';
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const [list,setList] = useState([]);
  const [message,setMessage] = useState('');
  const messagesEndRef = useRef(null)
  useEffect(()=>{
    Functions.Get((data)=>{
      setList(data);
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    });
  },[])
  return (
    <div> 
      <Title Value={'Messages'}/>
      <div className='doctor_messages_container'>
        <div className='doctor_messages_items'>
          {
            list.map((item,index)=>{
              return(
                <div key={index}>
                  {parseInt(item.type) === 1 &&
                    <div className='doctor_messages_items_mymessage'>
                      <p>{item.description}</p>
                    </div>
                  }
                  {parseInt(item.type) === 0 &&
                    <div className='doctor_messages_items_othermessage'>
                      <p>{item.description}</p>
                    </div>
                  }
                </div>
              )
            })
          }
          <div ref={messagesEndRef} />
        </div>
        <div className='doctor_messages_chatbox'>
          <input 
            type="text"
            placeholder="Enter your message"
            value={message} 
            onChange={(e)=>setMessage(e.target.value)}
          />
          <button onClick={()=>{
            const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
            if(message === ''){
              toast.update(toastid, { render: "Message is empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
              return;
            }
            Functions.Post({
              description : message
            },setList,toast,toastid,()=>{
              setMessage("");
            })
          }}><FontAwesomeIcon icon={faPaperPlane}/></button>
          
        </div>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default Index
