import React,{useState,useEffect} from 'react'
import Input from '../../../../components/panelcontrols/panelinput'
import TextArea from '../../../../components/panelcontrols/paneltextarea'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Functions from '../functions'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Index = ({selectedEmployee,setSelectedEmployee,toast,setList,setShowEdit}) => {
    const [name,setName] = useState('');
    const [designation,setDesignation] = useState('');
    const [image,setImage] = useState('');
    const [salary,setSalary] = useState(0);
    const [cnic,setCnic] = useState('');
    const [address,setAddress] = useState('');
    const [contact,setContact] = useState('');
    const [otherinformation,setOtherInformation] = useState('');
    useEffect(()=>{
        if(selectedEmployee){
            setName(selectedEmployee.name);
            setDesignation(selectedEmployee.designation);
            setSalary(selectedEmployee.salary);
            setCnic(selectedEmployee.cnic);
            setAddress(selectedEmployee.address);
            setContact(selectedEmployee.contact);
            setOtherInformation(selectedEmployee.otherinformation);
        }
        else{
            setName('');
            setDesignation('');
            setSalary(0);
            setCnic('');
            setAddress('');
            setContact('');
            setOtherInformation('');
        }
    },[selectedEmployee])
    const reset = ()=>{
        setSelectedEmployee(null)
        setShowEdit(false)
    }
  return (
    <div className='labadmin_product_edit_wrapper'>
        <div className='labadmin_product_edit_container'>
            <div className='labadmin_product_edit_close'>
                <button onClick={()=>{
                   reset(); 
                }}>
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            <div className='labadmin_product_formbox'>
                <h3>Edit Employee</h3>
                <div className='labadmin_employee_row'>
                <div className='labadmin_employee_col50'>
                    <Input Lable={'Name'} Type={'text'} Value={name} setValue={setName}/>
                    <Input Lable={'Designation'} Type={'text'} Value={designation} setValue={setDesignation}/>
                    <Input Lable={'Contact'} Type={'text'} Value={contact} setValue={setContact}/>
                    <TextArea Lable={'Address'} Placeholder={'Enter address'} Value={address} setValue={setAddress}/>
                </div>
                <div className='labadmin_employee_col50'>
                    <Input Lable={'Salary'} Type={'number'} Value={salary} setValue={setSalary}/>
                    <Input Lable={'Cnic'} Type={'text'} Value={cnic} setValue={setCnic}/>
                    <TextArea Lable={'Other information'} Placeholder={'Enter other information'} Value={otherinformation} setValue={setOtherInformation}/>
                </div>
                </div>
                <Button Value={'Save'} Action={()=>{
                    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                    if(name === ''){
                      toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                      return;
                    }
                    Functions.Put(selectedEmployee.id,{
                        name: name, 
                        designation : designation,
                        image : image,
                        salary : salary,
                        contact : contact,
                        cnic : cnic,
                        address : address,
                        otherinformation : otherinformation,
                        status : 'Active'
                    },setList,toast,toastid,reset)
                }}/>
            </div>
        </div>
    </div>
  )
}

export default Index
