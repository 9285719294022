import React,{useState,useEffect} from 'react'
import Input from '../../../../components/panelcontrols/panelinput'
import TextArea from '../../../../components/panelcontrols/paneltextarea'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Functions from '../functions'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Index = ({selectedExpense,setSelectedExpense,toast,setList,setShowEdit}) => {
    const [title,setTitle] = useState('');
    const [description,setDescription] = useState('');
    const [qty,setQty] = useState(0);
    const [amount,setAmount] = useState(0);
    const [total,setTotal] = useState(0);
    const [date,setDate] = useState('');
    useEffect(()=>{
        if(qty === '' || amount === ''){
            setTotal(0);
        }
        else{
            setTotal(qty * amount);
        }
    },[amount,qty])
    useEffect(()=>{
        if(selectedExpense){
            setTitle(selectedExpense.title);
            setDescription(selectedExpense.description);
            setQty(selectedExpense.qty);
            setAmount(selectedExpense.amount);
            setTotal(selectedExpense.total);
            setDate(selectedExpense.date);
        }
        else{
            setTitle('');
            setDescription('');
            setQty(0);
            setAmount(0);
            setTotal(0);
            setDate('');
        }
    },[selectedExpense])
    const reset = ()=>{
        setSelectedExpense(null)
        setShowEdit(false)
    }
  return (
    <div className='labadmin_product_edit_wrapper'>
        <div className='labadmin_product_edit_container'>
            <div className='labadmin_product_edit_close'>
                <button onClick={()=>{
                   reset(); 
                }}>
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            <div className='labadmin_product_formbox'>
                <h3>Edit Expense</h3>
                <div className='admin_expense_row'>
                    <div className='admin_expense_col50'>
                        <Input Lable={'Title'} Type={'text'} Value={title} setValue={setTitle}/>
                        <TextArea Lable={'Description'} Type={'text'} Value={description} setValue={setDescription}/>
                    </div>
                    <div className='admin_expense_col50'>
                    <div className='admin_expense_row'>
                        <div className='admin_expense_col50'>
                        <Input Lable={'Qty'} Type={'number'} Value={qty} setValue={setQty}/>
                        </div>
                        <div className='admin_expense_col50'>
                        <Input Lable={'Amount'} Type={'number'} Value={amount} setValue={setAmount}/>
                        </div>
                    </div>
                    <Input Lable={'Total'} Type={'number'} Value={total} setValue={setTotal} readonly={true}/>
                    <Input Lable={'Date'} Type={'date'} Value={date} setValue={setDate}/>
                    </div>
                </div>
                <Button Value={'Save'} Action={()=>{
                    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                    if(title === '' || amount === 0 || qty === 0 || date === ''){
                      toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                      return;
                    }
                    Functions.Put(selectedExpense.id,{
                        title : title,
                        description : description,
                        amount : amount,
                        qty: qty,
                        total : total,
                        date : date
                    },setList,toast,toastid,reset)
                }}/>
            </div>
        </div>
    </div>
  )
}

export default Index
