import React from 'react'
import ServiceBgImage from '../../../images/public/03.png' 
import ImplantsImage from '../../../images/public/04.png'
import VeneersImage from '../../../images/public/05.png'
import AlignersImage from '../../../images/public/06.png'
import { Link } from 'react-router-dom'

const Services = () => {
    const List = [
        {
            name: "Implants",
            image: ImplantsImage,
            discription:"Dental implants are a revolutionary solution for replacing missing teeth, providing a natural and long-lasting solution that enhances both aesthetics and functionality."
        },
        {
            name: "Veneers",
            image: VeneersImage,
            discription:"Veneers are thin, custom-made shells made of high-quality porcelain or composite material that are carefully bonded to the front surface of your teeth, transforming their appearance and giving you the perfect smile you've always desired."
        },
        {
            name: "Aligners",
            image: AlignersImage,
            discription:"Clear aligners are a modern orthodontic treatment that discreetly and effectively straighten your teeth without the use of traditional braces."
        }
    ]
  return (
    <div className='public_home_services_wrapper' style={{backgroundImage:`url(${ServiceBgImage})`}}>
      <div className='public_home_services_container'>
        <Link to={'/services'}>
            <div className='public_home_services_row'>
                {
                    List.map((item,index)=>{
                        return(
                            <div className='public_home_services_item_container' key={index}>
                                <ServiceItem Item={item}/>
                            </div>
                        )
                    })
                }
                <div className='public_home_services_seemore_container'>
                    <h3 className='colorprimary'>See More</h3>
                </div>
            </div>
        </Link>
      </div>
    </div>
  )
}

const ServiceItem = ({Item})=>{
    return(
        <>
            <div className='public_home_services_item_image'>
                <img src={Item.image}/>
            </div>
            <div className='public_home_services_item_disciption'>
                <h3 className='colorprimary'>{Item.name}</h3>
                <p>{Item.discription}</p>
            </div>
        </>
    )
}

export default Services
