import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import './index.css'
import Leftbar from './leftbar'
import Rightbar from './rightbar'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Index = () => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    const [mobileopen,setMobileOpen] = useState(false);
    if(!User){
        return <Navigate to={"/login"}/>
    }
    else{
        if(User.role !== 'admin'){
            return <Navigate to={"/login"}/>
        }
    }
    return (
        <div className='admin_layout_container'>
            <Leftbar mobileopen={mobileopen} setMobileOpen={setMobileOpen}/>
            <div className='admin_layout_body_wrapper'>
                <div className='mobile_menu_opener'>
                    <button onClick={()=>{setMobileOpen(true)}}>
                        <FontAwesomeIcon icon={faBars}/>
                    </button>
                </div>
                <div className='admin_layout_body_container'>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default Index
