import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay  } from 'swiper';
import BgImage from '../../../images/public/08.png'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import T1Image from '../../../images/public/09.png'
import T2Image from '../../../images/public/10.png'
import T3Image from '../../../images/public/11.png'
import T4Image from '../../../images/public/12.png'
import T5Image from '../../../images/public/13.png'

const Testimonials = () => {
    const slides = [
        {
            name: "James Thompson",
            image: T1Image,
            testimonial: "I collaborated with N Vision Digital Dental Laboratory on a intricate dental restoration, and I'm absolutely delighted with the outcomes. The meticulousness and artistry they demonstrated in crafting a personalized dental prosthesis for my patient were truly remarkable. The team conducted themselves with great professionalism, ensuring a harmonious integration of the restoration with my patient's original teeth. I wholeheartedly endorse N Vision Digital Dental Laboratory for their skillfulness and dedication to providing remarkable dental restorations."
        },
        {
            name: "Charlotte Evans",
            image: T2Image,
            testimonial: "N Vision Digital Dental Lab has gone above and beyond my expectations with their outstanding service and premium dental restorations. I have partnered with them on numerous cases, and their dedication to meticulous detail and precision is unmatched. The caliber of their work is exceptional, and they consistently produce restorations that boast impeccable fit and an authentically natural appearance. Their team, known for its expertise and approachability, ensures an absolute and pleasurable experience throughout the process. I enthusiastically endorse N Vision Dental Digital Lab to anyone in search of superior dental lab services."
        },
        {
            name: "William Harrison",
            image: T3Image,
            testimonial: "I had the opportunity to work with N Vision Digital Dental Laboratory for the restoration of my client dental implant, and I must express my deep admiration for their skill and professionalism. Throughout the journey, starting from the initial consultation and culminating in the final restoration, the experience was marked by its effortless flow and effectiveness. The specialists at Implant Solutions dedicated ample time to grasp my unique requirements, crafting a bespoke implant that harmoniously blends with my client natural teeth. The ultimate outcome is a smile that is not only aesthetically pleasing but also fully functional, and I take immense pride in it. I enthusiastically endorse N Vision Dental Digital Lab for its outstanding implant services."
        },
        {
            name: "Olivia Mitchell",
            image: T4Image,
            testimonial: "N Vision Digital Dental Lab has made a remarkable impact on my patient’s smile, elevating my patient’s self-assurance to new heights, Honestly this is the words of my patient and I believe all the credit goes to the professionals at N Vision possess exceptional expertise and a wealth of knowledge. They displayed extraordinary dedication in designing veneers that flawlessly mirror the characteristics of my patient’s original teeth. The process itself was remarkably comfortable and devoid of discomfort, with the outcomes surpassing all my anticipations. The veneers, exquisitely fashioned, have wrought a complete transformation upon my patient’s smile. My heartfelt gratitude extends to N Vision Digital Dental Lab for their amazing proficiency, and I wholeheartedly endorse them to individuals seeking a radiant smile transformation."
        },
        {
            name: "Benjamin Parker",
            image: T5Image,
            testimonial: "N Vision Digital Dental Lab has truly been a catalyst in my client’s journey towards a more aligned smile. The clear aligners they provided were a revelation, ultimately integrating comfort and invisibility into my patient daily routine. The watchful eyes of N Vision Dental Digital Lab's adept orthodontists ensured a well-guided treatment, their support readily available for any queries or refinements. The culmination of this experience has left me elated, as my teeth now stand in harmonious alignment. For an orthodontic solution that marries effectiveness with convenience, I wholeheartedly endorse N Vision Lab."
        }
    ]
  return (
    <div className='public_home_testimonials_wrapper'>
      <div className='public_home_testimonials_container'>
        <h3>Testimonials</h3>
      <Swiper
        modules={[Navigation,Autoplay]}
        slidesPerView={1}
        navigation={true}
        scrollbar={{ draggable: true }}
        autoplay={{
            delay: 5000,
            disableOnInteraction: false
        }}
        >
        {
            slides.map((item,index)=>{
                return(
                <SwiperSlide key={index}>
                    <TestimonialItem item={item}/>
                </SwiperSlide>
                )
            })
        }
        </Swiper>
      </div>
    </div>
  )
}

const TestimonialItem = ({item})=>{
    return(
        <div className='public_home_testimonials_item_container'>
            {/*
            <div className='public_home_testimonials_item_image' style={{backgroundImage:`url(${item.image})`}}></div>

             */}
            <div className='public_home_testimonials_item_details'>
                {/*<h3 className='colorprimary'>{item.name}</h3>*/}
                <p>"{item.testimonial}"</p>
            </div>
        </div>
    )
}

export default Testimonials
