import React,{useState,useEffect} from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import './index.css'
import Table from './transectionsTable'

const Index = () => {
    const [list,setList] = useState([]);
  return (
    <div className='labadmin_transections_container'>
        <Title Value={'Transections'}/>
        <Table List={list}/>
    </div>
  )
}

export default Index
