import React, { useEffect, useState } from 'react'
import Function from './function';
import Box from './box'
import Title from '../../../components/panelcontrols/paneltitle'
import './index.css'

const Index = () => {
  const [labs,setLabs] = useState(0);
  const [users,setUsers] = useState(0);
  const [doctors,setDoctors] = useState(0);
  const [orders,setOrders] = useState(0);
  useEffect(()=>{
    Function.Get((data)=>{
      setLabs(data.labs)
      setUsers(data.users)
      setDoctors(data.doctors)
      setOrders(data.orders)
    })
  },[])
  return (
    <div>
      <Title Value={'Dashboard'}/>
      <div className='admin_dashboard_container'>
        <div className='admin_dashboard_row'>
          <div className='admin_dashboard_w50'>
            <div className='admin_dashboard_row'>
              <div className='admin_dashboard_w50'>
                <Box Title={'Users'} Count={users}/>
              </div>
              <div className='admin_dashboard_w50'>
                <Box Title={'Labs'} Count={labs}/>
              </div>
            </div>
            <div className='admin_dashboard_row'>
              <div className='admin_dashboard_w50'>
                <Box Title={'Doctors'} Count={doctors}/>
              </div>
              <div className='admin_dashboard_w50'>
                <Box Title={'Orders'} Count={orders}/>
              </div>
            </div>
          </div>
          <div className='admin_dashboard_w50'></div>
        </div>
      </div>
    </div>
  )
}

export default Index
