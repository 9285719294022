import React, {useState} from 'react'
import Input from '../../../components/panelcontrols/panelinput'
import TextArea from '../../../components/panelcontrols/paneltextarea'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import Functions from './functions'

const Country = ({setValues}) => {
    const [name,setName] = useState('');
    const [remarks,setRemarks] = useState('');
  return (
    <>
        <Input Lable={'Name'} Type={'text'} Value={name} setValue={setName}/>
        <TextArea Lable={'Remarks'} Value={remarks} setValue={setRemarks}/>
        <Button Value={'Save'} Action={()=>{
            if(name === ''){
                alert('fields empty');
                return;
            }
            Functions.PostCountry({
                name:name,
                remarks:remarks
            },(data)=>{
                setValues(data);
            });
        }}/>
    </>
  )
}

export default Country
