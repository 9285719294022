import React from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

const Index = ({Action}) => {
  return (
    <div className='panelcontrols_paneltablebuttons_print'>
      <button onClick={Action}><FontAwesomeIcon icon={faPrint}/></button>
    </div>
  )
}

export default Index
