import React from 'react'
import './index.css'
import SingleUnit from './singleUnit'
import Image11 from '../../../../../../../images/tooth/11.png'
import Image11a from '../../../../../../../images/tooth/11a.png'
import Image12 from '../../../../../../../images/tooth/12.png'
import Image12a from '../../../../../../../images/tooth/12a.png'
import Image13 from '../../../../../../../images/tooth/13.png'
import Image13a from '../../../../../../../images/tooth/13a.png'
import Image14 from '../../../../../../../images/tooth/14.png'
import Image14a from '../../../../../../../images/tooth/14a.png'
import Image15 from '../../../../../../../images/tooth/15.png'
import Image15a from '../../../../../../../images/tooth/15a.png'
import Image16 from '../../../../../../../images/tooth/16.png'
import Image16a from '../../../../../../../images/tooth/16a.png'
import Image17 from '../../../../../../../images/tooth/17.png'
import Image17a from '../../../../../../../images/tooth/17a.png'
import Image18 from '../../../../../../../images/tooth/18.png'
import Image18a from '../../../../../../../images/tooth/18a.png'
import Image21 from '../../../../../../../images/tooth/21.png'
import Image21a from '../../../../../../../images/tooth/21a.png'
import Image22 from '../../../../../../../images/tooth/22.png'
import Image22a from '../../../../../../../images/tooth/22a.png'
import Image23 from '../../../../../../../images/tooth/23.png'
import Image23a from '../../../../../../../images/tooth/23a.png'
import Image24 from '../../../../../../../images/tooth/24.png'
import Image24a from '../../../../../../../images/tooth/24a.png'
import Image25 from '../../../../../../../images/tooth/25.png'
import Image25a from '../../../../../../../images/tooth/25a.png'
import Image26 from '../../../../../../../images/tooth/26.png'
import Image26a from '../../../../../../../images/tooth/26a.png'
import Image27 from '../../../../../../../images/tooth/27.png'
import Image27a from '../../../../../../../images/tooth/27a.png'
import Image28 from '../../../../../../../images/tooth/28.png'
import Image28a from '../../../../../../../images/tooth/28a.png'
import Image31 from '../../../../../../../images/tooth/31.png'
import Image31a from '../../../../../../../images/tooth/31a.png'
import Image32 from '../../../../../../../images/tooth/32.png'
import Image32a from '../../../../../../../images/tooth/32a.png'
import Image33 from '../../../../../../../images/tooth/33.png'
import Image33a from '../../../../../../../images/tooth/33a.png'
import Image34 from '../../../../../../../images/tooth/34.png'
import Image34a from '../../../../../../../images/tooth/34a.png'
import Image35 from '../../../../../../../images/tooth/35.png'
import Image35a from '../../../../../../../images/tooth/35a.png'
import Image36 from '../../../../../../../images/tooth/36.png'
import Image36a from '../../../../../../../images/tooth/36a.png'
import Image37 from '../../../../../../../images/tooth/37.png'
import Image37a from '../../../../../../../images/tooth/37a.png'
import Image38 from '../../../../../../../images/tooth/38.png'
import Image38a from '../../../../../../../images/tooth/38a.png'
import Image41 from '../../../../../../../images/tooth/41.png'
import Image41a from '../../../../../../../images/tooth/41a.png'
import Image42 from '../../../../../../../images/tooth/42.png'
import Image42a from '../../../../../../../images/tooth/42a.png'
import Image43 from '../../../../../../../images/tooth/43.png'
import Image43a from '../../../../../../../images/tooth/43a.png'
import Image44 from '../../../../../../../images/tooth/44.png'
import Image44a from '../../../../../../../images/tooth/44a.png'
import Image45 from '../../../../../../../images/tooth/45.png'
import Image45a from '../../../../../../../images/tooth/45a.png'
import Image46 from '../../../../../../../images/tooth/46.png'
import Image46a from '../../../../../../../images/tooth/46a.png'
import Image47 from '../../../../../../../images/tooth/47.png'
import Image47a from '../../../../../../../images/tooth/47a.png'
import Image48 from '../../../../../../../images/tooth/48.png'
import Image48a from '../../../../../../../images/tooth/48a.png'

const Index = ({List,setList}) => {
  const selectAllUpperJaw = () => {
    const upperIds = [11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28];
    const sendData = upperIds.map(id => ({ id }));
    setList(sendData);
  };
  const selectAllLowerJaw = () => {
    const upperIds = [41, 42, 43, 44, 45, 46, 47, 48, 31, 32, 33, 34, 35, 36, 37, 38];
    const sendData = upperIds.map(id => ({ id }));
    setList(sendData);
  };
  const selectAllJaw = () => {
    const upperIds = [11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28, 41, 42, 43, 44, 45, 46, 47, 48, 31, 32, 33, 34, 35, 36, 37, 38];
    const sendData = upperIds.map(id => ({ id }));
    setList(sendData);
  };
  return (
    <div className='labadmin_orders_new_lineitems_add_units_container'>
      <div className='labadmin_orders_new_lineitems_add_units_upper_container'>
        <SingleUnit List={List} setList={setList} id={11} image={Image11} imagea={Image11a}/>
        <SingleUnit List={List} setList={setList} id={12} image={Image12} imagea={Image12a}/>
        <SingleUnit List={List} setList={setList} id={13} image={Image13} imagea={Image13a}/>
        <SingleUnit List={List} setList={setList} id={14} image={Image14} imagea={Image14a}/>
        <SingleUnit List={List} setList={setList} id={15} image={Image15} imagea={Image15a}/>
        <SingleUnit List={List} setList={setList} id={16} image={Image16} imagea={Image16a}/>
        <SingleUnit List={List} setList={setList} id={17} image={Image17} imagea={Image17a}/>
        <SingleUnit List={List} setList={setList} id={18} image={Image18} imagea={Image18a}/>
        <SingleUnit List={List} setList={setList} id={21} image={Image21} imagea={Image21a}/>
        <SingleUnit List={List} setList={setList} id={22} image={Image22} imagea={Image22a}/>
        <SingleUnit List={List} setList={setList} id={23} image={Image23} imagea={Image23a}/>
        <SingleUnit List={List} setList={setList} id={24} image={Image24} imagea={Image24a}/>
        <SingleUnit List={List} setList={setList} id={25} image={Image25} imagea={Image25a}/>
        <SingleUnit List={List} setList={setList} id={26} image={Image26} imagea={Image26a}/>
        <SingleUnit List={List} setList={setList} id={27} image={Image27} imagea={Image27a}/>
        <SingleUnit List={List} setList={setList} id={28} image={Image28} imagea={Image28a}/>
        <SingleUnit List={List} setList={setList} id={41} image={Image41} imagea={Image41a}/>
        <SingleUnit List={List} setList={setList} id={42} image={Image42} imagea={Image42a}/>
        <SingleUnit List={List} setList={setList} id={43} image={Image43} imagea={Image43a}/>
        <SingleUnit List={List} setList={setList} id={44} image={Image44} imagea={Image44a}/>
        <SingleUnit List={List} setList={setList} id={45} image={Image45} imagea={Image45a}/>
        <SingleUnit List={List} setList={setList} id={46} image={Image46} imagea={Image46a}/>
        <SingleUnit List={List} setList={setList} id={47} image={Image47} imagea={Image47a}/>
        <SingleUnit List={List} setList={setList} id={48} image={Image48} imagea={Image48a}/>
        <SingleUnit List={List} setList={setList} id={31} image={Image31} imagea={Image31a}/>
        <SingleUnit List={List} setList={setList} id={32} image={Image32} imagea={Image32a}/>
        <SingleUnit List={List} setList={setList} id={33} image={Image33} imagea={Image33a}/>
        <SingleUnit List={List} setList={setList} id={34} image={Image34} imagea={Image34a}/>
        <SingleUnit List={List} setList={setList} id={35} image={Image35} imagea={Image35a}/>
        <SingleUnit List={List} setList={setList} id={36} image={Image36} imagea={Image36a}/>
        <SingleUnit List={List} setList={setList} id={37} image={Image37} imagea={Image37a}/>
        <SingleUnit List={List} setList={setList} id={38} image={Image38} imagea={Image38a}/>
        <p className='labadmin_orders_new_lineitems_add_units_upper_text' onClick={selectAllUpperJaw}>Upper Jaw</p>
        <p className='labadmin_orders_new_lineitems_add_units_Mid_text' onClick={selectAllJaw}>Both Jaw</p>
        <p className='labadmin_orders_new_lineitems_add_units_lower_text' onClick={selectAllLowerJaw}>Lower Jaw</p>
      </div>
    </div>
  )
}

export default Index
