import React from 'react'
import './index.css'

const Index = ({Lable,Placeholder,Value,setValue}) => {
  return (
    <div className='panelcontrols_paneltextarea' style={{positios:"relative"}}>
      <label>{Lable}</label>
      <textarea
        placeholder={Placeholder}
        value={Value}
        onChange={(e)=>setValue(e.target.value)}
      />
        
    </div>
  )
}

export default Index
