import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Input from '../../../components/panelcontrols/panelinput'
import TextArea from '../../../components/panelcontrols/paneltextarea'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import './index.css'
import Table from './employeeTable'
import Functions from './functions'
import { ToastContainer, toast } from 'react-toastify';
import Edit from './edit'

const Index = () => {
    const [name,setName] = useState('');
    const [designation,setDesignation] = useState('');
    const [image,setImage] = useState('');
    const [salary,setSalary] = useState(0);
    const [cnic,setCnic] = useState('');
    const [address,setAddress] = useState('');
    const [contact,setContact] = useState('');
    const [otherinformation,setOtherInformation] = useState('');
    const [List,setList] = useState([]);
    const [selectedEmployee,setSelectedEmployee] = useState(null); 
    const [showEdit,setShowEdit] = useState(false); 
    useEffect(()=>{
      Functions.Get(setList);
    },[])
    const reset = ()=>{
      setName('');
      setDesignation('')
      setImage('')
      setSalary(0)
      setCnic('')
      setAddress('')
      setContact('')
      setOtherInformation('')
    }
  return (
    <div className='labadmin_employee_container'>
        <Title Value={'Employees'}/>
      <div className='labadmin_employee_formbox'>
        <div className='labadmin_employee_row'>
          <div className='labadmin_employee_col50'>
            <Input Lable={'Name'} Type={'text'} Value={name} setValue={setName}/>
            <Input Lable={'Designation'} Type={'text'} Value={designation} setValue={setDesignation}/>
            <Input Lable={'Contact'} Type={'text'} Value={contact} setValue={setContact}/>
            <TextArea Lable={'Address'} Placeholder={'Enter address'} Value={address} setValue={setAddress}/>
          </div>
          <div className='labadmin_employee_col50'>
            <Input Lable={'Salary'} Type={'number'} Value={salary} setValue={setSalary}/>
            <Input Lable={'Cnic'} Type={'text'} Value={cnic} setValue={setCnic}/>
            <TextArea Lable={'Other information'} Placeholder={'Enter other information'} Value={otherinformation} setValue={setOtherInformation}/>
          </div>
        </div>
        <Button Value={'Save'} Action={()=>{
          const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
          if(name === ''){
            toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            return;
          }
          Functions.Post({
            name: name, 
            designation : designation,
            image : image,
            salary : salary,
            contact : contact,
            cnic : cnic,
            address : address,
            otherinformation : otherinformation,
            status : 'Active'
          },setList,toast,toastid,reset)
        }}/>
      </div>
      <Table List={List} setList={setList} setSelectedEmployee={setSelectedEmployee} setShowEdit={setShowEdit} toast={toast}/>
      {showEdit &&
        <Edit selectedEmployee={selectedEmployee} setSelectedEmployee={setSelectedEmployee} toast={toast} setList={setList} setShowEdit={setShowEdit}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default Index
