import React,{useState,useEffect} from 'react'
import Select from '../../../../components/panelcontrols/panelselect'
import LocationFunctions from '../../locations/functions'

const Index = ({city,setCity}) => {
    const [country,setCountry] = useState({value:0,name:'Select country'});
    const [state,setState] = useState({value:0,name:'Select state'});
    const [countryList,setCountryList] = useState([]);
    const [stateList,setStateList] = useState([]);
    const [cityList,setCityList] = useState([]);
    useEffect(()=>{
      LocationFunctions.GetCountry((data)=>{
        let senddata = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          senddata.push({
            value: parseInt(element.id),
            name: element.name
          });
        }
        setCountryList(senddata)
      })
    },[])
    useEffect(()=>{
      if(country.value !== 0){
        LocationFunctions.GetState(country.value,(data)=>{
          let senddata = [];
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            senddata.push({
              value: parseInt(element.id),
              name: element.name
            });
          }
          setStateList(senddata)
        })
      }
      else{
        setStateList([]);
      }
      setState({value:0,name:'Select state'});
    },[country])
    useEffect(()=>{
      if(state.value !== 0){
        LocationFunctions.GetCitybyState(state.value,(data)=>{
          let senddata = [];
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            senddata.push({
              value: parseInt(element.id),
              name: element.name
            });
          }
          setCityList(senddata)
        })
      }
      else{
        setCityList([]);
      }
      setCity({value:0,name:'Select city'})
    },[state])
  return (
    <div className='admin_labs_row'>
      <div className='admin_labs_col33'>
        <Select Lable={'Country'} Value={country} setValue={setCountry} Options={countryList}/>
      </div>
      <div className='admin_labs_col33'>
        <Select Lable={'State'} Value={state} setValue={setState} Options={stateList}/>
      </div>
      <div className='admin_labs_col33'>
        <Select Lable={'City'} Value={city} setValue={setCity} Options={cityList}/>
      </div>
    </div>
  )
}

export default Index
