import React,{useState,useEffect} from 'react'
import Input from '../../../../components/panelcontrols/panelinput'
import Select from '../../../../components/panelcontrols/panelselect'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Functions from '../functions'
import ClinicFunctions from '../clinics/function'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Index = ({selectedDoctor,setSelectedDoctor,toast,setList,setShowEdit}) => {
    const [firstname,setFirstname] = useState('');
    const [lastname,setLastname] = useState('');
    const [email,setEmail] = useState('');
    const [contact,setContact] = useState('');
    const [clinic,setClinic] = useState({value:0,name:'Select clinic'});
    const [cliniclist,setClinicList] = useState([]);
    useEffect(()=>{
        if(selectedDoctor){
            setFirstname(selectedDoctor.firstname);
            setLastname(selectedDoctor.lastname);
            setEmail(selectedDoctor.email);
            setContact(selectedDoctor.contact);
            ClinicFunctions.Get((data)=>{
                const sendData = [];
                for (let index = 0; index < data.length; index++) {
                  const element = data[index];
                  sendData.push({
                    value : parseInt(element.id),
                    name: element.name
                  })
                  if(element.id === selectedDoctor.clinic){
                    setClinic({
                        value : parseInt(element.id),
                        name: element.name
                    })
                  }
                }
                setClinicList(sendData)
            });
        }
        else{
            setFirstname('');
            setLastname('');
            setEmail('');
            setContact('');
            setClinic({value:0,name:'Select clinic'});
        }
    },[selectedDoctor])
    const reset = ()=>{
        setSelectedDoctor(null)
        setShowEdit(false)
    }
  return (
    <div className='labadmin_product_edit_wrapper'>
        <div className='labadmin_product_edit_container'>
            <div className='labadmin_product_edit_close'>
                <button onClick={()=>{
                   reset(); 
                }}>
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            <div className='labadmin_product_formbox'>
                <h3>Edit Doctor</h3>
                <div className='labadmin_doctor_row'>
                    <div className='labadmin_doctor_col50'>
                        <Input Lable={'First name'} Type={'text'} Value={firstname} setValue={setFirstname}/>
                        <Input Lable={'Last name'} Type={'text'} Value={lastname} setValue={setLastname}/>
                        <Input Lable={'Email'} Type={'text'} Value={email} setValue={setEmail}/>
                    </div>
                    <div className='labadmin_doctor_col50'>
                        <Input Lable={'Contact'} Type={'text'} Value={contact} setValue={setContact}/>
                        <Select Lable={'Clinic'} Value={clinic} setValue={setClinic} Options={cliniclist}/>
                        <Button Value={'Save'} Action={()=>{
                            const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                            if(firstname === '' || lastname === '' || email === '' || clinic.value === 0){
                              toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                              return;
                            }
                            Functions.Put(selectedDoctor.id,{
                                firstname: firstname, 
                                lastname : lastname,
                                email : email,
                                contact : contact,
                                clinic : clinic.value
                            },setList,toast,toastid,reset)
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Index
