import React from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Index = ({setShowEdit,width,Container}) => {
  return (
    <div className='panel_popup_wrapper'>
      <div className='panel_popup_container' style={{width:width}}>
        <div className='panel_popup_close'>
            <button onClick={()=>{
                setShowEdit(false);
            }}>
                <FontAwesomeIcon icon={faXmark}/>
            </button>
        </div>
        {
          Container
        }
      </div>
    </div>
  )
}

export default Index
