import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './index.css'

const Index = ({lineitems,setLineitems,setPage}) => {
  return (
    <div className='labadmin_orders_new_lineitems_container'>
      <div className='labadmin_orders_new_lineitems_add'>
        <p>Product & Services</p>
        <button onClick={()=>{
          setPage(1);
        }}>
          <FontAwesomeIcon icon={faPlus}/>
        </button>
      </div>
      {lineitems.length > 0 &&
      <div className='labadmin_orders_new_lineitems_list'>
        <div className='_table'>
          <div className='_tbody'>
              {
                lineitems.map((item,index)=>{
                  if(item.productname){
                    return(
                      <div className='_tr' key={index}>
                        <div className='_td' style={{width:"50%"}}>{item.productname + ' - '+ item.servicename}</div>
                        <div className='_td' style={{width:"15%"}}>{item.units}</div>
                        <div className='_td' style={{width:"25%"}}>Rs.{item.totalprice}</div>
                        <div className='_td' style={{width:"10%"}}><FontAwesomeIcon icon={faTrashCan} onClick={()=>{
                          const sendData = [];
                          for (let index2 = 0; index2 < lineitems.length; index2++) {
                            const element = lineitems[index2];
                            if(index !== index2){
                              sendData.push(element);
                            }
                          }
                          setLineitems(sendData)
                        }}/></div>
                      </div>
                    )
                  }
                  if(item.product){
                    return(
                      <div className='_tr' key={index}>
                        <div className='_td' style={{width:"50%"}}>{item.product.name + ' - '+ item.service.name}</div>
                        <div className='_td' style={{width:"15%"}}>{item.units}</div>
                        <div className='_td' style={{width:"25%"}}>Rs.{item.totalprice}</div>
                        <div className='_td' style={{width:"10%"}}><FontAwesomeIcon icon={faTrashCan} onClick={()=>{
                          const sendData = [];
                          for (let index2 = 0; index2 < lineitems.length; index2++) {
                            const element = lineitems[index2];
                            if(index !== index2){
                              sendData.push(element);
                            }
                          }
                          setLineitems(sendData)
                        }}/></div>
                      </div>
                    )
                  }
                })
              }
          </div>
        </div>
      </div>
      }
    </div>
  )
}

export default Index
