import React from 'react'
import ImageA from '../../../images/public/21.jpg'
import ImageB from '../../../images/public/22.jpg'

const CrownsBridges = () => {
  return (
    <div className='public_servic_aligners_wrapper colorsecondarybg' id='cnb'>
      <div className='public_servic_aligners_container'>
        <div className='public_servic_aligners_row'>
            <div className='public_servic_aligners_description'>
                <h3 className='colorprimary'>Crowns & Bridges</h3>
                <p>
                Crowns & Bridges are dental restorations that covers the exposed surface of a tooth to strengthen it or improve its appearance.<br></br>
A bridge is a dental restoration to replace one or more missing teeth. It includes an artificial tooth or teeth which are fused to crowns on either side to provide support.
                </p>
            </div>
            <div className='public_servic_aligners_images'>
                <div className='public_servic_aligners_imageA' style={{backgroundImage:`url(${ImageA})`}}></div>
                <div className='public_servic_aligners_imageB' style={{backgroundImage:`url(${ImageB})`}}></div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default CrownsBridges
