import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Input from '../../../components/panelcontrols/panelinput'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import Select from '../../../components/panelcontrols/panelselect'
import './index.css'
import Table from './doctorTable'
import ClinicFunctions from './clinics/function'
import Functions from './functions'
import { ToastContainer, toast } from 'react-toastify';
import Edit from './edit'

const Index = () => {
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [firstname,setFirstname] = useState('');
    const [lastname,setLastname] = useState('');
    const [email,setEmail] = useState('');
    const [contact,setContact] = useState('');
    const [clinic,setClinic] = useState({value:0,name:'Select clinic'});
    const [cliniclist,setClinicList] = useState([]);
    const [list,setList] = useState([]);
    const [selectedDoctor,setSelectedDoctor] = useState(null); 
    const [showEdit,setShowEdit] = useState(false); 
    useEffect(()=>{
      ClinicFunctions.Get((data)=>{
        const sendData = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          sendData.push({
            value : parseInt(element.id),
            name: element.name
          })
        }
        setClinicList(sendData)
      });
      Functions.Get(setList);
    },[])
    const reset = ()=>{
      setFirstname('');
      setLastname('');
      setUsername('');
      setPassword('');
      setContact('');
      setEmail('');
      setClinic({value:0,name:'Select clinic'});
    }
  return (
    <div className='labadmin_doctor_container'>
      <Title Value={'Doctors'}/>
      <div className='labadmin_doctor_formbox'>
        <div className='labadmin_doctor_row'>
            <div className='labadmin_doctor_col50'>
                <Input Lable={'First name'} Type={'text'} Value={firstname} setValue={setFirstname}/>
                <Input Lable={'Last name'} Type={'text'} Value={lastname} setValue={setLastname}/>
                <Input Lable={'Username'} Type={'text'} Value={username} setValue={setUsername}/>
                <Input Lable={'Password'} Type={'text'} Value={password} setValue={setPassword}/>
            </div>
            <div className='labadmin_doctor_col50'>
                <Input Lable={'Email'} Type={'text'} Value={email} setValue={setEmail}/>
                <Input Lable={'Contact'} Type={'text'} Value={contact} setValue={setContact}/>
                <Select Lable={'Clinic'} Value={clinic} setValue={setClinic} Options={cliniclist}/>
            </div>
        </div>
        <Button Value={'Save'} Action={()=>{
          const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
          if(firstname === '' || lastname === '' || username === '' || password === '' || email === '' || clinic.value === 0){
            toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            return;
          }
          Functions.Post({
            firstname: firstname, 
            lastname : lastname,
            username : username,
            password : password,
            email : email,
            contact : contact,
            clinic : clinic.value
          },setList,toast,toastid,reset)
        }}/>
      </div>
      <Table List={list} setList={setList} setSelectedDoctor={setSelectedDoctor} setShowEdit={setShowEdit} toast={toast}/>
      {showEdit &&
        <Edit selectedDoctor={selectedDoctor} setSelectedDoctor={setSelectedDoctor} toast={toast} setList={setList} setShowEdit={setShowEdit}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default Index
