import React, { useState,useEffect } from 'react'
import Title from '../../../../../components/panelcontrols/paneltitle'
import Button from '../../../../../components/panelcontrols/panelbuttonmain'
import Select from '../../../../../components/panelcontrols/panelselect'
import DepartmentFunctions from '../../departments/functions'
import Functions from '../functions'

const Index = ({selectedOrder,selectedDepartment,toast,setList,setShowEdit}) => {
  const [department,setDepartment] = useState({value:0,name:'Select department'});
  const [departmentlist,setDepartmentList] = useState([]);
  useEffect(()=>{
    DepartmentFunctions.Get((data)=>{
      const sendData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        sendData.push({
          value : parseInt(element.id),
          name : element.name
        })
        if(parseInt(element.id) === parseInt(selectedDepartment)){
          setDepartment({value:parseInt(element.id),name:element.name});
        }
      }
      setDepartmentList(sendData)
    })
  },[selectedDepartment])
  return (
    <div className='labadmin_orders_status_selector_container'>
      <Title Value={'Edit Department for Order # '+selectedOrder}/>
      <Select Lable={'Department'} Value={department} setValue={setDepartment} Options={departmentlist}/>
      <Button Value={'Save'} Action={()=>{
        const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
        if(selectedOrder === 0 || department.value === 0){
          toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
          return;
        }
        Functions.UpdateDepartment(selectedOrder,{department:department.value},setList,toast,toastid,()=>{
          setShowEdit(false);
        })
      }} />
    </div>
  )
}

export default Index
