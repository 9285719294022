import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import { faFacebook, faInstagram, faLinkedin, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Index = () => {
  return (
    <div className='public_about_hero_wrapper colorprimarybg'>
      <div className='public_about_hero_container'>
        <div className='public_about_hero_row'>
            <div className='public_about_hero_text'>
                <h3 className='colorwhite'>About N-Vision Digital Dental Laboratory</h3>
                <p className='colorwhite'>At N-Vision Digital Dental Laboratory, we redefine smiles with precision and innovation. Our unwavering commitment to crafting dental perfection has made us a trusted leader in the field of digital dentistry. With a blend of cutting-edge technology, artistic expertise, and a passion for patient satisfaction, we create dental restorations that not only restore function but also enhance the beauty of every smile.</p>
            </div>
            <div className='public_about_hero_clicks'>
              <div className='public_about_hero_clicks_ceo'>
                <h3><b>Adil Akram</b> <br></br>(Founder & CEO)</h3>
              </div>
              <div className='public_about_hero_clicks_login'>
                <Link to={'/contant'}><button>Become a member</button></Link>
                <p>Already have an account <Link to={'/login'}>Log in</Link></p>
              </div>
              <div className='public_about_hero_clicks_icons'>
                <div className='public_about_hero_clicks_icon'>
                  <Link to={'https://www.facebook.com/profile.php?id=100093019306708'}>
                    <FontAwesomeIcon icon={faFacebook}/>
                  </Link>
                </div>
                <div className='public_about_hero_clicks_icon'>
                  <Link to={'https://twitter.com/Nvisiondental'}>
                    <FontAwesomeIcon icon={faXTwitter}/>
                  </Link>
                </div>
                <div className='public_about_hero_clicks_icon'>
                  <Link to={'https://pk.linkedin.com/in/n-vision-dental-3b206b279'}>
                    <FontAwesomeIcon icon={faLinkedin}/>
                  </Link>
                </div>
                <div className='public_about_hero_clicks_icon'>
                  <Link to={'https://www.instagram.com/nvisiondental/'}>
                    <FontAwesomeIcon icon={faInstagram}/>
                  </Link>
                </div>
                <div className='public_about_hero_clicks_icon'>
                  <Link to={'https://www.youtube.com/channel/UCqYMbk_6ktRqoDcNR1xxYtw'}>
                    <FontAwesomeIcon icon={faYoutube}/>
                  </Link>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Index
