import React from 'react'
import '../../../css/public/pages/home.css'
import Hero from './hero'
import Samples from './samples'
import Livetracking from './livetracking'
import Services from './services'
import Facts from './facts'
import Testimonials from './testimonials'
import WhatweSupport from './whatwesupport'
import Labs from './labs'

const Index = () => {
  return (
    <div>
    <Hero/>
    <Livetracking/>
    <Samples/>
    <Services/>
    <Facts/>
    <Testimonials />
    <WhatweSupport/>
    <Labs/>
    </div>
  )
}

export default Index
