import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './index.css'

const Index = ({files,setFiles,setPage}) => {
  return (
    <div className='labadmin_orders_new_lineitems_container'>
      <div className='labadmin_orders_new_lineitems_add'>
        <p>Files</p>
        <button onClick={()=>{
          //setPage(2);
        }}>
          <FontAwesomeIcon icon={faPlus}/>
        </button>
      </div>
    </div>
  )
}

export default Index
