import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'

const Index = () => {
  return (
    <div className='panel_watermark_wrapper'>
        <Link to={'https://www.nvisiondental.com'}>
            <div className='panel_watermark_contaier'>
                <p>powered by</p>
                <h5>N-Vision Dental</h5>
            </div>
        </Link>
    </div>
  )
}

export default Index
