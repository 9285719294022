import React,{useState,useEffect} from 'react'
import './index.css'
import Input from '../../../../components/panelcontrols/panelinput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Index = ({Value,setValue,Options}) => {
    const [filterList,setfilterList] = useState(Options);
    const [search,setSearch] = useState("");
    function filterFunction(value){
      if(value === ""){
        setfilterList(Options);
      }
      else{
        const filtedList = Options.filter((obj)=>{
          return Object.values(obj)
          .join(" ").toLowerCase()
          .includes(value.toLowerCase());
        })
        setfilterList(filtedList);
      }
    }
    useEffect(()=>{
        filterFunction(search);
    },[Options,search])
  return (
    <div className='labadmin_messages_userselector_container'>
      <div className='labadmin_messages_userselector_search'>
        <Input Lable={''} Type={'text'} Value={search} setValue={setSearch}/>
      </div>
      <div className='labadmin_messages_userselector_users'>
        {
            filterList.map((item,index)=>{
                return(
                    <div 
                        key={index} 
                        className={item.value === Value.value? 'labadmin_messages_userselector_user_container active':'labadmin_messages_userselector_user_container'}
                        onClick={()=>{setValue(item)}}
                    >
                        <FontAwesomeIcon icon={faUser}/>
                        <p>{item.name}</p>
                    </div>
                )
            })
        }
      </div>
    </div>
  )
}

export default Index
