import React,{useState,useEffect} from 'react'
import Input from '../../../../../../components/panelcontrols/panelinput'
import Select from '../../../../../../components/panelcontrols/panelselect'
import TextArea from '../../../../../../components/panelcontrols/paneltextarea'
import Button from '../../../../../../components/panelcontrols/panelbuttonmain'
import Button2 from '../../../../../../components/panelcontrols/panelbuttonsecond'
import './index.css'
import UnitSelector from './unitSelector'
import ProductFunctions from '../../../../products/functions'

const Index = ({lineitems,setLineitems,setPage}) => {
    const [product,setProduct] = useState({value:0,name:'Select product'});
    const [productlist,setProductList] = useState([]);
    const [service,setService] = useState({value:0,name:'Select service'});
    const [servicelist,setServiceList] = useState([]);
    const [shades,setShades] = useState('');
    const [occlusal,setOcclusal] = useState({value:0,name:'Select occlusal stains'});
    const [occlusallist,setOcclusalList] = useState([
        {value:'NA',name:'NA'},
        {value:'Light',name:'Light'},
        {value:'Medium',name:'Medium'},
        {value:'Heavy',name:'Heavy'}
    ]);
    const [translucency,setTranslucency] = useState({value:'NA',name:'NA'});
    const [translucencylist,setTranslucencyList] = useState([
        {value:'NA',name:'NA'},
        {value:'Light',name:'Light'},
        {value:'Medium',name:'Medium'},
        {value:'Heavy',name:'Heavy'}
    ]);
    const [ponticdesign,setPonticDesign] = useState({value:'NA',name:'NA'});
    const [ponticdesignlist,setPonticdesignList] = useState([
        {value:'NA',name:'NA'},
        {value:'Partial Bridge',name:'Partial Bridge'},
        {value:'Buccal Crest',name:'Buccal Crest'},
        {value:'Point Contact',name:'Point Contact'},
        {value:'No Contact',name:'No Contact'}
    ]);
    const [duedate,setDueDate] = useState('');
    const [appointment,setAppointment] = useState('');
    const [enclosed,setEnclosed] = useState({value:'Other',name:'Other'});
    const [enclosedlist,setEnclosedList] = useState([
        {value:'Impression',name:'Impression'},
        {value:'Model',name:'Model'},
        {value:'Bite',name:'Bite'},
        {value:'Digital',name:'Digital'},
        {value:'Other',name:'Other'}
    ]);
    const [units,setUnits] = useState(0);
    const [totalprice,setTotalPrice] = useState(0);
    const [description,setDescription] = useState('');
    const [unitList,setUnitList] = useState([]);
    useEffect(()=>{
        ProductFunctions.GetProduct((data)=>{
            const sendData = [];
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                sendData.push({
                    value : parseInt(element.id),
                    name: element.name
                });
            }
            setProductList(sendData)
        })
    },[])
    useEffect(()=>{
        if(product.value === 0){
            return;
        }
        ProductFunctions.GetProductServiceByProduct(product.value,(data)=>{
            const sendData = [];
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                sendData.push({
                    value : parseInt(element.id),
                    name: element.name
                });
            }
            setServiceList(sendData)
        })
    },[product])
    useEffect(()=>{
        setTotalPrice(parseInt(unitList.length * units));
    },[unitList,units])
  return (
    <div className='labadmin_orders_new_lineitems_add_container'>
      <div className='labadmin_orders_new_lineitems_add_formbox'>
        <div className='labadmin_orders_new_lineitems_add_row'>
            <div className='labadmin_orders_new_lineitems_add_col50'>
              <Select Lable={'Product category'} Value={product} setValue={setProduct} Options={productlist}/>
            </div>
            <div className='labadmin_orders_new_lineitems_add_col50'>
              <Select Lable={'Service'} Value={service} setValue={setService} Options={servicelist}/>
            </div>
        </div>
        <div className='labadmin_orders_new_lineitems_add_row'>
            <div className='labadmin_orders_new_lineitems_add_col33'>
                <Input Lable={'Shades'} Type={'text'} Value={shades} setValue={setShades}/>
            </div>
            <div className='labadmin_orders_new_lineitems_add_col33'>
                <Select Lable={'Enclosed'} Value={enclosed} setValue={setEnclosed} Options={enclosedlist}/>
            </div>
            <div className='labadmin_orders_new_lineitems_add_col33'>
                <Select Lable={'Translucency'} Value={translucency} setValue={setTranslucency} Options={translucencylist}/>
            </div>
        </div>
        <div className='labadmin_orders_new_lineitems_add_row'>
            <div className='labadmin_orders_new_lineitems_add_col35'>
                <UnitSelector List={unitList} setList={setUnitList}/>
            </div>
            <div className='labadmin_orders_new_lineitems_add_col65'>
                <div className='labadmin_orders_new_lineitems_add_row'>
                    <div className='labadmin_orders_new_lineitems_add_col50'>
                        <Select Lable={'Occlusal stains'} Value={occlusal} setValue={setOcclusal} Options={occlusallist}/>
                        <Input Lable={'Due Date'} Type={'date'} Value={duedate} setValue={setDueDate}/>
                    </div>
                    <div className='labadmin_orders_new_lineitems_add_col50'>
                        <Select Lable={'Pontic Design'} Value={ponticdesign} setValue={setPonticDesign} Options={ponticdesignlist}/>
                        <Input Lable={'Appointment'} Type={'date'} Value={appointment} setValue={setAppointment}/>
                    </div>
                </div>
                <div className='labadmin_orders_new_lineitems_add_row'>
                    <div className='labadmin_orders_new_lineitems_add_col50'>
                        <Input Lable={'Unit Price'} Type={'number'} Value={units} setValue={setUnits}/>
                    </div>
                    <div className='labadmin_orders_new_lineitems_add_col50'>
                        <Input Lable={'Total price'} Type={'number'} Value={totalprice} setValue={setTotalPrice}/>
                    </div>
                </div>
                <TextArea Lable={'Description'} Type={'text'} Value={description} setValue={setDescription}/>
            </div>
        </div>
        <div className='labadmin_orders_new_lineitems_add_row'>
            <div className='labadmin_orders_new_lineitems_add_col50'>
                <Button2 Value={'Back'} Action={()=>{setPage(0)}}/>
            </div>
            <div className='labadmin_orders_new_lineitems_add_col50'>
                <Button Value={'Add'} Action={()=>{
                    if(
                        product.value === 0 || 
                        service.value === 0 || 
                        shades === '' ||
                        enclosed.value === 0 || 
                        translucency.value === 0 || 
                        occlusal.value === 0 || 
                        ponticdesign.value === 0 || 
                        duedate === '' ||
                        appointment === '' ||
                        units === 0,
                        totalprice === 0
                    ){
                        alert('fields empty');
                        return;
                    }
                    const sendData = [];
                    for (let index = 0; index < lineitems.length; index++) {
                        const element = lineitems[index];
                        sendData.push(element);
                    }
                    sendData.push({
                        product : product,
                        service: service,
                        shades: shades,
                        enclosed : enclosed,
                        translucency : translucency,
                        occlusal : occlusal,
                        ponticdesign : ponticdesign,
                        duedate : duedate,
                        appointment : appointment,
                        units : units,
                        unitList : unitList,
                        totalprice : parseInt(totalprice),
                        description : description
                    })
                    setLineitems(sendData);
                    setPage(0);
                }}/>
            </div>
        </div>
        
      </div>
    </div>
  )
}

export default Index
