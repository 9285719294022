import React,{useState,useEffect} from 'react'

const Index = ({List,setList,id,image,imagea}) => {
    const [isActive,setIsActive] = useState(false);
    useEffect(()=>{
        var found = false;
        for (let index = 0; index < List.length; index++) {
            const element = List[index];
            if(element.id === id){
                found = true
            }
        }
        setIsActive(found);
    },[List])
  return (
    <button 
        className={`labadmin_orders_new_lineitems_add_units_upper_item unit_item_${id}`}
        onClick={()=>{
            var found = false;
            const sendData = [];
            for (let index = 0; index < List.length; index++) {
                const element = List[index];
                if(element.id === id){
                    found = true
                }
                else{
                    sendData.push(element);
                }
            }
            if(!found){
                sendData.push({
                    id: id
                })
            }
            setList(sendData)
        }}
    >
        {isActive === false &&
            <img src={image} alt="tooth" />
        }
        {isActive === true &&
            <img src={imagea} alt="tooth" />
        }
    </button>
  )
}

export default Index
