import React from 'react'
import LiveTrackingImage from '../../../images/public/02.png'

const Livetracking = () => {
  return (
    <div className='public_home_live_tacking_wrapper'>
      <div className='public_home_live_tacking_container'>
        <div className='public_home_live_tacking_row'>
            <div className='public_home_live_tacking_image'>
                <img src={LiveTrackingImage} alt='Live Tacking'/>
            </div>
            <div className='public_home_live_tacking_text'>
                <h3>Be your boss and track your orders live.</h3>
                <h4>Request your portal today.</h4>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Livetracking
