import React,{useState,useEffect} from 'react'
import Input from '../../../../components/panelcontrols/panelinput'
import TextArea from '../../../../components/panelcontrols/paneltextarea'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Select from '../../../../components/panelcontrols/panelselect'
import Functions from '../functions'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Index = ({serviceproductlist,selectedService,setSelectedService,toast,setList,setShowServiceEdit}) => {
    const [servicename,setServiceName] = useState('');
    const [serviceproduct,setServiceProduct] = useState({value:0,name:'Select product'});
    const [serviceremarks,setServiceRemarks] = useState('');
    useEffect(()=>{
        if(selectedService){
            setServiceName(selectedService.name);
            for (let index = 0; index < serviceproductlist.length; index++) {
                const element = serviceproductlist[index];
                if(element.value === selectedService.product){
                    setServiceProduct(element);
                }
            }
            setServiceRemarks(selectedService.remarks);
        }
        else{
            setServiceName('');
            setServiceProduct({value:0,name:'Select product'});
            setServiceRemarks('');
        }
    },[selectedService])
    const resetServcie = ()=>{
        setSelectedService(null)
        setShowServiceEdit(false)
    }
  return (
    <div className='labadmin_product_edit_wrapper'>
        <div className='labadmin_product_edit_container'>
            <div className='labadmin_product_edit_close'>
                <button onClick={()=>{
                   resetServcie(); 
                }}>
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            <div className='labadmin_product_formbox'>
                <h3>Edit service</h3>
                <Input Lable={'Name'} Type={'text'} Value={servicename} setValue={setServiceName}/>
                <Select Lable={'Product category'} Value={serviceproduct} setValue={setServiceProduct} Options={serviceproductlist}/>
                <TextArea Lable={'Remarks'} Type={'text'} Value={serviceremarks} setValue={setServiceRemarks}/>
                <Button Value={'Save'} Action={()=>{
                    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                    if(servicename === '' || serviceproduct.value === 0){
                    toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                    return;
                    }
                    Functions.PutProductService(selectedService.id,{
                        name: servicename,
                        product: serviceproduct.value,
                        remarks: serviceremarks
                    },setList,toast,toastid,resetServcie)
                }}/>
            </div>
        </div>
    </div>
  )
}

export default Index
