import React, { useEffect, useState } from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import OrderTable from './orderTable'
import OrderStatusSelector  from './orderStatusSelector'
import OrderFunctions from '../orders/list/functions'
import './index.css'

const Index = () => {
  const [selectedStatus,setSelectedStatus] = useState(0);
  const [orderList,setOrderList] = useState([]);
  useEffect(()=>{
    if(selectedStatus === 0){
      OrderFunctions.Get(setOrderList)
    }
    else{
      OrderFunctions.GetByStatus(selectedStatus,setOrderList)
    }
  },[selectedStatus])
  return (
    <div className='labadmin_dashboard_container'>
      <Title Value={'Dashboard'}/>
      <OrderStatusSelector selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus}/>
      <OrderTable List={orderList}/>
    </div>
  )
}

export default Index
