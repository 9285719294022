import React from 'react'

const Topbar = () => {
  return (
    <div className='public_topbar_container'>
      <p className='colorprimary'>We provide digital solutions</p>
    </div>
  )
}

export default Topbar
