import React from 'react'
import Hero from './hero'
import Form from './form'
import '../../../css/public/pages/contact.css'
import Email from './email'

const Index = () => {
  return (
    <div>
      <Hero/>
      <Form/>
      <Email/>
    </div>
  )
}

export default Index
