import React from 'react'
import './index.css'

const Index = () => {
  return (
    <div className='public_about_personalized_wrapper'>
      <div className='public_about_personalized_wrappera'>
        <div className='public_about_personalized_background'>
          <div className='public_about_personalized_container'>
              <p>N-Vision Digital Dental Laboratory is more than a laboratory; we are your partners in achieving dental excellence. Our commitment extends beyond producing dental restorations – we aim to elevate the standards of modern dentistry by providing continuous education and support to dental professionals. Together, we can shape the future of oral healthcare, one radiant smile at a time.</p>
          </div>
        </div>
        <div className='public_about_personalized_title'>
            <h3>Partners in Dental Excellence</h3>
        </div>

      </div>
      
    </div>
  )
}

export default Index
