import React,{useState,useEffect} from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import Input from '../../../../components/panelcontrols/panelinput'
import TextArea from '../../../../components/panelcontrols/paneltextarea'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Button2 from '../../../../components/panelcontrols/panelbuttonsecond'
import Select from '../../../../components/panelcontrols/panelselect'
import './index.css'
import Lineitems from './lineitems';
import Files from './files';
import Notes from './notes';
import Tasks from './tasks';
import LineItemsAdd from './lineitems/add'
import DepartmentFunctions from '../departments/functions'
import StatusFunctions from '../status/functions'
import Functions from './functions'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const [patient,setPatient] = useState('');
  const [date,setDate] = useState('');
  const [workingpan,setWorkingPan] = useState('0');
  const [status,setStatus] = useState({value:0,name:'Select status'});
  const [statuslist,setStatusList] = useState([]);
  const [lineitems,setLineitems] = useState([]);
  const [notes,setNotes] = useState([]);
  const [files,setFiles] = useState([]);
  const [tasks,setTasks] = useState([]);
  const [page,setPage] = useState(0);
  const [subtotal,setSubTotal] = useState(0);
  const [discount,setDiscount] = useState(0);
  const [nettotal,setNetTotal] = useState(0);
  useEffect(()=>{
    /*DepartmentFunctions.Get((data)=>{
      const sendData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        sendData.push({
          value : parseInt(element.id),
          name : element.name
        })
      }
      setDepartmentList(sendData)
    })*/
    StatusFunctions.Get((data)=>{
      const sendData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        sendData.push({
          value : parseInt(element.id),
          name : element.name
        })
      }
      setStatusList(sendData)
    })
  },[])
  useEffect(()=>{
    var subtotalsend = 0;
    for (let index = 0; index < lineitems.length; index++) {
      const element = lineitems[index];
      subtotalsend = subtotalsend + parseInt(element.totalprice);
    }
    setSubTotal(subtotalsend);
    setNetTotal(subtotalsend - parseInt(discount));
  },[lineitems,discount])
  const reset = ()=>{
    setPatient('');
    setDate('');
    setWorkingPan('0');
    setStatus({value:0,name:'Select status'});
    //setDepartment({value:0,name:'Select department'});
    setLineitems([]);
    setFiles([]);
    setNotes([]);
    setTasks([]);
    setSubTotal(0);
    setDiscount(0);
  }
  return (
    <div className='labadmin_orders_new_container'>
      <Title Value={'New order'}/>
      {page === 0 &&
        <div className='labadmin_orders_new_formbox'>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
              <Input Lable={'Patient'} Type={'text'} Value={patient} setValue={setPatient}/>
            </div>
            <div className='labadmin_orders_new_col50'>
              <Input Lable={'Date'} Type={'date'} Value={date} setValue={setDate}/>
            </div>
          </div>
              {/*
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
                <Select Lable={'Status'} Value={status} setValue={setStatus} Options={statuslist}/>
            </div>
            <div className='labadmin_orders_new_col50'>
              <Select Lable={'Department'} Value={department} setValue={setDepartment} Options={departmentlist}/>
            </div>
          </div>
              */}
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
              <Lineitems lineitems={lineitems} setLineitems={setLineitems} setPage={setPage}/>
            </div>
            <div className='labadmin_orders_new_col50'>
              <Files lineitems={files} setLineitems={setFiles} setPage={setPage}/>
            </div>
          </div>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
              <Notes notes={notes} setNotes={setNotes} setPage={setPage}/>
            </div>
            <div className='labadmin_orders_new_col50'>
              <Tasks tasks={tasks} setTasks={setTasks} setPage={setPage}/>
            </div>
          </div>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col33'>
              <Input Lable={'Sub Total'} Type={'number'} Value={subtotal} setValue={setSubTotal} readonly={true}/>
            </div>
            <div className='labadmin_orders_new_col33'>
              <Input Lable={'Discount'} Type={'number'} Value={discount} setValue={setDiscount}/>
            </div>
            <div className='labadmin_orders_new_col33'>
              <Input Lable={'Net Total'} Type={'number'} Value={nettotal} setValue={setNetTotal} readonly={true}/>
            </div>
          </div>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
              <Button Value={'Save'} Action={()=>{
                const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                if(patient === '' || date === '' || workingpan === '' || lineitems.length === 0 || nettotal === 0 ){
                  toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                  return;
                }
                const lineitemsSend = [];
                for (let index = 0; index < lineitems.length; index++) {
                  const element = lineitems[index];
                  const unitListSend = [];
                  for (let jndex = 0; jndex < element.unitList.length; jndex++) {
                    const uitem = element.unitList[jndex];
                    unitListSend.push(uitem.id);
                  }
                  lineitemsSend.push({
                    service: element.service.value,
                    shades: element.shades,
                    enclosed: element.enclosed.value,
                    translucency: element.translucency.value,
                    occlusal:element.occlusal.value,
                    ponticdesign:element.ponticdesign.value,
                    duedate: element.duedate,
                    appointment: element.appointment,
                    units: element.units,
                    totalprice: element.totalprice,
                    description: element.description,
                    unitlist: unitListSend
                  })
                }
                Functions.Post({
                  patient: patient,
                  date: date,
                  workingpan: workingpan,
                  status: status.value,
                  department: 0,
                  subtotal : subtotal,
                  discount: discount,
                  nettotal: nettotal,
                  notes: notes,
                  tasks: tasks,
                  lineitems: lineitemsSend
                },toast,toastid,()=>{
                  reset();
                  setTimeout(() => {
                    document.location.replace('/doctor/orders');
                  }, 500);
                })
              }}/>
            </div>
            <div className='labadmin_orders_new_col50'>
              <Button2 Value={'Back'} Action={()=>{
                document.location.replace('/doctor/orders');
              }}/>
            </div>
          </div>

        </div>
      }
      {page === 1 &&
        <LineItemsAdd lineitems={lineitems} setLineitems={setLineitems} setPage={setPage}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default Index
