import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Input from '../../../components/panelcontrols/panelinput'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import './index.css'

const Index = () => {
    const [salebydateDate,setSaleByDateDate] = useState('');
    const [salebyrangeFrom,setSaleByRangeFrom] = useState('');
    const [salebyrangeTo,setSaleByRangeTo] = useState('');
    const [expensebydateDate,setExpenseByDateDate] = useState('');
    const [expensebyrangeFrom,setExpenseByRangeFrom] = useState('');
    const [expensebyrangeTo,setExpenseByRangeTo] = useState('');
    const [pnlbydateDate,setPnlByDateDate] = useState('');
    const [pnlbyrangeFrom,setPnlByRangeFrom] = useState('');
    const [pnlbyrangeTo,setPnlByRangeTo] = useState('');
  return (
    <div className='labadmin_reports_container'>
        <Title Value={'Reports'}/>
      <div className='labadmin_reports_row'>
        <div className='labadmin_reports_col33'>
            {/*
                <div className='labadmin_reports_formbox'>
                    <h3>Sales by date</h3>
                    <Input Lable={'Date'} Type={'date'} Value={salebydateDate} setValue={setSaleByDateDate}/>
                    <Button Value={'Print'} Action={()=>{
                        if(salebydateDate === ''){
                            alert('fields empty');
                            return;
                        }
                        window.open(`/labadminreport/salesbydate?date=${salebydateDate}`);
                    }}/>
                </div>
            */}
            <div className='labadmin_reports_formbox'>
                <h3>Daily Sales</h3>
                <Input Lable={'Date'} Type={'date'} Value={salebydateDate} setValue={setSaleByDateDate}/>
                <Button Value={'Print'} Action={()=>{
                    if(salebydateDate === ''){
                        alert('fields empty');
                        return;
                    }
                    window.open(`/labadminreport/dailysalesreport?date=${salebydateDate}`);
                }}/>
            </div>
        </div>
        <div className='labadmin_reports_col33'>
            <div className='labadmin_reports_formbox'>
                <h3>Sales by range</h3>
                <Input Lable={'From'} Type={'date'} Value={salebyrangeFrom} setValue={setSaleByRangeFrom}/>
                <Input Lable={'To'} Type={'date'} Value={salebyrangeTo} setValue={setSaleByRangeTo}/>
                <Button Value={'Print'} Action={()=>{
                    if(salebyrangeFrom === '' || salebyrangeTo === ''){
                        alert('fields empty');
                        return;
                    }
                    window.open(`/labadminreport/rangesalesreport?fdate=${salebyrangeFrom}&tdate=${salebyrangeTo}`);
                    //window.open(`/labadminreport/salesbyrange?from=${salebyrangeFrom}&to=${salebyrangeTo}`);
                }}/>
            </div>
        </div>
        <div className='labadmin_reports_col33'>
            <div className='labadmin_reports_formbox'>
                <h3>Expense by date</h3>
                <Input Lable={'Date'} Type={'date'} Value={expensebydateDate} setValue={setExpenseByDateDate}/>
                <Button Value={'Print'} Action={()=>{
                    if(expensebydateDate === ''){
                        alert('fields empty');
                        return;
                    }
                    window.open(`/labadminreport/expensebydate?date=${expensebydateDate}`);
                }}/>
            </div>
        </div>
      </div>
      <div className='labadmin_reports_row'>
        <div className='labadmin_reports_col33'>
            <div className='labadmin_reports_formbox'>
                <h3>Expense by range</h3>
                <Input Lable={'From'} Type={'date'} Value={expensebyrangeFrom} setValue={setExpenseByRangeFrom}/>
                <Input Lable={'To'} Type={'date'} Value={expensebyrangeTo} setValue={setExpenseByRangeTo}/>
                <Button Value={'Print'} Action={()=>{
                    if(expensebyrangeFrom === '' || expensebyrangeTo === ''){
                        alert('fields empty');
                        return;
                    }
                    window.open(`/labadminreport/expensebyrange?from=${expensebyrangeFrom}&to=${expensebyrangeTo}`);
                }}/>
            </div>
        </div>
        <div className='labadmin_reports_col33'>
            <div className='labadmin_reports_formbox'>
                <h3>Profit & Loss by date</h3>
                <Input Lable={'Date'} Type={'date'} Value={pnlbydateDate} setValue={setPnlByDateDate}/>
                <Button Value={'Print'} Action={()=>{
                    if(pnlbydateDate === ''){
                        alert('fields empty');
                        return;
                    }
                    window.open(`/labadminreport/profitandlossdate?date=${pnlbydateDate}`);
                }}/>
            </div>
        </div>
        <div className='labadmin_reports_col33'>
            <div className='labadmin_reports_formbox'>
                <h3>Profit & Loss by range</h3>
                <Input Lable={'From'} Type={'date'} Value={pnlbyrangeFrom} setValue={setPnlByRangeFrom}/>
                <Input Lable={'To'} Type={'date'} Value={pnlbyrangeTo} setValue={setPnlByRangeTo}/>
                <Button Value={'Print'} Action={()=>{
                    if(pnlbyrangeFrom === '' || pnlbyrangeTo === ''){
                        alert('fields empty');
                        return;
                    }
                    window.open(`/labadminreport/profitandlossrange?from=${pnlbyrangeFrom}&to=${pnlbyrangeTo}`);
                }}/>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Index
