import React from 'react'
import Primarybtn from '../../../components/form/primarybtn'

const Email = () => {
  return (
    <div className='public_contact_email_wrapper'>
      <div className='public_contact_email_container colorprimarybg'>
        <div className='public_contact_email_row'>
            <div className='public_contact_email_text'>
                <p className='colorwhite'>
                At our company, customer satisfaction is our top priority. We strive to ensure that your experience with us is seamless and enjoyable. Our knowledgeable support staff is well-equipped to address your inquiries and provide prompt solutions.
                </p>
            </div>
            <div className='public_contact_email_button'>
                <Primarybtn Value={'Send an email'} Action={()=>{
                  window.open("mailto:info@nvisiondental.com")
                }}/>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Email
