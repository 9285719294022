import React from 'react'
import HeroImage from '../../../images/public/01.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'

const Hero = () => {
  const claims = [
    "FREE fast pickup & delivery",
    "Best quality for the lowest prices",
    "Fully integrated online system",
    "We Accept All Digital Scans",
    "Submit & track cases online 24/7"
  ]
  return (
    <div className='public_home_hero_wrapper colorprimarybg'>
      <div className='public_home_hero_container'>
        <div className='public_home_hero_row'>
            <div className='public_home_hero_text'>
                <h3 className='colorwhite'>Services</h3>
                <p className='colorwhite'>Our cutting-edge dental technologies and advanced digital techniques allow us to provide efficient and effective solutions.</p>
                <div className='public_home_hero_text_claims'>
                  <h3>Claim your first free unit!</h3>
                  {
                    claims.map((item,index)=>{
                      return(
                        <div className='public_home_hero_text_claims_row' key={index}>
                          <div className='public_home_hero_text_claims_icon'>
                            <FontAwesomeIcon icon={faCircleCheck}/>
                          </div>
                          <div className='public_home_hero_text_claims_text'>
                            <p>{item}</p>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
            </div>
            <div className='public_home_hero_image'>
              {/*
                <img src={HeroImage} alt='FAQ Hero'/>
               */}
            </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
