import React,{useState,useEffect} from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import Table from './orderTable'
import Functions from './functions'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import StatusEdit from './statusSelector'
import DepartmentEdit from './departmentSelector'
import EditPopup from '../../../../components/panelcontrols/panelpopup'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const [showStatusEdit,setShowStatusEdit] = useState(false);
  const [showDepartmentEdit,setShowDepartmentEdit] = useState(false);
  const [selectedOrder,setSelectedOrder] = useState(0);
  const [selectedStatus,setSelectedStatus] = useState(0);
  const [selectedDepartment,setSelectedDepartment] = useState(0);
  const [list,setList] = useState([]);
  useEffect(()=>{
    Functions.Get(setList);
  },[])
  return (
    <div className='labadmin_order_list_container'>
      <Title Value={'Orders'}/>
      <div style={{display:"flex",justifyContent:"flex-end"}}>
        <div style={{width:"25%"}}>
          <Button Value={'Add New'} Action={()=>{
            document.location.replace('/labadmin/orders/new');
          }}/>
        </div>
      </div>
      <Table List={list} setShowStatusEdit={setShowStatusEdit} setSelectedOrder={setSelectedOrder} setSelectedStatus={setSelectedStatus} setShowDepartmentEdit={setShowDepartmentEdit} setSelectedDepartment={setSelectedDepartment}/>
      {showStatusEdit &&
        <EditPopup setShowEdit={setShowStatusEdit} width={'50%'} Container={<StatusEdit selectedOrder={selectedOrder} selectedStatus={selectedStatus} toast={toast} setList={setList} setShowEdit={setShowStatusEdit} />}/>
      }
      {showDepartmentEdit &&
        <EditPopup setShowEdit={setShowDepartmentEdit} width={'50%'} Container={<DepartmentEdit selectedOrder={selectedOrder} selectedDepartment={selectedDepartment} toast={toast} setList={setList} setShowEdit={setShowDepartmentEdit} />}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default Index
