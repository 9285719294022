import React from 'react'
import './index.css'

const Index = ({Value,Action}) => {
  return (
    <div className='panelcontrols_panelbutton_second'>
      <button onClick={Action}>{Value}</button>
    </div>
  )
}

export default Index
