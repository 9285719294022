import React from 'react'
import ImageA from '../../../images/public/17.jpg'
import ImageB from '../../../images/public/28.png'

const Laminates = () => {
  return (
    <div className='public_servic_dentalimplants_wrapper colorprimarybg' id='orthodontics'>
      <div className='public_servic_dentalimplants_container'>
        <div className='public_servic_dentalimplants_row'>
            <div className='public_servic_dentalimplants_images'>
                <div className='public_servic_dentalimplants_imageA' style={{backgroundImage:`url(${ImageA})`}}></div>
                <div className='public_servic_dentalimplants_imageB' style={{backgroundImage:`url(${ImageB})`}}></div>
            </div>
            <div className='public_servic_dentalimplants_description'>
                <h3 className='colorwhite'>Orthodontics</h3>
                <p className='colorwhite'>
                Orthodontics is a dental specialty focused on aligning your bite and straightening your teeth. You might need to see an orthodontist if you have crooked, overlapped, twisted or gapped teeth. Common orthodontic treatments include traditional braces, clear aligners and removable retainers.
                </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Laminates
