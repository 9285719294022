import React from 'react'
import { Link } from 'react-router-dom'

const Bottombar = () => {
  return (
    <div className='public_bottombar_container colorprimarybg'>
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
      <p className='colorwhite'> <Link to={'/privacypolicy'} className='colorwhite'>Privacy Policy</Link>| Infection Control</p>
      <p className='colorwhite'>&copy; 2024 N-Vision Dental (Pvt.) Ltd.  All Right Reserved.</p>
      </div>
    </div>
  )
}

export default Bottombar
