import {BrowserRouter, Routes, Route, Navigate, Outlet} from 'react-router-dom';
import ScrollToTop from './components/scrollTop';
import PublicLayout from './layout/public';
import PublicHome from './pages/public/home';
import PublicFAQ from './pages/public/faq';
import PublicContant from './pages/public/contact';
import PublicService from './pages/public/service'
import PublicLogin from './pages/public/login'
import PublicAbout from './pages/public/about'
import PublicPrivacyPolicy from './pages/public/privacypolicy'

import AdminLayout from './layout/admin'
import AdminDashboard from './pages/admin/dashboard'
import AdminLocations from './pages/admin/locations'
import AdminUsers from './pages/admin/users'
import AdminLabs from './pages/admin/labs'
import AdminSubscribers from './pages/admin/subscribers'

import LabAdminLayout from './layout/labadmin'
import LabAdminDashboard from './pages/labadmin/dashboard'
import LabAdminExpenses from './pages/labadmin/expense'
import LabAdminMaterials from './pages/labadmin/materials'
import LabAdminEmployees from './pages/labadmin/employees'
import LabAdminProducts from './pages/labadmin/products'
import LabAdminDoctors from './pages/labadmin/doctors'
import LabAdminDoctorsClinics from './pages/labadmin/doctors/clinics'
import LabAdminOrdersList from './pages/labadmin/orders/list'
import LabAdminOrdersNew from './pages/labadmin/orders/new'
import LabAdminOrdersStatus from './pages/labadmin/orders/status'
import LabAdminOrdersDepartments from './pages/labadmin/orders/departments'
import LabAdminShipping from './pages/labadmin/shipping'
import LabAdminAccounts from './pages/labadmin/accounts'
import LabAdminAccountsVouchers from './pages/labadmin/accounts/vouchers'
import LabAdminAccountsTransections from './pages/labadmin/accounts/transections'
import LabAdminBilling from './pages/labadmin/billing'
import LabAdminBillingPayments from './pages/labadmin/billing/payments'
import LabAdminReports from './pages/labadmin/reports'
import LabAdminMessages from './pages/labadmin/mesages'

import LabAdminReportLayout from './layout/labadminreport'
import LabAdminReportJobCard from './pages/labadminprints/jobcard'
import LabAdminReportJobCardNp from './pages/labadminprints/jobcardnp'
import LabAdminReportLabel from './pages/labadminprints/label'
import LabAdminReportBilling from './pages/labadminprints/billing'
import LabAdminReportSalesbydate from './pages/labadminprints/salesbydate'
import LabAdminReportSalesbyRange from './pages/labadminprints/salesbyrange'
import LabAdminReportBillingDetailed from './pages/labadminprints/billingdetailed'
import LabAdminReportExpensebydate from './pages/labadminprints/expensebydate'
import LabAdminReportExpensebyRange from './pages/labadminprints/expensebyrange'
import LabAdminReportProfitandLossdate from './pages/labadminprints/profitandlossdate'
import LabAdminReportProfitandLossRange from './pages/labadminprints/profitandlossrange'
import LabAdminReportDailySales from './pages/labadminprints/dailyReport'
import LabAdminReportRangedSales from './pages/labadminprints/salesRangeDetailed'


import DoctorLayout from './layout/doctor'
import DoctorDashboard from './pages/doctor/dashboard'
import DoctorOrdersList from './pages/doctor/orders/list'
import DoctorOrdersNew from './pages/doctor/orders/new'
import DoctorMessages from './pages/doctor/messages'


import DoctorReportLayout from './layout/doctorreport'
import DoctorReportJobCard from './pages/doctorreport/jobcard'
import DoctorReportJobCardNp from './pages/doctorreport/jobcardnp'
import DoctorReportLabel from './pages/doctorreport/label'



import Signout from './pages/signout';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={ <PublicLayout /> }>
            <Route index element={<PublicHome/>} />
            <Route path='faq' element={<PublicFAQ/>} />
            <Route path='contant' element={<PublicContant/>} />
            <Route path='services' element={<PublicService/>} />
            <Route path='login' element={<PublicLogin/>} />
            <Route path='about' element={<PublicAbout/>} />
            <Route path='privacypolicy' element={<PublicPrivacyPolicy/>}/>
          </Route>
          <Route path='/admin' element={<AdminLayout/>}>
            <Route index element={<Navigate to='/admin/dashboard'/>} />
            <Route path='dashboard' element={<AdminDashboard/>}/>
            <Route path='locations' element={<AdminLocations/>}/>
            <Route path='labs' element={<AdminLabs/>}/>
            <Route path='users' element={<AdminUsers/>}/>
            <Route path='subscribers' element={<AdminSubscribers/>}/>
          </Route>
          <Route path='/labadmin' element={<LabAdminLayout/>}>
            <Route index element={<Navigate to='/labadmin/dashboard'/>} />
            <Route path='dashboard' element={<LabAdminDashboard/>}/>
            <Route path='expenses' element={<LabAdminExpenses/>}/>
            <Route path='orders' element={<Outlet/>}>
              <Route index element={<LabAdminOrdersList/>}/>
              <Route path='new' element={<LabAdminOrdersNew/>}/>
              <Route path='new/:orderId' element={<LabAdminOrdersNew/>}/>
              <Route path='status' element={<LabAdminOrdersStatus/>}/>
              <Route path='departments' element={<LabAdminOrdersDepartments/>}/>
            </Route>
            <Route path='shipping' element={<LabAdminShipping/>}/>
            <Route path='accounts' element={<Outlet/>}>
              <Route index element={<LabAdminAccounts/>}/>
              <Route path='vouchers' element={<LabAdminAccountsVouchers/>}/>
              <Route path='transections' element={<LabAdminAccountsTransections/>}/>
            </Route>
            <Route path='billing' element={<Outlet/>}>
              <Route index element={<LabAdminBilling/>}/>
              <Route path='payments' element={<LabAdminBillingPayments/>}/>
            </Route>
            <Route path='reports' element={<LabAdminReports/>}/>
            <Route path='messages' element={<LabAdminMessages/>}/>
            <Route path='entries' element={<Outlet/>}>
              <Route index element={<Navigate to='/labadmin/entries/products'/>}/>
              <Route path='products' element={<LabAdminProducts/>}/>
              <Route path='doctors' element={<LabAdminDoctors/>}/>
              <Route path='clinics' element={<LabAdminDoctorsClinics/>}/>
              <Route path='employees' element={<LabAdminEmployees/>}/>
              <Route path='materials' element={<LabAdminMaterials/>}/>
            </Route>
          </Route>
          <Route path='/labadminreport' element={<LabAdminReportLayout/>}>
            <Route index element={<></>} />
            <Route path='jobcard/:id' element={<LabAdminReportJobCard/>}/>
            <Route path='jobcardnp/:id' element={<LabAdminReportJobCardNp/>}/>
            <Route path='label/:id' element={<LabAdminReportLabel/>}/>
            <Route path='billing' element={<LabAdminReportBilling/>}/>
            <Route path='billingdetailed' element={<LabAdminReportBillingDetailed/>}/>
            <Route path='salesbydate' element={<LabAdminReportSalesbydate/>}/>
            <Route path='salesbyrange' element={<LabAdminReportSalesbyRange/>}/>
            <Route path='expensebydate' element={<LabAdminReportExpensebydate/>}/>
            <Route path='expensebyrange' element={<LabAdminReportExpensebyRange/>}/>
            <Route path='profitandlossdate' element={<LabAdminReportProfitandLossdate/>}/>
            <Route path='profitandlossrange' element={<LabAdminReportProfitandLossRange/>}/>
            <Route path='dailysalesreport' element={<LabAdminReportDailySales/>}/>
            <Route path='rangesalesreport' element={<LabAdminReportRangedSales/>}/>
          </Route>
          <Route path='/doctor' element={<DoctorLayout/>}>
            <Route index element={<Navigate to='/doctor/dashboard'/>} />
            <Route path='dashboard' element={<DoctorDashboard/>}/>
            <Route path='orders' element={<Outlet/>}>
              <Route index element={<DoctorOrdersList/>}/>
              <Route path='new' element={<DoctorOrdersNew/>}/>
            </Route>
            <Route path='messages' element={<DoctorMessages/>}/>
          </Route>
          <Route path='/doctorreport' element={<DoctorReportLayout/>}>
            <Route index element={<></>} />
            <Route path='jobcard/:id' element={<DoctorReportJobCard/>}/>
            <Route path='jobcardnp/:id' element={<DoctorReportJobCardNp/>}/>
            <Route path='label/:id' element={<DoctorReportLabel/>}/>
          </Route>
          <Route path='/signout' element={<Signout/>}/>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
