import React from 'react'
import './index.css'
const Index = () => {
  return (
    <div className='public_pp_hero_wrapper colorprimarybg'>
      <div className='public_pp_hero_container'>
        <h3>Privacy Policy for N Vision Digital Dental Laboratory</h3>
        <p>Last updated: [16/05/2020]</p>
        <p>N Vision Digital Dental Laboratory ("us", "we", or "our") is committed to safeguarding your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our digital dental design and manufacturing services ("Services"). By using our Services, you consent to the practices described in this policy.</p>
      </div>
    </div>
  )
}

export default Index
