import ApiUrl from "../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const GetCountry = (setValues) => {
    fetch(ApiUrl.Admin_Location_Country,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        setValues(data.response);
    });
}

const GetState = (selectedCountry,setValues) => {
    if(selectedCountry === 0){
        setValues([]);
        return;
    }
    fetch(ApiUrl.Admin_Location_State+"/"+selectedCountry,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        setValues(data.response);
    });
}

const GetCity = (setValues) => {
    fetch(ApiUrl.Admin_Location_City,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        setValues(data.response);
    });
}

const GetCitybyState = (selectedState,setValues) => {
    fetch(ApiUrl.Admin_Location_City+'/'+selectedState,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        setValues(data.response);
    });
}

const PostCountry = (body,setValues) => {
    fetch(ApiUrl.Admin_Location_Country,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            GetCountry(setValues);
        }
        else{
            alert(data.response);
        }
    });
}

const PostState = (body,selectedCountry,setValues) => {
    fetch(ApiUrl.Admin_Location_State,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            GetState(selectedCountry,setValues);
        }
        else{
            alert(data.response);
        }
    });
}

const PostCity = (body,setValues) => {
    fetch(ApiUrl.Admin_Location_City,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            GetCity(setValues);
        }
        else{
            alert(data.response);
        }
    });
}


export default {GetCountry,GetState,GetCity,GetCitybyState,PostCountry,PostState,PostCity}