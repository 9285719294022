import React,{useState,useEffect} from 'react'
import Input from '../../../../../components/panelcontrols/panelinput'
import TextArea from '../../../../../components/panelcontrols/paneltextarea'
import Button from '../../../../../components/panelcontrols/panelbuttonmain'
import Functions from '../functions'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Index = ({selectedStatus,setSelectedStatus,toast,setList,setShowEdit}) => {
    const [name,setName] = useState('');
    const [remarks,setRemarks] = useState('');
    useEffect(()=>{
        if(selectedStatus){
            setName(selectedStatus.name);
            setRemarks(selectedStatus.remarks);
        }
        else{
            setName('');
            setRemarks('');
        }
    },[selectedStatus])
    const reset = ()=>{
        setSelectedStatus(null)
        setShowEdit(false)
    }
  return (
    <div className='labadmin_product_edit_wrapper'>
        <div className='labadmin_product_edit_container'>
            <div className='labadmin_product_edit_close'>
                <button onClick={()=>{
                   reset(); 
                }}>
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            <div className='labadmin_product_formbox'>
                <h3>Edit status</h3>
                <Input Lable={'Name'} Type={'text'} Value={name} setValue={setName}/>
                <TextArea Lable={'Remarks'} Type={'text'} Value={remarks} setValue={setRemarks}/>
                <Button Value={'Save'} Action={()=>{
                    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                    if(name === ''){
                    toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                    return;
                    }
                    Functions.Put(selectedStatus.id,{
                        name: name,
                        remarks: remarks
                    },setList,toast,toastid,reset)
                }}/>
            </div>
        </div>
    </div>
  )
}

export default Index
