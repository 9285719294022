import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './index.css'

const Index = () => {
  return (
    <div className='labs_information_container'>
      <div className='labs_information_row'>
        <div className='labs_information_col labs_information_col_border'>
            <div className='labs_information_locations_item'>
                <div className='labs_information_locations_item_icon'>
                    <FontAwesomeIcon icon={faLocationDot}/>
                </div>
                <div className='labs_information_locations_item_details'>
                    <h3>Charlotte Office (Head Office)</h3>
                    <p>7561 Orr Rd., Charlotte, NC 28213, United States of America</p>
                    <p>+1 (845) 544 - 0100</p>
                    <p>usa@nvisiondental.com</p>
                </div>
            </div>
        </div>
        <div className='labs_information_col labs_information_col_border'>
            <div className='labs_information_locations_item'>
                <div className='labs_information_locations_item_icon'>
                    <FontAwesomeIcon icon={faLocationDot}/>
                </div>
                <div className='labs_information_locations_item_details'>
                    <h3>Lahore Office</h3>
                    <p>P-Block, Oman Road, Johar Town, Lahore, 54782, Pakistan</p>
                    <p>+92 (333) 460 - 1314</p>
                    <p>lhr@nvisiondental.com</p>
                </div>
            </div>
        </div>
        <div className='labs_information_col'>
            <div className='labs_information_locations_item'>
                <div className='labs_information_locations_item_icon'>
                    <FontAwesomeIcon icon={faLocationDot}/>
                </div>
                <div className='labs_information_locations_item_details'>
                    <h3>Faisalabad Office</h3>
                    <p>P-369 Block B Street#2 Alnajaf Colony Faisalabad, 38000, Pakistan</p>
                    <p>+92 (300) 460 - 1314</p>
                    <p>fsd@nvisiondental.com</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Index
