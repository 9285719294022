import React from 'react'

const PrimarytextArea = ({Lable, Value, setInput}) => {
  return (
    <div className='public_primary_textarea_wrapper'>
      {Lable &&
        <label>{Lable}</label>
      }
      <div className='public_primary_textarea_container'>
        <textarea 
          value={Value}
          placeholder={Lable} 
          onChange={(e)=> setInput(e.target.value)}
        />
      </div>
    </div>
  )
}

export default PrimarytextArea
