import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Title from '../../../../components/panelcontrols/paneltitle';
import Input from '../../../../components/panelcontrols/panelinput';
import TextArea from '../../../../components/panelcontrols/paneltextarea';
import Button from '../../../../components/panelcontrols/panelbuttonmain';
import Button2 from '../../../../components/panelcontrols/panelbuttonsecond';
import Select from '../../../../components/panelcontrols/panelselect';
import './index.css';
import Lineitems from './lineitems';
import Files from './files';
import Notes from './notes';
import Tasks from './tasks';
import LineItemsAdd from './lineitems/add';
import DoctorFunctions from '../../doctors/functions';
import DepartmentFunctions from '../departments/functions';
import StatusFunctions from '../status/functions';
import Functions from './functions';
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [patient, setPatient] = useState('');
  const [doctor, setDoctor] = useState({ value: 0, name: 'Select doctor/clinic' });
  const [doctorlist, setDoctorList] = useState([]);
  const [date, setDate] = useState('');
  const [workingpan, setWorkingPan] = useState('');
  const [status, setStatus] = useState({ value: 0, name: 'Select status' });
  const [statuslist, setStatusList] = useState([]);
  const [department, setDepartment] = useState({ value: 0, name: 'Select department' });
  const [departmentlist, setDepartmentList] = useState([]);
  const [lineitems, setLineitems] = useState([]);
  const [notes, setNotes] = useState([]);
  const [files, setFiles] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(0);
  const [subtotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [nettotal, setNetTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      var orderDataValue = "";
      if (orderId) {
        try {
          const orderData = await Functions.getOrder(orderId);
          orderDataValue = orderData.response[0]
          //Set Patient
          if (orderDataValue) {
            setPatient(orderDataValue.patient);
            // Set Date
            if (orderDataValue.date) {
              const dateObject = new Date(orderDataValue.date);
              if (!isNaN(dateObject.getTime())) { // Check if dateObject is a valid date
                const formattedDate = dateObject.toISOString().split('T')[0];
                setDate(formattedDate);
              } else {
                setDate(""); // Set date to an empty string if it's not a valid date
              }
            } else {
              setDate(""); // Set date to an empty string if orderDataValue.date is null or undefined
            }
          
            // Set Work Pan
            setWorkingPan(orderDataValue.workingpan);
          
            setSubTotal(orderDataValue.subtotal);
            setDiscount(orderDataValue.discount);
          
            setNetTotal(orderDataValue.nettotal);
            console.log(orderDataValue.items)
            setLineitems(orderDataValue.items);
          }
        } catch (error) {
          console.error("Error fetching order data:", error);
        }
      }
      DoctorFunctions.Get(async(data) => {
        const sendData = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          sendData.push({
            value: parseInt(element.id),
            name: element.firstname + ' ' + element.lastname + ' / ' + element.clinicname,
          });
        }
        await setDoctorList(sendData);
        var doctorData = sendData.find(doctor => doctor.value === orderDataValue.doctorId)
        if(doctorData){
          setDoctor(doctorData)
        }
      });
  
      DepartmentFunctions.Get((data) => {
        const sendData = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          sendData.push({
            value: parseInt(element.id),
            name: element.name,
          });
        }
        setDepartmentList(sendData);
        var departmentData = sendData.find(department => department.value === orderDataValue.department)
        if(departmentData){
          setDepartment(departmentData)
        }
      });
  
      StatusFunctions.Get((data) => {
        const sendData = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          sendData.push({
            value: parseInt(element.id),
            name: element.name,
          });
        }
        setStatusList(sendData);
      });
    };
  
    fetchData();
  }, [orderId]);

  useEffect(() => {
    if (order) {
      setPatient(order.patient);
      setDoctor({ value: order.doctorId, name: order.doctorName });
      setDate(order.date);
      setWorkingPan(order.workingpan);
      setStatus({ value: order.statusId, name: order.statusName });
      setDepartment({ value: order.departmentId, name: order.departmentName });
      setLineitems(order.lineitems);
      setFiles(order.files);
      setNotes(order.notes);
      setTasks(order.tasks);
      setSubTotal(order.subtotal);
      setDiscount(order.discount);
    }
  }, [order]);

  useEffect(() => {
    let subtotalsend = 0;
    for (let index = 0; index < lineitems.length; index++) {
      const element = lineitems[index];
      subtotalsend = subtotalsend + parseInt(element.totalprice);
    }
    setSubTotal(subtotalsend);
    setNetTotal(subtotalsend - parseInt(discount));
  }, [lineitems, discount]);

  const reset = () => {
    setPatient('');
    setDoctor({ value: 0, name: 'Select doctor/clinic' });
    setDate('');
    setWorkingPan('');
    setStatus({ value: 0, name: 'Select status' });
    setDepartment({ value: 0, name: 'Select department' });
    setLineitems([]);
    setFiles([]);
    setNotes([]);
    setTasks([]);
    setSubTotal(0);
    setDiscount(0);
  };

  const saveOrder = () => {
    const toastid = toast.loading('Please wait ...', { position: toast.POSITION.BOTTOM_CENTER });
    if (
      doctor.value === 0 ||
      patient === '' ||
      date === '' ||
      workingpan === '' ||
      department.value === 0 ||
      lineitems.length === 0 ||
      nettotal === 0
    ) {
      toast.update(toastid, { render: 'Fields Empty', type: 'error', isLoading: false, autoClose: 2000, position: toast.POSITION.BOTTOM_CENTER });
      return;
    }
    if(orderId){
      const lineitemsSend = lineitems.map((element) => {
        const combinedArray = Object.values(element.sections).flat();
        return {
          service: element.servicename,
          shades: element.shades,
          enclosed: element.enclosed,
          translucency: element.translucency,
          occlusal: element.occlusal,
          ponticdesign: element.ponticdesign,
          duedate: element.duedate,
          appointment: element.appointment,
          units: element.units,
          totalprice: element.totalprice,
          description: element.description,
          unitlist: combinedArray,
        };
      });
      Functions.Put(
        {
          id: orderId,
          doctor: doctor.value,
          patient: patient,
          date: date,
          workingpan: workingpan,
          status: status.value,
          department: department.value,
          subtotal: subtotal,
          discount: discount,
          nettotal: nettotal,
          notes: notes,
          tasks: tasks,
          lineitems: lineitemsSend,
        },
        toast,
        toastid,
        () => {
          reset();
          setTimeout(() => {
            document.location.replace('/labadmin/orders');
          }, 500);
        }
      );
      return
    }
    const lineitemsSend = lineitems.map((element) => {
      const unitListSend = element.unitList.map((uitem) => uitem.id);
      return {
        service: element.service.value,
        shades: element.shades,
        enclosed: element.enclosed.value,
        translucency: element.translucency.value,
        occlusal: element.occlusal.value,
        ponticdesign: element.ponticdesign.value,
        duedate: element.duedate,
        appointment: element.appointment,
        units: element.units,
        totalprice: element.totalprice,
        description: element.description,
        unitlist: unitListSend,
      };
    });
    Functions.Post(
      {
        doctor: doctor.value,
        patient: patient,
        date: date,
        workingpan: workingpan,
        status: status.value,
        department: department.value,
        subtotal: subtotal,
        discount: discount,
        nettotal: nettotal,
        notes: notes,
        tasks: tasks,
        lineitems: lineitemsSend,
      },
      toast,
      toastid,
      () => {
        reset();
        setTimeout(() => {
          document.location.replace('/labadmin/orders');
        }, 500);
      }
    );
  };

  return (
    <div className='labadmin_orders_new_container'>
      <Title Value={'Edit Order'} />
      {page === 0 && (
        <div className='labadmin_orders_new_formbox'>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
              <Select Lable={'Client'} Value={doctor} setValue={setDoctor} Options={doctorlist} />
            </div>
            <div className='labadmin_orders_new_col50'>
              <Input Lable={'Patient'} Type={'text'} Value={patient} setValue={setPatient} />
            </div>
          </div>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
              <Input Lable={'Date'} Type={'date'} Value={date} setValue={setDate} />
            </div>
            <div className='labadmin_orders_new_col50'>
              <Input Lable={'Working Pan'} Type={'text'} Value={workingpan} setValue={setWorkingPan} />
            </div>
          </div>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
              {/* 
              <Select Lable={'Status'} Value={status} setValue={setStatus} Options={statuslist} />
              */}
            </div>
            <div className='labadmin_orders_new_col50'>
              <Select Lable={'Department'} Value={department} setValue={setDepartment} Options={departmentlist} />
            </div>
          </div>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
              <Lineitems lineitems={lineitems} setLineitems={setLineitems} setPage={setPage} />
            </div>
            <div className='labadmin_orders_new_col50'>
              <Files lineitems={files} setLineitems={setFiles} setPage={setPage} />
            </div>
          </div>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
              <Notes notes={notes} setNotes={setNotes} setPage={setPage} />
            </div>
            <div className='labadmin_orders_new_col50'>
              <Tasks tasks={tasks} setTasks={setTasks} setPage={setPage} />
            </div>
          </div>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col33'>
              <Input Lable={'Sub Total'} Type={'number'} Value={subtotal} setValue={setSubTotal} readonly={true} />
            </div>
            <div className='labadmin_orders_new_col33'>
              <Input Lable={'Discount'} Type={'number'} Value={discount} setValue={setDiscount} />
            </div>
            <div className='labadmin_orders_new_col33'>
              <Input Lable={'Net Total'} Type={'number'} Value={nettotal} setValue={setNetTotal} readonly={true} />
            </div>
          </div>
          <div className='labadmin_orders_new_row'>
            <div className='labadmin_orders_new_col50'>
              <Button Value={'Save'} Action={saveOrder} />
            </div>
            <div className='labadmin_orders_new_col50'>
              <Button2 Value={'Back'} Action={() => { document.location.replace('/labadmin/orders'); }} />
            </div>
          </div>
        </div>
      )}
      {page === 1 && <LineItemsAdd lineitems={lineitems} setLineitems={setLineitems} setPage={setPage} />}
      <ToastContainer />
    </div>
  );
};

export default Index;
