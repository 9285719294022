import React,{useState,useEffect} from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Index = ({List}) => {
  const [filterList,setfilterList] = useState(List);
  function filterFunction(value){
    if(value === ""){
      setfilterList(List);
    }
    else{
      const filtedList = List.filter((obj)=>{
        return Object.values(obj)
        .join(" ").toLowerCase()
        .includes(value.toLowerCase());
      })
      setfilterList(filtedList);
    }
  }
  useEffect(()=>{
      setfilterList(List);
  },[List])
  return (
    <div className='user_table_wrapper'>
      <div className='user_table_search'>
        <input onChange={(e)=>{filterFunction(e.target.value);}}/>
        <div className='user_table_search_icon'>
          <FontAwesomeIcon icon={faSearch}/>
        </div>
      </div>
      <div className='user_table_container'>
        <div className='_table'>
          <div className='_thead'>
            <div className='_tr'>
              <div className='_th' style={{width:"15%"}}>Number</div>
              <div className='_th' style={{width:"15%"}}>Voucher</div>
              <div className='_th' style={{width:"35%"}}>Description</div>
              <div className='_th' style={{width:"15%"}}>Date</div>
              <div className='_th' style={{width:"10%"}}>Type</div>
              <div className='_th' style={{width:"10%"}}>Amount</div>
            </div>
          </div>
          <div className='_tbody'>
            {filterList.length > 0 &&
              filterList.map((item,index)=>{
                return(
                  <div className='_tr' key={index}>
                    <div className='_td' style={{width:"15%"}}>{item.id}</div>
                    <div className='_td' style={{width:"15%"}}>{item.voucher}</div>
                    <div className='_td' style={{width:"35%"}}>{item.description}</div>
                    <div className='_td' style={{width:"15%"}}>{item.date}</div>
                    <div className='_td' style={{width:"10%"}}>{item.type}</div>
                    <div className='_td' style={{width:"10%"}}>{item.amount}</div>
                  </div>
                )
              })
            }
            {filterList.length <= 0 &&
              <div className='table_nodata'>
                <p>No data available</p>
              </div>
            }
          </div>
        </div>
      </div>      
    </div>
  )
}

export default Index
