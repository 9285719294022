import React from 'react'
import './index.css'

const Index = ({Lable,Placeholder,Type,Value,setValue,readonly}) => {
  return (
    <div className='panelcontrols_panelinput'>
      <label>{Lable}</label>
      <input 
        type={Type}
        placeholder={Placeholder} 
        value={Value} 
        onChange={(e)=>setValue(e.target.value)}
        readOnly={readonly}
      />
    </div>
  )
}

export default Index
