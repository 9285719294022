import React,{useState} from 'react'
import Input from '../../../../components/panelcontrols/panelinput'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Functions from '../funtions'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Index = ({selectedUserID,toast,setList,setShowEdit}) => {
    const [password,setPassword] = useState('');
    const [repassword,setRePassword] = useState('');
  return (
    <div className='labadmin_product_edit_wrapper'>
        <div className='labadmin_product_edit_container'>
            <div className='labadmin_product_edit_close'>
                <button onClick={()=>{
                   setShowEdit(false); 
                }}>
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            <div className='labadmin_product_formbox'>
                <h3>Password change</h3>
                <Input Lable={'New Password'} Type={'text'} Value={password} setValue={setPassword}/>
                <Input Lable={'Re-Enter Password'} Type={'text'} Value={repassword} setValue={setRePassword}/>
                <Button Value={'Save'} Action={()=>{
                    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                    if(password === ''){
                        toast.update(toastid, { render: "Enter a valid password", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                        return;
                    }
                    if(password !== repassword){
                        toast.update(toastid, { render: "Password doesnt match", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                        return;
                    }
                    Functions.Put(selectedUserID,{
                        password: password
                    },setList,toast,toastid,()=>{setShowEdit(false)})
                }}/>
            </div>
        </div>
    </div>
  )
}

export default Index
