import ApiUrl from "../../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = (setValues) => {
    fetch(ApiUrl.LabAdmin_Orders,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        data.response.sort((a, b) => b.id - a.id);
        setValues(data.response);
    });
}

// Assuming you have a service or API function to fetch order data
const getOrder = async (itemId) => {
    try {
      // Make an API call to fetch order data based on itemId
      const response = await fetch(ApiUrl.LabAdmin_Orders + `/${itemId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch order data');
      }
      const orderData = await response.json();
      return orderData;
    } catch (error) {
      console.error('Error fetching order data:', error);
      throw error;
    }
  };
  

const DeleteOrder = (id, setValues) => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    fetch(ApiUrl.LabAdmin_Orders + "/" + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + User.token
        }
    })
    .then(res => {
        if (!res.ok) {
            throw new Error('Network response was not ok');
        }
        return res.json();
    })
    .then(data => {
        console.log(data)
    })
    .catch(error => {
        console.error('Error deleting order:', error);
    });
}



const GetByStatus = (status,setValues) => {
    fetch(ApiUrl.LabAdmin_Orders+"/bystatus/"+status,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        setValues(data.response);
    });
}

const UpdateStatus = (id,body,setValues,toast,toastid,reset) => {
    fetch(ApiUrl.LabAdmin_Orders+"/orderstatus/"+id,{
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            setTimeout(() => {
              toast.update(toastid, { render: "Updated", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
            Get(setValues);
            reset();
        }
        else{
            setTimeout(() => {
              toast.update(toastid, { render: data.response, type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
        }
    });
}

const UpdateDepartment = (id,body,setValues,toast,toastid,reset) => {
    fetch(ApiUrl.LabAdmin_Orders+"/orderdepartment/"+id,{
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            setTimeout(() => {
              toast.update(toastid, { render: "Updated", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
            Get(setValues);
            reset();
        }
        else{
            setTimeout(() => {
              toast.update(toastid, { render: data.response, type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
        }
    });
}

export default {Get,getOrder,DeleteOrder,GetByStatus,UpdateDepartment,UpdateStatus};