import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Functions from './function';
import Logo from '../../../images/public/websitefooter.png'

const Index = () => {
    const [searchParams] = useSearchParams();
    const [formData,setFormData] = useState(null);
    let inprint = false;
    useEffect(()=>{
        Functions.Get(searchParams.get('from'),searchParams.get('to'),(data)=>{
            setFormData(data)
            if(!inprint){
                setTimeout(() => {
                    window.print();
                    window.close();
                }, 400);
            }
            inprint = true;
        });
    },[])
  return (
    <>
        <div style={{width:"200px",margin:"20px auto"}}>
            <img src={Logo} style={{width:"100%"}}/>
        </div>
      {formData &&
      <>
        <div style={{display:"flex",justifyContent:"space-between"}}>
            <h3 style={{fontWeight:"500",fontSize:"18px"}}>Profit & Loss range</h3>
            <p>From: {searchParams.get('from')} To: {searchParams.get('to')}</p>
        </div>
        <div style={{backgroundColor:"#2F1C60",height:"5px",margin:"10px 0px"}}></div>
        <div style={{display:"flex",border:"1px solid black"}}>
            <div style={{width:"10%", borderRight:"1px solid black", padding:"10px"}}>
                <h3 style={{fontSize:"16px",fontWeight:"500"}}>ID</h3>
            </div>
            <div style={{width:"60%", borderRight:"1px solid black", padding:"10px"}}>
                <h3 style={{fontSize:"16px",fontWeight:"500"}}>Title</h3>
            </div>
            <div style={{width:"30%", padding:"10px"}}>
                <h3 style={{fontSize:"16px",fontWeight:"500",textAlign:"right"}}>Amount</h3>
            </div>
        </div>
        {formData.list.map((item,index)=>{
            return(
                <div key={index} style={{display:"flex",borderBottom:"1px solid black",borderLeft:"1px solid black",borderRight:"1px solid black"}}>
                    <div style={{width:"10%", borderRight:"1px solid black", padding:"10px"}}>
                        <h3 style={{fontSize:"16px",fontWeight:"500"}}>{item.id}</h3>
                    </div>
                    <div style={{width:"60%", borderRight:"1px solid black", padding:"10px"}}>
                        <h3 style={{fontSize:"16px",fontWeight:"500"}}>{item.title}</h3>
                    </div>
                    <div style={{width:"30%", padding:"10px"}}>
                        <h3 style={{fontSize:"16px",fontWeight:"500",textAlign:"right"}}>{item.total}</h3>
                    </div>
                </div>
            )
        })}
        <div style={{display:"flex",borderBottom:"1px solid black",borderLeft:"1px solid black",borderRight:"1px solid black"}}>
            <div style={{width:"10%", borderRight:"1px solid black", padding:"10px"}}>
                <h3 style={{fontSize:"16px",fontWeight:"500"}}></h3>
            </div>
            <div style={{width:"60%", borderRight:"1px solid black", padding:"10px"}}>
                <h3 style={{fontSize:"16px",fontWeight:"500"}}>Total</h3>
            </div>
            <div style={{width:"30%", padding:"10px"}}>
                <h3 style={{fontSize:"16px",fontWeight:"500",textAlign:"right"}}>{formData.closingbalance}</h3>
            </div>
        </div>
      </>
      }
    </>
  )
}

export default Index
