import React,{useState,useEffect} from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Index = ({List}) => {
  const [filterList,setfilterList] = useState(List);
  function filterFunction(value){
    if(value === ""){
      setfilterList(List);
    }
    else{
      const filtedList = List.filter((obj)=>{
        return Object.values(obj)
        .join(" ").toLowerCase()
        .includes(value.toLowerCase());
      })
      setfilterList(filtedList);
    }
  }
  useEffect(()=>{
      setfilterList(List);
  },[List])
  return (
    <div className='city_table_wrapper'>
      <div className='city_table_search'>
        <input onChange={(e)=>{filterFunction(e.target.value);}}/>
        <div className='city_table_search_icon'>
          <FontAwesomeIcon icon={faSearch}/>
        </div>
      </div>
      <div className='city_table_container'>
        <table>
          <thead>
            <tr>
              <th style={{width:"10%"}}>Id</th>
              <th style={{width:"15%"}}>Name</th>
              <th style={{width:"15%"}}>State</th>
              <th style={{width:"15%"}}>Country</th>
              <th style={{width:"45%"}}>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {
              filterList.map((item,index)=>{
                return(
                  <tr key={index}>
                    <td style={{width:"10%"}}>{item.id}</td>
                    <td style={{width:"15%"}}>{item.name}</td>
                    <td style={{width:"15%"}}>{item.state}</td>
                    <td style={{width:"15%"}}>{item.country}</td>
                    <td style={{width:"45%"}}>{item.remarks}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>      
    </div>
  )
}

export default Index
