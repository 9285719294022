import React from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

const Index = ({Action}) => {
  return (
    <div className='panelcontrols_paneltablebuttons_edit'>
      <button onClick={Action}><FontAwesomeIcon icon={faPenToSquare}/></button>
    </div>
  )
}

export default Index
