import React,{useState,useEffect} from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import Table from './orderTable'
import Functions from './functions'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const [list,setList] = useState([]);
  useEffect(()=>{
    Functions.Get(setList);
  },[])
  return (
    <div className='labadmin_order_list_container'>
      <Title Value={'Orders'}/>
      <div style={{display:"flex",justifyContent:"flex-end"}}>
        <div style={{width:"25%"}}>
          <Button Value={'Add New'} Action={()=>{
            document.location.replace('/doctor/orders/new');
          }}/>
        </div>
      </div>
      <Table List={list}/>
      <ToastContainer/>
    </div>
  )
}

export default Index
