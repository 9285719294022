import React,{useState,useEffect} from 'react'
import Functions from './function'
import { useParams } from 'react-router-dom';
import { QRCodeCanvas } from "qrcode.react";
import './index.css'

const Index = () => {
    const [orderdata,setOrderData] = useState(null);
    const {id} = useParams();
    let inprint = false;
    useEffect(()=>{
        Functions.Get(id,(data)=>{
            setOrderData(data);
            if(!inprint){
                setTimeout(() => {
                    window.print();
                    window.close();
                }, 400);
            }
            inprint = true;
        });
    },[])
  return (
    <div className='labadmin_lableprint'>
    {orderdata &&
        <div style={{margin:"10px"}}>
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <p style={{fontSize:"9pt"}}>Invoice No: <b style={{fontWeight:"600",fontSize:"9pt"}}>{id}</b></p>
                <p style={{fontSize:"9pt"}}>Pan No: <b style={{fontWeight:"600",fontSize:"9pt"}}>{orderdata.workingpan}</b></p>
            </div>
            <div style={{border:"2px solid #646464",padding:"5px"}}>
                <h5 style={{fontSize:"8pt"}}>To</h5>
                <div style={{padding:"2px 10px"}}>
                    <p style={{fontSize:"11pt"}}><b style={{fontSize:"11pt"}}>{orderdata.clinicname+ " / " + orderdata.doctorname}</b></p>
                    <p style={{fontSize:"9pt"}}>Patient: <b style={{fontSize:"9pt"}}>{orderdata.patient}</b></p>
                    <p style={{fontSize:"9pt"}}>Address: <b style={{fontSize:"9pt"}}>{orderdata.clinicaddress}</b></p>
                    <p style={{fontSize:"9pt"}}>Email: <b style={{fontSize:"9pt"}}>{orderdata.doctoremail}</b></p>
                    <p style={{fontSize:"9pt"}}>Phone: <b style={{fontSize:"9pt"}}>{orderdata.doctorcontact}</b></p>
                </div>
            </div>
            <div style={{borderBottom:"2px solid #646464",borderLeft:"2px solid #646464",borderRight:"2px solid #646464",padding:"5px", margin:"0px 0px"}}>
                <h5 style={{fontSize:"8pt"}}>From</h5>
                <div style={{padding:"2px 10px"}}>
                    <div style={{display:"flex"}}>
                        <div style={{width:"80%"}}>
                            <p style={{fontSize:"11pt"}}>N-Vision Digital Dental Laboratory</p>
                            <p style={{fontSize:"9pt"}}>P 369, Street#2, Block B, Alnajaf Colony, Faisalabad, Pakistan. 38000</p>
                            <p style={{fontSize:"9pt"}}>Email: fsd@nvisiondental.com</p>
                            <p style={{fontSize:"9pt"}}>041-8555333</p>
                        </div>
                        <div style={{width:"20%"}}>
                        <QRCodeCanvas
                            id="qrCode"
                            value={id}
                            bgColor={"#ffffff"}
                            level={"H"}
                            size={60}
                        />
                        </div>
                    </div>
                    
                </div>
            </div>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", margin:"5px 0px"}}>
                <div style={{borderBottom:"1px solid black"}}>
                    <h3 style={{fontWeight:"500",fontSize:"9pt"}}>Checked by</h3>
                    <div style={{width:"40vw"}}></div>
                </div>
                <div style={{borderBottom:"1px solid black"}}>
                    <h3 style={{fontWeight:"500",fontSize:"9pt"}}>Delivered by</h3>
                    <div style={{width:"40vw"}}></div>
                </div>
            </div>
        </div>
    }
    </div>
  )
}

export default Index

