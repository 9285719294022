import React,{useState,useEffect} from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import Input from '../../../../components/panelcontrols/panelinput'
import TextArea from '../../../../components/panelcontrols/paneltextarea'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import './index.css'
import Table from './statusTable'
import Edit from './edit'
import Functions from './functions'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const [name,setName] = useState('');
  const [remarks,setRemarks] = useState('');
  const [list,setList] = useState([]);
  const [selectedStatus,setSelectedStatus] = useState(null); 
  const [showEdit,setShowEdit] = useState(false); 
  useEffect(()=>{
    Functions.Get(setList);
  },[])
  const reset = ()=>{
    setName('');
    setRemarks('');
  }
  return (
    <div className='labadmin_orders_status_container'>
      <Title Value={'Order Status'}/>
      <div className='admin_orders_status_formbox'>
        <Input Lable={'Name'} Type={'text'} Value={name} setValue={setName}/>
        <TextArea Lable={'Remarks'} Type={'text'} Value={remarks} setValue={setRemarks}/>
        <Button Value={'Save'} Action={()=>{
          const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
          if(name === ''){
            toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            return;
          }
          Functions.Post({
            name: name, 
            remarks : remarks
          },setList,toast,toastid,reset)
        }}/>
      </div>
      <Table List={list} setList={setList} setSelectedStatus={setSelectedStatus} setShowEdit={setShowEdit} toast={toast}/>
      {showEdit &&
        <Edit selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} toast={toast} setList={setList} setShowEdit={setShowEdit}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default Index
