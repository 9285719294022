import React from 'react'
import './index.css'

const Index = () => {
  return (
    <div className='public_about_experince_container'>
      <h3>Experience the N-Vision Difference</h3>
      <p>Elevate your dental practice with N-Vision Digital Dental Laboratory. Discover the synergy of artistry and technology that transforms smiles and enhances lives. With a legacy of precision, innovation, and unwavering dedication, we invite you to experience the N-Vision difference and join us on a journey to dental perfection. Your vision, our expertise – a masterpiece in every smile.</p>
    </div>
  )
}

export default Index
