import React, { useState,useEffect } from 'react'
import Title from '../../../../../components/panelcontrols/paneltitle'
import Button from '../../../../../components/panelcontrols/panelbuttonmain'
import Select from '../../../../../components/panelcontrols/panelselect'
import StatusFunctions from '../../status/functions'
import Functions from '../functions'

const Index = ({selectedOrder,selectedStatus,toast,setList,setShowEdit}) => {
  const [status,setStatus] = useState({value:0,name:'Select status'});
  const [statuslist,setStatusList] = useState([]);
  useEffect(()=>{
    StatusFunctions.Get((data)=>{
      const sendData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        sendData.push({
          value : parseInt(element.id),
          name : element.name
        })
        if(parseInt(element.id) === parseInt(selectedStatus)){
          setStatus({value:parseInt(element.id),name:element.name});
        }
      }
      setStatusList(sendData)
    })
  },[selectedStatus])
  return (
    <div className='labadmin_orders_status_selector_container'>
      <Title Value={'Edit Status for Order # '+selectedOrder}/>
      <Select Lable={'Status'} Value={status} setValue={setStatus} Options={statuslist}/>
      <Button Value={'Save'} Action={()=>{
        const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
        if(selectedOrder === 0 || status.value === 0){
          toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
          return;
        }
        Functions.UpdateStatus(selectedOrder,{status:status.value},setList,toast,toastid,()=>{
          setShowEdit(false);
        })
      }} />
    </div>
  )
}

export default Index
