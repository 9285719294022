import ApiUrl from "../../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));



const Post = (body,toast,toastid,reset) => {
    fetch(ApiUrl.LabAdmin_Orders,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            setTimeout(() => {
              toast.update(toastid, { render: "Added", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
            reset();
        }
        else{
            setTimeout(() => {
              toast.update(toastid, { render: data.response, type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
        }
    });
}

const Put = (body,toast,toastid,reset) => {
  console.log(User.token)
  fetch(ApiUrl.LabAdmin_Orders,{
      method: 'put',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+User.token
      },
      body: JSON.stringify(body)
  })
  .then(res=>res.json())
  .then(data =>{
      if(data.success){
          setTimeout(() => {
            toast.update(toastid, { render: "Added", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
          }, 500);
          reset();
      }
      else{
          setTimeout(() => {
            toast.update(toastid, { render: data.response, type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
          }, 500);
      }
  });
}

// Assuming you have a service or API function to fetch order data
const getOrder = async (itemId) => {
    try {
      // Make an API call to fetch order data based on itemId
      const response = await fetch(ApiUrl.LabAdmin_Orders + `/${itemId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + User.token
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch order data');
      }
      const orderData = await response.json();
      return orderData;
    } catch (error) {
      console.error('Error fetching order data:', error);
      throw error;
    }
  };

export default {Post, Put, getOrder};