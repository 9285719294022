import React,{useState,useEffect} from 'react'
import Input from '../../../../components/panelcontrols/panelinput'
import Select from '../../../../components/panelcontrols/panelselect'
import TextArea from '../../../../components/panelcontrols/paneltextarea'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Functions from '../functions'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Index = ({selectedMaterial,setSelectedMaterial,toast,setList,setShowEdit}) => {
    const [title,setTitle] = useState('');
    const [description,setDescription] = useState('');
    const [type,setType] = useState({value:0,name:'Select type'});
    const [typelist,setTypeList] = useState([]);
    const [weight,setWeight] = useState(0);
    useEffect(()=>{
        if(selectedMaterial){
            setTitle(selectedMaterial.title);
            setDescription(selectedMaterial.description);
            setWeight(selectedMaterial.weight);
            Functions.GetMaterialType((data)=>{
                var sendData = [];
                for (let index = 0; index < data.length; index++) {
                const element = data[index];
                    sendData.push({
                        value: parseInt(element.id),
                        name: element.name
                    });
                    if(element.id === selectedMaterial.id){
                        setType({
                            value: parseInt(element.id),
                            name: element.name
                        })
                    }
                }
                setTypeList(sendData);
            })
        }
        else{
            setTitle('');
            setDescription('');
            setWeight(0);
            setType({value:0,name:'Select type'})
        }
    },[selectedMaterial])
    const reset = ()=>{
        setSelectedMaterial(null)
        setShowEdit(false)
    }
  return (
    <div className='labadmin_product_edit_wrapper'>
        <div className='labadmin_product_edit_container'>
            <div className='labadmin_product_edit_close'>
                <button onClick={()=>{
                   reset(); 
                }}>
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            <div className='labadmin_product_formbox'>
                <h3>Edit Material</h3>
                <Input Lable={'Title'} Type={'text'} Value={title} setValue={setTitle}/>
                <Input Lable={'Weight (Grams)'} Type={'number'} Value={weight} setValue={setWeight}/>
                <Select Lable={'Type'} Value={type} setValue={setType} Options={typelist}/>
                <TextArea Lable={'Description'} Type={'text'} Value={description} setValue={setDescription}/>
                <Button Value={'Save'} Action={()=>{
                    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                    if(title === '' || type.value === 0){
                      toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                      return;
                    }
                    Functions.PutMaterial(selectedMaterial.id,{
                        title: title,
                        weight: weight,
                        type: type.value,
                        description: description
                    },setList,toast,toastid,reset)
                }}/>
            </div>
        </div>
    </div>
  )
}

export default Index
