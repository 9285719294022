import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Input from '../../../components/panelcontrols/panelinput'
import TextArea from '../../../components/panelcontrols/paneltextarea'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import Select from '../../../components/panelcontrols/panelselect'
import './index.css'
import Table from './productTable'
import Edit from './edit'
import Functions from './functions'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const [productname,setProductName] = useState('');
  const [productremarks,setProductRemarks] = useState('');
  const [servicename,setServiceName] = useState('');
  const [serviceproduct,setServiceProduct] = useState({value:0,name:'Select product'});
  const [serviceproductlist,setServiceProductList] = useState([]);
  const [serviceremarks,setServiceRemarks] = useState('');
  const [list,setList] = useState([]);
  const [selectedService,setSelectedService] = useState(null); 
  const [showServiceEdit,setShowServiceEdit] = useState(false); 
  const CreateProductList = (data)=>{
    var sendData = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      sendData.push({
        value: parseInt(element.id),
        name: element.name
      });
    }
    setServiceProductList(sendData);
  }
  useEffect(()=>{
    Functions.GetProduct((data)=>{
      CreateProductList(data);
    })
    Functions.GetProductService(setList)
  },[])
  const resetProduct = ()=>{
    setProductName('');
    setProductRemarks('');
  }
  const resetServcie = ()=>{
    setServiceName('');
    setServiceProduct({value:0,name:'Select product'});
    setServiceRemarks('');
  }
  return (
    <div className='labadmin_products_container'>
      <Title Value={'Products'}/>
      <div className='labadmin_product_row'>
        <div className='labadmin_product_col50'>
            <div className='labadmin_product_formbox'>
                <h3>New product category</h3>
                <Input Lable={'Name'} Type={'text'} Value={productname} setValue={setProductName}/>
                <TextArea Lable={'Remarks'} Type={'text'} Value={productremarks} setValue={setProductRemarks}/>
                <Button Value={'Save'} Action={()=>{
                  const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                  if(productname === ''){
                    toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                    return;
                  }
                  Functions.PostProduct({
                    name: productname,
                    remarks: productremarks
                  },CreateProductList,toast,toastid,resetProduct)
                }}/>
            </div>
        </div>
        <div className='labadmin_product_col50'>
            <div className='labadmin_product_formbox'>
                <h3>New service</h3>
                <Input Lable={'Name'} Type={'text'} Value={servicename} setValue={setServiceName}/>
                <Select Lable={'Product category'} Value={serviceproduct} setValue={setServiceProduct} Options={serviceproductlist}/>
                <TextArea Lable={'Remarks'} Type={'text'} Value={serviceremarks} setValue={setServiceRemarks}/>
                <Button Value={'Save'} Action={()=>{
                  const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                  if(servicename === '' || serviceproduct.value === 0){
                    toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                    return;
                  }
                  Functions.PostProductService({
                    name: servicename,
                    product: serviceproduct.value,
                    remarks: serviceremarks
                  },setList,toast,toastid,resetServcie)
                }}/>
            </div>
        </div>
      </div>
      <Table List={list} setList={setList} setSelectedService={setSelectedService} setShowServiceEdit={setShowServiceEdit} toast={toast}/>
      {showServiceEdit &&
        <Edit serviceproductlist={serviceproductlist} selectedService={selectedService} setSelectedService={setSelectedService} toast={toast} setList={setList} setShowServiceEdit={setShowServiceEdit}/>
      }
      <ToastContainer />
    </div>
  )
}

export default Index
