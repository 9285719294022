import ApiUrl from "../../../configTools/api";

const Post = (body,responce) => {
    fetch(ApiUrl.Public_Subscriber,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        responce(data);
    });
}

export default {Post};