import React from 'react'
import { Link } from 'react-router-dom'
import Primarybtn from '../../../components/form/primarybtn'
import Logo from '../../../images/public/websitewhite.png'
import { HashLink } from 'react-router-hash-link'

const Web = () => {
    const NavList = [
        {name: "Home", url: "/",dropdown:[]},
        {name: "Contact us", url: "/contant", dropdown: [
            {name: "USA Office", url: "/contant"},
            {name: "LHR Office", url: "/contant"},
            {name: "FSD Office", url: "/contant"}
        ]},
        {name: "Our services", url: "/services",dropdown:[
            {name: "Digital", url: "/services", dropdown:[
                {name: "Cad-Cam", url: "/services"},
                {name: "Digital Crown & Bridge", url: "/services"},
                {name: "Digital Implantology", url: "/services"},
                {name: "Digital Impressions", url: "/services"},
                {name: "Digital Prosthetics", url: "/services"},
                {name: "Digital Veneers", url: "/services"},
                {name: "Digital Study Models", url: "/services"},
                {name: "Digital Smile Design", url: "/services"}
            ]},
            {name: "Implantology", url: "/services#implants", dropdown:[
                {name: "Implant Crown & Bridge", url: "/services#implants"},
                {name: "Implant Prosthetics", url: "/services#implants"},
                {name: "Implant Milled Bars", url: "/services#implants"},
                {name: "Immediat Loading Implant", url: "/services#implants"},
                {name: "Surgical Guide", url: "/services#implants"}
            ]},
            {name: "Crown & Bridge", url: "/services#cnb", dropdown:[
                {name: "Zirconia", url: "/services#cnb"},
                {name: "PFM", url: "/services#cnb"},
                {name: "IPSE-Max", url: "/services#cnb"},
                {name: "Composite", url: "/services#cnb"},
                {name: "Full Metal Crown", url: "/services#cnb"},
                {name: "Diagnostic Wexup", url: "/services#cnb"},
                {name: "PMMA", url: "/services#cnb"}
            ]},
            {name: "N-Visalign", url: "/services"},
            {name: "Snap-on Smile", url: "/services"},
            {name: "Prosthetics (R-P-D & C-P-D)", url: "/services", dropdown:[
                {name: "Flexible Dature", url: "/services"},
                {name: "CO-CR", url: "/services"},
                {name: "Acrylic Denture", url: "/services"},
                {name: "Soft Relining", url: "/services"},
                {name: "Denture Repairs", url: "/services"}
            ]},
            {name: "Orthodontics", url: "/services#orthodontics", dropdown:[
                {name: "Gum Shields", url: "/services#orthodontics"},
                {name: "Whiteing Trays", url: "/services#orthodontics"},
                {name: "Night Guard", url: "/services#orthodontics"},
                {name: "Aligner", url: "/services#orthodontics"},
                {name: "Zirconia Retainer", url: "/services#orthodontics"},
                {name: "Essix Retainer", url: "/services#orthodontics"},
                {name: "Hawley Retainers", url: "/services#orthodontics"}
            ]}
        ]},
        {name: "FAQs", url: "/faq",dropdown:[]},
        {name: "About", url: "/about",dropdown:[]}
    ]
  return (
    <div className='public_header_wrapper colorprimarybg'>
      <div className='public_header_container'>
        <div className='public_header_row'>
            <div className='public_header_logonav'>
                <div className='public_header_logo'>
                    <Link to={'/'}>
                        <img src={Logo}/>
                    </Link>
                </div>
                <div className='public_header_nav'>
                    {
                        NavList.map((item,index)=>{
                            return(
                                <Link to={item.url} key={index}><WebItem Name={item.name}  List={item.dropdown}/></Link>
                            )
                        })
                    }
                </div>
            </div>
            <div className='public_header_buttons'>
                <div className='public_header_nav'>
                    <Link to={'/login'} ><WebItem Name={'Login'} List={[]}/></Link>
                </div>
                <Primarybtn Value={'Get in touch'} Action={()=>{
                    window.location.replace('/contant')
                }}/>
            </div>
        </div>
      </div>
    </div>
  )
}

const WebItem = ({Name,List})=>{
    return(
        <div className='public_header_item_wrapper'>
            <div className='public_header_item_container'>
                <div className='public_header_item_text'>
                    <p className='colorwhite'>{Name}</p>
                </div>
                <div className='public_header_item_bar colorwhitebg'></div>
            </div>
            {List.length !== 0 &&
                <>
                    <div className='public_header_item_list_container'>
                        {List.map((item,index)=>{
                            return(
                                <HashLink key={index} to={item.url}>
                                    <div className='public_header_list_item_wrapper'>
                                        <div className='public_header_item_container'>
                                            <div className='public_header_item_text'>
                                                <p className='colorprimary'>{item.name}</p>
                                            </div>
                                            <div className='public_header_item_bar colorprimarybg'></div>
                                        </div>
                                        {item.dropdown &&
                                            <div className='public_header_item_sublist_container'>
                                                {
                                                    item.dropdown.map((subnavitem,index2)=>{
                                                        return(
                                                            <HashLink key={index2} to={subnavitem.url}>
                                                                <div className='public_header_item_container'>
                                                                    <div className='public_header_item_text'>
                                                                        <p className='colorprimary'>{subnavitem.name}</p>
                                                                    </div>
                                                                    <div className='public_header_item_bar colorprimarybg'></div>
                                                                </div>
                                                            </HashLink>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                </HashLink>
                            )
                        })}
                    </div>
                </>
            }
        </div>
    )
}

export default Web
