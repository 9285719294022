import React from 'react'
import ImageA from '../../../images/public/19.jpg'
import ImageB from '../../../images/public/20.webp'

const DentalImplants = () => {
  return (
    <div className='public_servic_dentalimplants_wrapper colorprimarybg' id='implants'>
      <div className='public_servic_dentalimplants_container'>
        <div className='public_servic_dentalimplants_row'>
            <div className='public_servic_dentalimplants_images'>
                <div className='public_servic_dentalimplants_imageA' style={{backgroundImage:`url(${ImageA})`}}></div>
                <div className='public_servic_dentalimplants_imageB' style={{backgroundImage:`url(${ImageB})`}}></div>
            </div>
            <div className='public_servic_dentalimplants_description'>
                <h3 className='colorwhite'>Dental Implants</h3>
                <p className='colorwhite'>
                    Dental Implants are medical devices surgically implanted into the jaw to restore a person's ability to chew or their appearance. They provide support for artificial teeth, such as crowns, bridges, or dentures.<br></br>
                    Dental implant systems consist of a dental implant body and dental implant abutment and may also include an abutment fixation screw.
                </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default DentalImplants
