import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Input from '../../../components/panelcontrols/panelinput'
import Select from '../../../components/panelcontrols/panelselect'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import DoctorFunctions from '../doctors/functions'
import './index.css'
import Functions from './functions'
import { ToastContainer, toast } from 'react-toastify';
import Table from './billingTable'

const Index = () => {
  const [list,setList] = useState([]);
  const [doctor,setDoctor] = useState({value:0,name:'Select doctor'});
  const [doctorlist,setDoctorList] = useState([]);
  const [month,setMonth] = useState({value:0,name:'Select month'});
  const [monthlist,setMonthList] = useState([
    {value:1,name:'January'},
    {value:2,name:'February'},
    {value:3,name:'March'},
    {value:4,name:'April'},
    {value:5,name:'May'},
    {value:6,name:'June'},
    {value:7,name:'July'},
    {value:8,name:'August'},
    {value:9,name:'September'},
    {value:10,name:'October'},
    {value:11,name:'November'},
    {value:12,name:'December'},
  ]);
  const [expiredate,setExpireDate] = useState('');
  useEffect(()=>{
    DoctorFunctions.Get((data)=>{
      const sendData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        sendData.push({
          value : parseInt(element.id),
          name : element.firstname + " " + element.lastname + " / " + element.clinicname
        })
      }
      setDoctorList(sendData)
    })
    Functions.Get(setList)
  },[])
  const reset = ()=>{
    setDoctor({value:0,name:'Select doctor'})
    setMonth({value:0,name:'Select month'})
    setExpireDate('');
  }
  return (
    <div className='labadmin_billing_container'>
      <Title Value={'Billing'}/> 
      <div className='admin_billing_formbox'>
        <Select Lable={'Doctor'} Value={doctor} setValue={setDoctor} Options={doctorlist}/>
        <Select Lable={'Month'} Value={month} setValue={setMonth} Options={monthlist}/>
        <Input Lable={'Due Date'} Type={'date'} Value={expiredate} setValue={setExpireDate}/>
        <Button Value={'Save'} Action={()=>{
          const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
          if(doctor.value === 0 || month.value === 0 || expiredate === ''){
            toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            return;
          }
          Functions.Post({
            doctor: doctor.value, 
            month : month.value,
            duedate: expiredate
          },setList,toast,toastid,()=>{
            reset();
            window.open(`/labadminreport/billingdetailed?doctor=${doctor.value}&month=${month.value}&duedate=${expiredate}`);
          })
        }} />
      </div>
      <Table List={list} setList={setList} toast={toast}/>
      <ToastContainer/>
    </div>
  )
}

export default Index
