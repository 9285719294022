import React from 'react'

const Primaryinput = ({Lable, Type, Value, setInput, onkeyPress}) => {
  return (
    <div className='public_primary_input_wrapper'>
      {Lable &&
        <label>{Lable}</label>
      }
      <div className='public_primary_input_container'>
        <input 
          type={Type}
          value={Value}
          placeholder={Lable} 
          onChange={(e)=> setInput(e.target.value)}
          onKeyPress={onkeyPress}
        />
      </div>
    </div>
  )
}

export default Primaryinput
