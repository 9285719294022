import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import Input from '../../../components/panelcontrols/panelinput'
import TextArea from '../../../components/panelcontrols/paneltextarea'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import './index.css'
import CitySelector from './citySelector'
import Functions from './funtions'
import Table from './labTable'

const Index = () => {
    const [name,setName] = useState('');
    const [location,setLocation] = useState('');
    const [postalcode,setPostalCode] = useState('');
    const [contact,setContact] = useState('');
    const [remarks,setRemarks] = useState('');
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [firstname,setFirstname] = useState('');
    const [lastname,setLastname] = useState('');
    const [email,setEmail] = useState('');
    const [city,setCity] = useState({value:0, name:'Select city'});
    const [list,setList] = useState([]);
    useEffect(()=>{
        Functions.Get(setList)
    },[])
  return (
    <div className='admin_labs_container'>
        <Title Value={'Labs'}/>
        <div className='admin_labs_formbox'>
            <div className='admin_labs_row'>
                <div className='admin_labs_col35'>
                    <h3>User information</h3>
                    <Input Lable={'First name'} Type={'text'} Value={firstname} setValue={setFirstname}/>
                    <Input Lable={'Last name'} Type={'text'} Value={lastname} setValue={setLastname}/>
                    <Input Lable={'Email'} Type={'text'} Value={email} setValue={setEmail}/>
                    <Input Lable={'Username'} Type={'text'} Value={username} setValue={setUsername}/>
                    <Input Lable={'Password'} Type={'text'} Value={password} setValue={setPassword}/>
                </div>
                <div className='admin_labs_col65'>
                    <h3>LAB information</h3>
                    <div className='admin_labs_row'>
                        <div className='admin_labs_col50'>
                            <Input Lable={'Name'} Type={'text'} Value={name} setValue={setName}/>
                        </div>
                        <div className='admin_labs_col50'>
                            <Input Lable={'Contact'} Type={'text'} Value={contact} setValue={setContact}/>
                        </div>
                    </div>
                    <Input Lable={'Postal code'} Type={'text'} Value={postalcode} setValue={setPostalCode}/>
                    <Input Lable={'Address'} Type={'text'} Value={location} setValue={setLocation}/>
                    <CitySelector city={city} setCity={setCity}/>
                    <TextArea Lable={'Remarks'} Type={'text'} Value={remarks} setValue={setRemarks}/>
                </div>
            </div>
            <Button Value={'Save'} Action={()=>{
                if(
                    firstname === ''|| 
                    lastname === '' || 
                    email === '' || 
                    username === '' || 
                    password === '' || 
                    name === ''|| 
                    city.value === 0
                ){
                    alert('Fields Empty');
                    return;
                }
                Functions.Post({
                    firstname:firstname,
                    lastname: lastname,
                    email: email,
                    username: username,
                    password:password,
                    name: name,
                    location: location,
                    postalcode: postalcode,
                    contact: contact,
                    remarks: remarks,
                    city:city.value
                },(data)=>{
                    setList(data);
                })
            }}/>
        </div>
        <div className='admin_labs_divider'></div>
        <Table List={list}/>
    </div>
  )
}

export default Index
