import React,{useState,useEffect} from 'react'

const Index = ({List,setList,id,image,imagea}) => {
    const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(List.some(item => item.id === id));
  }, [List, id]);

  return (
    <button
      className={`labadmin_orders_new_lineitems_add_units_upper_item unit_item_${id}`}
      onClick={() => {
        const updatedList = isActive
          ? List.filter(item => item.id !== id)
          : [...List, { id }];
        setList(updatedList);
      }}
    >
      {isActive ? <img src={imagea} alt="tooth" /> : <img src={image} alt="tooth" />}
    </button>
  );
}

export default Index
