import React,{useState,useEffect} from 'react'
import Functions from '../function'
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import './index.css'

const Index = ({User}) => {
    const [list,setList] = useState([]);
    const [message,setMessage] = useState('');
    useEffect(()=>{
      Functions.Get(User,setList);
    },[User])
  return (
    <div className='labadmin_messages_container'>
      <div className='labadmin_messages_items'>
        {
          list.map((item,index)=>{
            return(
              <div key={index}>
                {parseInt(item.type) === 0 &&
                  <div className='labadmin_messages_items_mymessage'>
                    <p>{item.description}</p>
                  </div>
                }
                {parseInt(item.type) === 1 &&
                  <div className='labadmin_messages_items_othermessage'>
                    <p>{item.description}</p>
                  </div>
                }
              </div>
            )
          })
        }
      </div>
      <div className='labadmin_messages_chatbox'>
        <input 
          type="text"
          placeholder="Enter your message"
          value={message} 
          onChange={(e)=>setMessage(e.target.value)}
        />
        <button onClick={()=>{
          const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
          if(message === ''){
            toast.update(toastid, { render: "Message is empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            return;
          }
          Functions.Post({
            description : message,
            user: User
          },setList,toast,toastid,()=>{
            setMessage("");
          })
        }}><FontAwesomeIcon icon={faPaperPlane}/></button>
        
      </div>
      <ToastContainer/>
    </div>
  )
}

export default Index
