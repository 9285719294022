import React from 'react'
import './index.css'
import { faRightFromBracket,faBoxesStacked, faEnvelope, faHouse, faUser, faXmark } from '@fortawesome/free-solid-svg-icons'
import Item from './item'
import Watermark from './watermark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Index = ({mobileopen,setMobileOpen}) => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    const navList = [
        {name: 'Dashboard', url: '/doctor/dashboard', icon:faHouse, slug: 'dashboard', subnav: []},
        {name: 'Orders', url: '/doctor/orders', icon:faBoxesStacked, slug: 'orders', subnav: []},
        {name: 'Messages', url: '/doctor/messages', icon:faEnvelope, slug:'messages', subnav:[]}
    ]
  return (
    <div className={mobileopen?'admin_layout_leftbar_container active':'admin_layout_leftbar_container'}>
        <div className='admin_layout_leftbar_mobile_close'>
            <button onClick={()=>{
                setMobileOpen(false)
            }}>
                <FontAwesomeIcon icon={faXmark}/>
            </button>
        </div>
      <div className='admin_layout_leftbar_logout'>
          <Link to={'/signout'}>
              <div className='admin_layout_leftbar_logout_container'>
                  <div className='admin_layout_leftbar_logout_lable'>
                      <p>Logout</p>
                  </div>
                  <div className='admin_layout_leftbar_logout_icon'>
                      <FontAwesomeIcon icon={faRightFromBracket}/>
                  </div>
              </div>
          </Link>
      </div>
      <div className='admin_layout_leftbar_user_container'>
          <div className='admin_layout_leftbar_user_image_wrapper'>
              <div className='admin_layout_leftbar_user_image_container'>
                  <FontAwesomeIcon icon={faUser}/>
              </div>
          </div>
          <div className='admin_layout_leftbar_user_details'>
              <h4>{User.username}</h4>
              <p>{User.role}</p>
          </div>
      </div>
      <div className='admin_layout_leftbar_navigations'>
        {
            navList.map((item,index)=>{
                return(
                    <div key={index}>
                        <Item Item={item}/>
                    </div>
                )
            })
        }
      </div>
      <Watermark />
    </div>
  )
}

export default Index
