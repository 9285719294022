import React,{useState,useEffect} from 'react'
import Web from './web'
import ScreenSizes from '../../../configTools/screensizes';
import Mobile from './mobile';

const Index = () => {
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

  	function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}
  
  	useEffect(() => {
    		const updateDimension = () => {
      			setScreenSize(getCurrentDimension())
    		}
    		window.addEventListener('resize', updateDimension);
    
		
    		return(() => {
        		window.removeEventListener('resize', updateDimension);
    		})
  	}, [screenSize])
  return (
    <>
    {screenSize.width > ScreenSizes.Mobile &&
        <Web/>
    }
    {screenSize.width <= ScreenSizes.Mobile &&
        <Mobile/>
    }
    </>
  )
}

export default Index
