import React,{useState,useEffect} from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import DeleteButton from '../../../../../components/panelcontrols/paneltablebuttons/deletebutton'
import EditButton from '../../../../../components/panelcontrols/paneltablebuttons/editbutton'
import Functions from '../function'

const Index = ({List,setList,setSelectedPayment,setShowEdit,toast}) => {
  const [filterList,setfilterList] = useState(List);
  function filterFunction(value){
    if(value === ""){
      setfilterList(List);
    }
    else{
      const filtedList = List.filter((obj)=>{
        return Object.values(obj)
        .join(" ").toLowerCase()
        .includes(value.toLowerCase());
      })
      setfilterList(filtedList);
    }
  }
  useEffect(()=>{
      setfilterList(List);
  },[List])
  return (
    <div className='user_table_wrapper'>
      <div className='user_table_search'>
        <input onChange={(e)=>{filterFunction(e.target.value);}}/>
        <div className='user_table_search_icon'>
          <FontAwesomeIcon icon={faSearch}/>
        </div>
      </div>
      <div className='user_table_container'>
        <div className='_table'>
          <div className='_thead'>
            <div className='_tr'>
              <div className='_th' style={{width:"15%"}}>Date</div>
              <div className='_th' style={{width:"20%"}}>Doctor</div>
              <div className='_th' style={{width:"30%"}}>Description</div>
              <div className='_th' style={{width:"15%"}}>Amount</div>
              <div className='_th' style={{width:"20%"}}></div>
            </div>
          </div>
          <div className='_tbody'>
            {filterList.length > 0 &&
              filterList.map((item,index)=>{
                return(
                  <div className='_tr' key={index}>
                    <div className='_td' style={{width:"15%"}}>{item.date.split('',10)}</div>
                    <div className='_td' style={{width:"20%"}}>{item.doctorname}</div>
                    <div className='_td' style={{width:"30%"}}>{item.description}</div>
                    <div className='_td' style={{width:"15%"}}>{item.amount}</div>
                    <div className='_td' style={{width:"20%",display:"flex", justifyContent:"space-evenly"}}>
                      <EditButton Action={()=>{
                        setSelectedPayment(item);
                        setShowEdit(true);
                      }}/>
                      <DeleteButton Action={()=>{
                        const confermation = window.confirm('Are you sure you want to delete this payment?');
                        if(!confermation){
                          return;
                        }
                        const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                        Functions.Delete(item.id,setList,toast,toastid)
                      }}/>
                    </div>
                  </div>
                )
              })
            }
            {filterList.length <= 0 &&
              <div className='table_nodata'>
                <p>No data available</p>
              </div>
            }
          </div>
        </div>
      </div>      
    </div>
  )
}

export default Index
