import React, { useState } from 'react'
import Primaryinput from '../../../components/form/primaryinput'
import Primaryaltbtn from '../../../components/form/primaryaltbtn';
import Functions from './functions';

const Form = () => {
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
  return (
    <div>
        <div className='public_header_complete colorprimarybg'></div>
        <div className='public_login_wrapper'>
        <div className='public_login_container colorprimarybg'>
            <div className='public_login_form colorsecondarybg'>
                <h3>Login to continue</h3>
                <Primaryinput Lable={'Username'} Type={'text'} Value={username} setInput={setUsername} onkeyPress={(event)=>{
                    if(event.key === 'Enter'){
                        Functions.Submit({
                            username: username,
                            password: password
                        });
                    }
                }}/>
                <Primaryinput Lable={'Password'} Type={'password'} Value={password} setInput={setPassword} onkeyPress={(event)=>{
                    if(event.key === 'Enter'){
                        Functions.Submit({
                            username: username,
                            password: password
                        });
                    }
                }}/>
                <Primaryaltbtn Value={'Login'} Action={()=>{
                    Functions.Submit({
                        username: username,
                        password: password
                    });
                }}/>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Form
