import React from 'react'
import Hero from './hero'
import Items from './items'
import '../../../css/public/pages/faqs.css'

const Index = () => {
  return (
    <div>
      <Hero/>
      <Items/>
    </div>
  )
}

export default Index
