import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Primarybtn from '../../../components/form/primarybtn'
import Logo from '../../../images/public/websitewhite.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

const Mobile = () => {
    const [nav,setNav] = useState(false);
    const NavList = [
        {name: "Contact", url: "/contant"},
        {name: "Services", url: "/services"},
        {name: "FAQs", url: "/faq"},
        {name: "About", url: "/about",dropdown:[]},
        {name: "Login", url:"/login"}
    ]
    /*const RelocateFun = (url)=>{
        window.location.replace(url);
    }*/
  return (
    <>
    <div className={'public_header_mobile_wrapper colorprimarybg'}>
        <div className='public_header_mobile_container'>
            <div className='public_header_mobile_row'>
                <div className='public_header_mobile_logo'>
                    <a href={'/'}>
                        <img src={Logo}/>
                    </a>
                </div>
                <div className='public_header_mobile_navopener'>
                    <button onClick={()=>{setNav(true)}}>
                    <FontAwesomeIcon icon={faBars}/>
                    </button>
                </div>
            </div>
        </div>
    </div>
    {nav && 
    <div className='public_header_mobile_nav_wrapper colorprimarybg'>
        <div className='public_header_mobile_nav_container'>
            <div className='public_header_mobile_nav_items'>
                {NavList.map((item,index)=>{
                    return(
                        <a href={item.url} key={index}>
                            <p className='colorwhite'>{item.name}</p>
                        </a>
                    )
                })
                }
            </div>
            <div className='public_header_mobile_nav_button'>
                <Primarybtn Value={'Get in touch'} />
            </div>
            <div className='public_header_mobile_nav_close'>
                <button onClick={()=>{setNav(false)}}><FontAwesomeIcon icon={faXmark}/></button>
            </div>
        </div>
    </div>
    }
    </>
  )
}

export default Mobile
