import React, { useEffect, useState } from 'react'
import StatusFunctions from '../../orders/status/functions'
import './index.css'

const Index = ({selectedStatus,setSelectedStatus}) => {
    const [statusList,setStatusList] = useState([])
    useEffect(()=>{
        StatusFunctions.Get(setStatusList);
    },[])
  return (
    <div className='labadmin_dashboard_orderstatusselector_container'>
        <button className={parseInt(selectedStatus) === 0 ? 'active': ''} onClick={()=>{
            setSelectedStatus(parseInt(0));
        }}>All</button>
      {
        statusList.map((item,index)=>{
            return(
                <button className={parseInt(item.id) === parseInt(selectedStatus) ? 'active': ''} key={index} onClick={()=>{
                    setSelectedStatus(parseInt(item.id));
                }}>{item.name}</button>
            )
        })
      }
    </div>
  )
}

export default Index
