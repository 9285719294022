import { faPlus,faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './index.css'

const Index = ({tasks,setTasks,setPage}) => {
  return (
    <div className='labadmin_orders_new_lineitems_container'>
      <div className='labadmin_orders_new_lineitems_add'>
        <p>Tasks</p>
        <button onClick={()=>{
          let note = prompt("Please enter a task", "");

          if (note !== null && note !== '') {
            const sendData = [];
            for (let index = 0; index < tasks.length; index++) {
              const element = tasks[index];
              sendData.push(element);
            }
            sendData.push(note);
            setTasks(sendData)
          }
        }}>
          <FontAwesomeIcon icon={faPlus}/>
        </button>
      </div>
      {tasks.length > 0 &&
      <div className='labadmin_orders_new_lineitems_list'>
        <div className='_table'>
          <div className='_tbody'>
              {
                tasks.map((item,index)=>{
                  return(
                    <div className='_tr' key={index}>
                      <div className='_td' style={{width:"90%"}}>{item}</div>
                      <div className='_td' style={{width:"10%"}}><FontAwesomeIcon icon={faTrashCan} onClick={()=>{
                        const sendData = [];
                        for (let index2 = 0; index2 < tasks.length; index2++) {
                          const element = tasks[index2];
                          if(index !== index2){
                            sendData.push(element);
                          }
                        }
                        setTasks(sendData)
                      }}/></div>
                    </div>
                  )
                })
              }
          </div>
        </div>
      </div>
      }
    </div>
  )
}

export default Index
