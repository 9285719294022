import React,{useState,useEffect} from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import Input from '../../../../components/panelcontrols/panelinput'
import TextArea from '../../../../components/panelcontrols/paneltextarea'
import Select from '../../../../components/panelcontrols/panelselect'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import Functions from './function'
import DoctorFunctions from '../../doctors/functions'
import { ToastContainer, toast } from 'react-toastify';
import './index.css'
import Table from './paymentsTable'
import Edit from './edit'

const Index = () => {
  const [description,setDescription] = useState('');
  const [doctor,setDoctor] = useState({value:0,name:'Select doctor'});
  const [doctorlist,setDoctorList] = useState([]);
  const [amount,setAmount] = useState(0);
  const [date,setDate] = useState('');
  const [list,setList] = useState([]);
  const [selectedPayment,setSelectedPayment] = useState(null); 
  const [showEdit,setShowEdit] = useState(false); 
  useEffect(()=>{
    Functions.Get(setList)
    DoctorFunctions.Get((data)=>{
      const sendData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        sendData.push({
          value : parseInt(element.id),
          name : element.firstname + " " + element.lastname + " / " + element.clinicname
        })
      }
      setDoctorList(sendData)
    })
  },[])
  return (
    <div className='labadmin_billing_container'>
      <Title Value={'Payments'}/>
      <div className='admin_billing_formbox'>
        <div className='admin_billing_row'>
          <div className='admin_billing_col33'>
            <Select Lable={'Doctor'} Value={doctor} setValue={setDoctor} Options={doctorlist}/>
          </div>
          <div className='admin_billing_col33'>
            <Input Lable={'Date'} Type={'date'} Value={date} setValue={setDate}/>
          </div>
          <div className='admin_billing_col33'>
            <Input Lable={'Amount'} Type={'number'} Value={amount} setValue={setAmount}/>
          </div>
        </div>
        <TextArea Lable={'Description'} Type={'text'} Value={description} setValue={setDescription}/>
        <Button Value={'Save'} Action={()=>{
            const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
            if(doctor.value === 0 || amount === 0 || date === ''){
              toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
              return;
            }
            Functions.Post({
              doctor: doctor.value,
              amount: amount,
              date: date,
              description: description
            },setList,toast,toastid,()=>{})
        }}/>
      </div>
      <Table List={list} setList={setList} setSelectedPayment={setSelectedPayment} setShowEdit={setShowEdit} toast={toast}/>
      {showEdit &&
        <Edit selectedPayment={selectedPayment} setSelectedPayment={setSelectedPayment} toast={toast} setList={setList} setShowEdit={setShowEdit}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default Index
