import React from 'react'

const Primarybtn = ({Value,Action}) => {
  return (
    <div className='public_primary_btn'>
      <button onClick={Action}>
        <div className='public_primary_btn_container'>
            <div className='public_primary_btn_text'>
                <p>{Value}</p>
            </div>
            <div className='public_primary_btn_background'></div>
        </div>
      </button>
    </div>
  )
}

export default Primarybtn
