import React from 'react'
import ImageA from '../../../images/public/23.avif'
import ImageB from '../../../images/public/24.webp'
import ImageBg from '../../../images/public/08.png'

const Laminates = () => {
  return (
    <div className='public_servic_dentalimplants_wrapper colorprimarybg'>
      <div className='public_servic_dentalimplants_container'>
        <div className='public_servic_dentalimplants_row'>
            <div className='public_servic_dentalimplants_images'>
                <div className='public_servic_dentalimplants_imageA' style={{backgroundImage:`url(${ImageA})`}}></div>
                <div className='public_servic_dentalimplants_imageB' style={{backgroundImage:`url(${ImageB})`}}></div>
            </div>
            <div className='public_servic_dentalimplants_description'>
                <h3 className='colorwhite'>Veneers</h3>
                <p className='colorwhite'>
                Veneers are thin coverings that are placed over the front part of your tooth that look like natural teeth. Veneers can be used to correct a wide range of dental issues, included stained, chipped, crooked or damaged teeth. They can even help conceal uneven spaces between teeth.
                </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Laminates
