import ApiUrl from "../../../configTools/api";
const Submit = async (body)=>{
    let res = await fetch(ApiUrl.OAuth_Login, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    res = await res.json();
    if(res.success){
        localStorage.setItem("user-info",JSON.stringify(res.responce));
        document.location.reload();
    }
    else{
        alert(res.responce);
    }
}

export default {Submit}