const BaseURL = 'https://nvisiondental.com/api/'//'https://nvisiondental.com/api/'//'/api/';//'http://localhost:4000/api/';

const ApiUrl = {
    OAuth_Login: BaseURL+'oauth/login',
    Public_Subscriber: BaseURL+'public/subscriber',
    Admin_Labs: BaseURL+'admin/labs',
    Admin_Users: BaseURL+'admin/users',
    Admin_Dashboard: BaseURL+'admin/dashboard',
    Admin_Location_Country: BaseURL+'admin/location/country',
    Admin_Location_State: BaseURL+'admin/location/state',
    Admin_Location_City: BaseURL+'admin/location/city',
    Admin_Subscriber: BaseURL+'admin/subscribers',
    LabAdmin_Product: BaseURL+'labadmin/product',
    LabAdmin_Product_Service: BaseURL+'labadmin/productservice',
    LabAdmin_Expense: BaseURL+'labadmin/expense',
    LabAdmin_Material_Type: BaseURL+'labadmin/materialtype',
    LabAdmin_Material: BaseURL+'labadmin/material',
    LabAdmin_Employee: BaseURL+'labadmin/employee',
    LabAdmin_Clinics: BaseURL+'labadmin/clinics',
    LabAdmin_Doctors: BaseURL+'labadmin/doctors',
    LabAdmin_Status_Order: BaseURL+'labadmin/statusorder',
    LabAdmin_Department_Order: BaseURL+'labadmin/departmentorder',
    LabAdmin_Orders: BaseURL+'labadmin/orders',
    LabAdmin_Payments: BaseURL+'labadmin/payments',
    LabAdmin_Messages: BaseURL+'labadmin/messages',
    LabAdmin_Billing: BaseURL+'labadmin/billing',
    LabAdminPrint_Label_Order: BaseURL+'labadminprints/label/order',
    LabAdminPrint_Jobcard_Order: BaseURL+'labadminprints/jobcard/order',
    LabAdminPrint_Jobcard_Billing: BaseURL+'labadminprints/billing',
    LabAdminPrint_Jobcard_BillingDetailed: BaseURL+'labadminprints/billingdetailed',
    LabAdminPrint_Jobcard_Salesbydate: BaseURL+'labadminprints/salesbydate',
    LabAdminPrint_Jobcard_Expensebydate: BaseURL+'labadminprints/expensebydate',
    LabAdminPrint_Profitandlossdate: BaseURL+'labadminprints/profitandloss',
    LabAdminPrint_Dailysales: BaseURL+'labadminprints/dailysales',
    LabAdminPrint_Salesrange: BaseURL+'labadminprints/salesrange',
    Doctor_Orders: BaseURL+'doctor/orders',
    Doctor_Status_Order: BaseURL+'doctor/statusorder',
    Doctor_Product: BaseURL+'doctor/product',
    Doctor_Product_Service: BaseURL+'doctor/productservice',
    Doctor_Messages: BaseURL+'doctor/messages',
    DoctorPrint_Label_Order: BaseURL+'doctorprints/label/order',
    DoctorPrint_Jobcard_Order: BaseURL+'doctorprints/jobcard/order'
}

export default ApiUrl