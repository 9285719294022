import React, {useState,useEffect} from 'react'
import Input from '../../../components/panelcontrols/panelinput'
import Select from '../../../components/panelcontrols/panelselect'
import TextArea from '../../../components/panelcontrols/paneltextarea'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import Functions from './functions'

const City = ({countryList,citySelectedCountry,setCitySelectedCountry,cityStateList,setCityList}) => {
    const [name,setName] = useState('');
    const [remarks,setRemarks] = useState('');
    const [selectCountryList,setSelectedCountryList] = useState([]);
    const [selectStateList,setSelectedStateList] = useState([]);
    const [selectedState,setSelectedState] = useState({value:0,name:'Select state'});
    useEffect(()=>{
        let dataSend = [];
        for (let index = 0; index < countryList.length; index++) {
            const country = countryList[index];
            dataSend.push({
                value: parseInt(country.id),
                name: country.name
            });
        }
        setSelectedCountryList(dataSend);
    },[countryList])
    useEffect(()=>{
        let dataSend = [];
        for (let index = 0; index < cityStateList.length; index++) {
            const state = cityStateList[index];
            dataSend.push({
                value: parseInt(state.id),
                name: state.name
            });
        }
        setSelectedStateList(dataSend);
    },[cityStateList])
  return (
    <>
        <Input Lable={'Name'} Type={'text'} Value={name} setValue={setName}/>
        <Select Lable={'Country'} Value={citySelectedCountry} setValue={setCitySelectedCountry} Options={selectCountryList}/>
        <Select Lable={'State'} Value={selectedState} setValue={setSelectedState} Options={selectStateList}/>
        <TextArea Lable={'Remarks'} Value={remarks} setValue={setRemarks}/>
        <Button Value={'Save'} Action={()=>{
            if(name === '' || selectedState.value === 0){
                alert('fields empty');
                return;
            }
            Functions.PostCity({
                name:name,
                state: selectedState.value,
                remarks:remarks
            },(data)=>{
                setCityList(data);
            });
        }}/>
    </>
  )
}

export default City
