import React from 'react'
import './index.css'

const Index = ({Count,Title}) => {
  return (
    <div className='admin_dashboard_box_wrapper'>
      <div className='admin_dashboard_box_container'>
        <h3>{Count}</h3>
        <p>{Title}</p>
      </div>
    </div>
  )
}

export default Index
