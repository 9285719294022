import React from 'react'
import Hero from './hero'
import Aligners from './aligners'
import '../../../css/public/pages/services.css'
import DentalImplants from './dentalImplants'
import CrownsBridges from './crownsbridges'
import Laminates from './laminates'
import Partialdenter from './Partialdenter'
import Aorthdontix from './aorthdontix'

const Index = () => {
  return (
    <div>
      <Hero/>
      <Aligners/>
      <DentalImplants/>
      <CrownsBridges/>
      <Laminates/>
      <Partialdenter/>
      <Aorthdontix/>
    </div>
  )
}

export default Index
