import React from 'react'
import ImageA from '../../../images/public/26.jpg'
import ImageB from '../../../images/public/27.jpg'

const CrownsBridges = () => {
  return (
    <div className='public_servic_aligners_wrapper colorsecondarybg'>
      <div className='public_servic_aligners_container'>
        <div className='public_servic_aligners_row'>
            <div className='public_servic_aligners_description'>
                <h3 className='colorprimary'>Removeable Dentures</h3>
                <p>
                Removable dentures, better known as false teeth, are prosthetic teeth made from material such as acrylic and metal, which fit over the gums and/or remaining teeth to give the appearance that the patient still has a full set of teeth.</p>
            </div>
            <div className='public_servic_aligners_images'>
                <div className='public_servic_aligners_imageA' style={{backgroundImage:`url(${ImageA})`}}></div>
                <div className='public_servic_aligners_imageB' style={{backgroundImage:`url(${ImageB})`}}></div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default CrownsBridges
