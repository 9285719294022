import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import './index.css'
import Table from './accountsTable'

const Index = () => {
    const [list,setList] = useState([]);
  return (
    <div className='labadmin_accounts_container'>
        <Title Value={'Accounts'}/>
        <Table List={list}/>
    </div>
  )
}

export default Index
