import React, {useState,useEffect} from 'react'
import Input from '../../../components/panelcontrols/panelinput'
import Select from '../../../components/panelcontrols/panelselect'
import TextArea from '../../../components/panelcontrols/paneltextarea'
import Button from '../../../components/panelcontrols/panelbuttonmain'
import Functions from './functions'

const State = ({countryList,citySelectedCountry,setCityStateList}) => {
    const [name,setName] = useState('');
    const [remarks,setRemarks] = useState('');
    const [selectCountryList,setSelectedCountryList] = useState([]);
    const [selectedCountry,setSelectedCountry] = useState({value:0,name:'Select country'});
    useEffect(()=>{
        let dataSend = [];
        for (let index = 0; index < countryList.length; index++) {
            const country = countryList[index];
            dataSend.push({
                value: parseInt(country.id),
                name: country.name
            });
        }
        setSelectedCountryList(dataSend);
    },[countryList])
  return (
    <>
        <Input Lable={'Name'} Type={'text'} Value={name} setValue={setName}/>
        <Select Lable={'Country'} Value={selectedCountry} setValue={setSelectedCountry} Options={selectCountryList}/>
        <TextArea Lable={'Remarks'} Value={remarks} setValue={setRemarks}/>
        <Button Value={'Save'} Action={()=>{
            if(name === ''){
                alert('fields empty');
                return;
            }
            Functions.PostState({
                name:name,
                country: selectedCountry.value,
                remarks:remarks
            },citySelectedCountry.value,(data)=>{
                setCityStateList(data);
            });
        }}/>
    </>
  )
}

export default State
