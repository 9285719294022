import React,{useState,useEffect} from 'react'
import Title from '../../../../components/panelcontrols/paneltitle'
import Input from '../../../../components/panelcontrols/panelinput'
import TextArea from '../../../../components/panelcontrols/paneltextarea'
import Button from '../../../../components/panelcontrols/panelbuttonmain'
import './index.css'
import City from './citySelector'
import Table from './clinicsTable'
import Functions from './function'
import { ToastContainer, toast } from 'react-toastify';
import Edit from './edit'

const Index = () => {
  const [name,setName] = useState('');
  const [contact,setContact] = useState('');
  const [address,setAddress] = useState('');
  const [city,setCity] = useState({value:0,name:'Select city'});
  const [list,setList] = useState([]);
  const [selectedClinic,setSelectedClinic] = useState(null); 
  const [showEdit,setShowEdit] = useState(false); 
  useEffect(()=>{

    Functions.Get(setList);
  },[])
  const reset = ()=>{
    setName('');
    setAddress('')
    setContact('')
  }
  return (
    <div className='labadmin_doctors_clinics_container'>
      <Title Value={'Clinics'}/>
      <div className='labadmin_doctors_clinics_formbox'>
        <div className='labadmin_doctors_clinics_row'>
          <div className='labadmin_doctors_clinics_col50'>
            <Input Lable={'Name'} Type={'text'} Value={name} setValue={setName}/>
          </div>
          <div className='labadmin_doctors_clinics_col50'>
            <Input Lable={'Contact'} Type={'text'} Value={contact} setValue={setContact}/>
          </div>
        </div>
        <City city={city} setCity={setCity} />
        <TextArea Lable={'Address'} Type={'text'} Value={address} setValue={setAddress}/>
        <Button Value={'Save'} Action={()=>{
          const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
          if(name === '' || city.value === 0){
            toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            return;
          }
          Functions.Post({
            name: name, 
            contact : contact,
            address : address,
            city : city.value
          },setList,toast,toastid,reset)
        }}/>
      </div>
      <Table List={list} setList={setList} setSelectedClinic={setSelectedClinic} setShowEdit={setShowEdit} toast={toast}/>
      {showEdit &&
        <Edit selectedClinic={selectedClinic} setSelectedClinic={setSelectedClinic} toast={toast} setList={setList} setShowEdit={setShowEdit}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default Index
