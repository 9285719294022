import React from 'react'
import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

const Index = () => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    if(!User){
        return <Navigate to={"/login"}/>
    }
    else{
        if(User.role !== 'doctor'){
            return <Navigate to={"/login"}/>
        }
    }
  return (
    <Outlet />
  )
}

export default Index
