import React,{useState,useEffect} from 'react'
import './index.css'
import Title from '../../../components/panelcontrols/paneltitle'
import Functions from './functions'
import Country from './country'
import State from './state'
import City from './city'
import CityTable from './cityTable'

const Index = () => {
  const [countryList,setCountryList] = useState([]);
  const [citySelectedCountry,setCitySelectedCountry] = useState({value:0,name:'Select country'});
  const [cityStateList,setCityStateList] = useState([]);
  const [cityList,setCityList] = useState([]); 
  useEffect(()=>{
    Functions.GetCountry(setCountryList)
    Functions.GetCity(setCityList)
  },[])
  useEffect(()=>{
    Functions.GetState(citySelectedCountry.value,(data)=>{
      setCityStateList(data);
    })
  },[citySelectedCountry])
  return (
    <div>
      <Title Value={'Locations'}/>
      <div className='admin_locations_container'>
        <div className='admin_locations_row'>
          <div className='admin_locations_w33'>
            <div className='admin_locations_formbox'>
              <h3>Add country</h3>
              <Country setValues={setCountryList}/>
            </div>
          </div>
          <div className='admin_locations_w33'>
            <div className='admin_locations_formbox'>
              <h3>Add state</h3>
              <State countryList={countryList} citySelectedCountry={citySelectedCountry} setCityStateList={setCityStateList}/>
            </div>
          </div>
          <div className='admin_locations_w33'>
            <div className='admin_locations_formbox'>
              <h3>Add city</h3>
              <City countryList={countryList} citySelectedCountry={citySelectedCountry} setCitySelectedCountry={setCitySelectedCountry} cityStateList={cityStateList} setCityList ={setCityList} />
            </div>
          </div>
        </div>
        <CityTable List={cityList} />
      </div>
    </div>
  )
}

export default Index
