import React,{useState} from 'react'
import Primaryinput from '../../../components/form/primaryinput'
import Primaryaltbtn from '../../../components/form/primaryaltbtn'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope,faPhone } from '@fortawesome/free-solid-svg-icons'
import Logo from '../../../images/public/websitefooter.png'
import { faLinkedin, faSquareFacebook, faSquareInstagram, faSquareXTwitter, faSquareYoutube } from '@fortawesome/free-brands-svg-icons'
import Functions from './functions'
import SubscriberMessage from './subscribermessage'

const Web = () => {
    const [email,setEmail] = useState('');
    const [emailPostRes,setEmailPostRes] = useState(null);
    const [showemailPostRes,setShowEmailPostRes] = useState(false);
    const NavList = [
        {name: "Contact", url: "/contant"},
        {name: "Services", url: "/services"},
        {name: "FAQs", url: "/faq"},
        {name: "About", url: "/about"}
    ]
  return (
    <div className='public_footer_wrapper colorsecondarybg'>
      <div className='public_footer_container'>
        <div className='public_footer_row'>
            <div className='public_footer_company'>
              <div className='public_footer_logo'>
                <Link to={'/'}>
                  <img src={Logo}/>
                </Link>
                <p style={{paddingRight:"10px"}}>
                Embrace Excellence with N-Vision Dental – Your Trusted Partner in Crafting Exceptional Dental Restorations.
                </p>
              </div>
            </div>
            {/*
            <div className='public_footer_nav'>
                <h3 className='colorprimary'>Map</h3>
                <div className='public_footer_nav_container'>
                  {
                    NavList.map((item,index)=>{
                        return(
                            <Link to={item.url} key={index}><FooterItem Name={item.name}/></Link>
                        )
                    })
                  }
                </div>
            </div>

  */}
            
            <div className='public_footer_contact'>
                <h3 className='colorprimary'>Get in touch</h3>
                <div className='public_footer_contact_item'>
                  <div className='public_footer_contact_item_icon'>
                    <FontAwesomeIcon icon={faEnvelope}/>
                  </div>
                  <div className='public_footer_contact_item_text'>
                    <p>info@nvisiondental.com</p>
                  </div>
                </div>
                {/*
                <div className='public_footer_contact_item'>
                  <div className='public_footer_contact_item_icon'>
                    <FontAwesomeIcon icon={faPhone}/>
                  </div>
                  <div className='public_footer_contact_item_text'>
                    <p>+92 (300) 460 - 1314</p>
                  </div>
                </div>
                */}
                <div className='public_footer_contact_item_social'>
                  <div className='public_footer_contact_item_icon'>
                    <Link to={'https://www.facebook.com/profile.php?id=100093019306708'}>
                      <FontAwesomeIcon icon={faSquareFacebook}/>
                    </Link>
                  </div>
                  <div className='public_footer_contact_item_icon'>
                    <Link to={'https://twitter.com/Nvisiondental'}>
                      <FontAwesomeIcon icon={faSquareXTwitter}/>
                    </Link>
                  </div>
                  <div className='public_footer_contact_item_icon'>
                    <Link to={'https://pk.linkedin.com/in/n-vision-dental-3b206b279'}>
                      <FontAwesomeIcon icon={faLinkedin}/>
                    </Link>
                  </div>
                  <div className='public_footer_contact_item_icon'>
                    <Link to={'https://www.instagram.com/nvisiondental/'}>
                      <FontAwesomeIcon icon={faSquareInstagram}/>
                    </Link>
                  </div>
                  <div className='public_footer_contact_item_icon'>
                    <Link to={'https://www.youtube.com/channel/UCqYMbk_6ktRqoDcNR1xxYtw'}>
                      <FontAwesomeIcon icon={faSquareYoutube}/>
                    </Link>
                  </div>
                </div>
            </div>
            <div className='public_footer_newsletter'>
                <h3 className='colorprimary'>Newsletter</h3>
                <div className='public_footer_newsletter_container'>
                    <p>Subscribe to receive updates, access to exclusive deals, and more.</p>
                    <Primaryinput Type={'text'} Value={email} setInput={setEmail} onkeyPress={(event)=>{
                      if(event.key === 'Enter'){
                        if(email === ''){
                          alert('Enter email !!')
                          return;
                        }
                        Functions.Post({
                          email: email
                        },(data)=>{
                          setEmailPostRes(data);
                          setShowEmailPostRes(true);
                          setEmail('');
                        })
                      }
                    }}/>
                    <Primaryaltbtn Value={'Subscribe'} Action={()=>{
                      if(email === ''){
                        alert('Enter email !!')
                        return;
                      }
                      Functions.Post({
                        email: email
                      },(data)=>{
                        setEmailPostRes(data);
                        setShowEmailPostRes(true);
                        setEmail('');
                      })
                    }}/>
                </div>
            </div>
        </div>
      </div>
      {showemailPostRes &&
        <SubscriberMessage ResData={emailPostRes} setShowEmailPostRes={setShowEmailPostRes}/>
      }
    </div>
  )
}

const FooterItem = ({Name})=>{
    return(
        <div className='public_footer_item_wrapper'>
          <div className='public_footer_item_container'>
            <div className='public_footer_item_text'>
              <p>{Name}</p>
            </div>
            <div className='public_footer_item_bar colorprimarybg'></div>
          </div>
        </div>
    )
}

export default Web
