import React, { useState } from 'react'
import Primaryinput from '../../../components/form/primaryinput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import Primaryaltbtn from '../../../components/form/primaryaltbtn';
import PrimarytextArea from '../../../components/form/primarytextArea';

const Form = () => {
    const [firstname,setFirstname] = useState('');
    const [lastname,setLastname] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [message,setMessage] = useState('');
  return (
    <div className='public_contact_form_wrapper'>
      <div className='public_contact_form_container'>
        <div className='public_contact_form_row'>
            <div className='public_contact_form_locations' id='contact_locations'>
                <div className='public_contact_form_locations_item'>
                    <div className='public_contact_form_locations_item_icon'>
                        <FontAwesomeIcon icon={faLocationDot}/>
                    </div>
                    <div className='public_contact_form_locations_item_details'>
                        <p>7561 Orr Rd., Charlotte, NC 28213, United States of America</p>
                        <p>+1 (845) 544 - 0100</p>
                        <p>usa@nvisiondental.com</p>
                    </div>
                </div>
                <div className='public_contact_form_locations_item'>
                    <div className='public_contact_form_locations_item_icon'>
                        <FontAwesomeIcon icon={faLocationDot}/>
                    </div>
                    <div className='public_contact_form_locations_item_details'>
                        <p>Johar Town, Lahore</p>
                        <p>+92 (333) 460 - 1314</p>
                        <p>lhr@nvisiondental.com</p>
                    </div>
                </div>
                <div className='public_contact_form_locations_item'>
                    <div className='public_contact_form_locations_item_icon'>
                        <FontAwesomeIcon icon={faLocationDot}/>
                    </div>
                    <div className='public_contact_form_locations_item_details'>
                        <p>P-369 Block B Street#2 Alnajaf Colony Faisalabad</p>
                        <p>+92 (300) 460 - 1314</p>
                        <p>fsd@nvisiondental.com</p>
                    </div>
                </div>
            </div>
            <div className='public_contact_form_inputs colorsecondarybg'>
                <div className='public_contact_form_inputs_row'>
                    <div className='public_contact_form_inputs_col50'>
                        <Primaryinput Lable={'First name'} Type={'text'} Value={firstname} setInput={setFirstname} />
                    </div>
                    <div className='public_contact_form_inputs_col50'>
                        <Primaryinput Lable={'Last name'} Type={'text'} Value={lastname} setInput={setLastname} />
                    </div>
                </div>
                <Primaryinput Lable={'Email'} Type={'text'} Value={email} setInput={setEmail} />
                <PrimarytextArea Lable={'You thoughts'} Value={message} setInput={setMessage} />
                <Primaryaltbtn Value={'Submit'}/>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Form
