import React from 'react'
import './index.css'

const Index = () => {
  return (
    <div className='public_about_quality_wrapper'>
      <div className='public_about_quality_container'>
        <h3>Quality Without Compromise</h3>
        <p>Quality is the cornerstone of our work philosophy. We use only the finest FDA-approved materials, combined with our mastery of digital design and traditional craftsmanship, to deliver dental restorations that surpass expectations. Rigorous quality control processes at every stage of production guarantee that every restoration meets our high standards, providing both dental professionals and patients with the confidence they deserve.</p>
      </div>
    </div>
  )
}

export default Index
