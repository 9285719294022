import ApiUrl from "../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = (setValues) => {
    fetch(ApiUrl.LabAdmin_Employee,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        setValues(data.response);
    });
}


const Post = (body,setValues,toast,toastid,reset) => {
    fetch(ApiUrl.LabAdmin_Employee,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            setTimeout(() => {
              toast.update(toastid, { render: "Added", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
            Get(setValues);
            reset();
        }
        else{
            setTimeout(() => {
              toast.update(toastid, { render: data.response, type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
        }
    });
}

const Put = (id,body,setValues,toast,toastid,reset) => {
    fetch(ApiUrl.LabAdmin_Employee+"/"+id,{
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            setTimeout(() => {
              toast.update(toastid, { render: "Updated", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
            Get(setValues);
            reset();
        }
        else{
            setTimeout(() => {
              toast.update(toastid, { render: data.response, type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
        }
    });
}


const Delete = (id,setValues,toast,toastid) => {
    fetch(ApiUrl.LabAdmin_Employee+"/"+id,{
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    .then(res=>res.json())
    .then(data =>{
        if(data.success){
            setTimeout(() => {
              toast.update(toastid, { render: "Deleted", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
            Get(setValues);
        }
        else{
            setTimeout(() => {
              toast.update(toastid, { render: data.response, type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
            }, 500);
        }
    });
}

export default {Get, Post, Put, Delete};