import React from 'react'
import HeroImage from '../../../images/public/33.png'
import PrimaryBtn from '../../../components/form/primarybtn'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className='public_home_hero_wrapper colorprimarybg'>
      <div className='public_home_hero_container'>
        <div className='public_home_hero_row'>
            <div className='public_home_hero_text'>
                <h3 className='colorwhite'>Welcome to <br></br>N Vision Digital Dental Laboratory</h3>
                <p className='colorwhite'>we specialize in creating high-quality, custom-made dental prosthetics to meet the unique needs of both patients and dentists.</p>
                <div className='public_home_hero_buttons'>
                    <Link to={'/services'}>
                        <PrimaryBtn Value={'Explore'}/>
                    </Link>
                </div>
            </div>
            <div className='public_home_hero_image'>
                <img src={HeroImage} alt='Home Hero'/>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
