import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

const Items = () => {
    const QNAs = [
        {
            question: "What is a N Vision Digital Dental Lab?",
            answer:"N Vision Digital Dental Lab is a specialized facility where dental technicians create custom dental restorations, such as crowns, bridges, dentures, and veneers, based on the specifications provided by dentists. These restorations are fabricated to improve the function, aesthetics, and overall oral health of patients."
        },
        {
            question: "How does a N Vision Digital Dental Lab work?",
            answer:"After receiving an order from a dentist, the dental lab technicians follow a precise process. They start by taking impressions or digital scans of the patient's teeth and gums. These impressions or scans are then used to create custom restorations in a dental lab. The technicians craft the restorations using high-quality materials, such as porcelain or composite, and employ various techniques like milling, shaping, and color matching. Finally, the completed restorations are sent back to the dentist for placement in the patient's mouth."
        },
        {
            question: "What types of dental restorations can N Vision Digital Dental Lab create?",
            answer:"N Vision Digital Dental Lab can create a wide range of dental restorations, including crowns, bridges, dentures, veneers, inlays, and onlays. These restorations can be made from different materials, such as porcelain, zirconia, or acrylic, depending on the specific needs and preferences of the patient."
        },
        {
            question: "How long does it take for N Vision Digital Dental Lab to complete a restoration?",
            answer:"The time required to complete a dental restoration can vary depending on the complexity of the case and the specific procedures involved. Typically, a restoration can take anywhere from a few days to a couple of weeks. However, advancements in technology and digital dentistry have reduced turnaround times in many cases, allowing for quicker delivery of restorations."
        },
        {
            question: "How can I ensure the quality of restorations from N Vision Digital Dental Lab?",
            answer:"To ensure the quality of dental restorations, we hire reputable, experienced and skilled technicians. Look for certifications, accreditations, and testimonials from dental professionals who have worked with the lab. Additionally, communication and collaboration between the dentist and N Vision Digital Dental Lab are crucial to achieving optimal results."
        },
        {
            question: "Can N Vision Digital Dental Lab repair or modify existing dental restorations?",
            answer:"Yes, N Vision Digital Dental Lab can often repair or modify existing dental restorations. If you have a damaged or ill-fitting dental restoration, consult with your dentist who can work in partnership with the N Vision Digital Dental Lab to assess the restoration's condition and determine the appropriate course of action for repair or modification."
        },
        {
            question: "How should I communicate with N Vision Digital Dental Lab?",
            answer:"Communication with N Vision Digital Dental Lab is typically facilitated through the dentist's office. Your dentist will provide the lab with the necessary information and instructions for your specific case. If you have any questions or concerns, it is best to discuss them with your dentist, who can then liaise with the dental lab on your behalf."
        }
    ]
  return (
    <div className='public_faq_wrapper'>
      <div className='public_faq_container'>
        {
            QNAs.map((item,index)=>{
                return(
                    <div key={index}>
                        <QNAItem Item={item}/>
                    </div>
                )
            })
        }
      </div>
    </div>
  )
}

const QNAItem = ({Item})=>{
    const [open,setOpen] = useState(false);
    return(
        <div className='public_faq_qna_container colorsecondarybg'>
            <div className='public_faq_qna_question' onClick={()=>{setOpen(!open);}}>
                <div className='public_faq_qna_question_row'>
                    <h3 className='colorprimary'>{Item.question}</h3>
                    <div className={open? 'public_faq_qna_question_arrow active':'public_faq_qna_question_arrow'}>
                        <FontAwesomeIcon icon={faArrowDown}/>
                    </div>
                </div>
            </div>
            <div className={open?'public_faq_qna_answer active':'public_faq_qna_answer'}>
                <p>{Item.answer}</p>
            </div>
        </div>
    )
}

export default Items
