import React from 'react'
import './index.css'
import { faRightFromBracket,faBoxesStacked, faCalculator, faCircleNodes, faEnvelope, faFile, faHouse, faMoneyBillTransfer, faTeethOpen, faTruck, faUser, faUserDoctor, faTableCellsLarge, faXmark } from '@fortawesome/free-solid-svg-icons'
import Item from './item'
import Watermark from './watermark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Index = ({mobileopen,setMobileOpen}) => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    const navList = [
        {name: 'Dashboard', url: '/labadmin/dashboard', icon:faHouse, slug: 'dashboard', subnav: []},
        {name: 'Orders', url: '/labadmin/orders', icon:faBoxesStacked, slug: 'orders', subnav: [
          {name: 'Status', url: '/labadmin/orders/status', slug: 'orders/status'},
          {name: 'Departments', url: '/labadmin/orders/departments', slug: 'orders/departments'}
        ]},
        //{name: 'Shipping', url: '/labadmin/shipping', icon:faTruck, slug: 'shipping', subnav: []},
        {name: 'Messages', url: '/labadmin/messages', icon:faEnvelope, slug:'messages', subnav:[]},
        {name: 'Expenses', url: '/labadmin/expenses', icon:faCalculator, slug:'expenses', subnav: []},
        {name: 'Billing', url: '/labadmin/billing', icon:faMoneyBillTransfer, slug:'billing', subnav: [
          {name: 'Payments', url: '/labadmin/billing/payments', slug: 'billing/payments'}
        ]},
        {name: 'Reports', url: '/labadmin/reports', icon:faFile, slug:'reports', subnav: []},
        {name: 'Entries', url: '/labadmin/entries/products', icon:faTableCellsLarge, slug:'entries', subnav:[
            {name: 'Products', url: '/labadmin/entries/products', slug: 'products'},
            {name: 'Materials', url: '/labadmin/entries/materials', slug: 'materials'},
            {name: 'Clinics', url: '/labadmin/entries/clinics', slug: 'clinics'},
            {name: 'Doctors', url: '/labadmin/entries/doctors', slug: 'doctors'},
            {name: 'Employees', url: '/labadmin/entries/employees', slug: 'employees'}
        ]}
    ]
  return (
    <div className={mobileopen?'admin_layout_leftbar_container active':'admin_layout_leftbar_container'}>
        <div className='admin_layout_leftbar_mobile_close'>
            <button onClick={()=>{
                setMobileOpen(false)
            }}>
                <FontAwesomeIcon icon={faXmark}/>
            </button>
        </div>
      <div className='admin_layout_leftbar_logout'>
          <Link to={'/signout'}>
              <div className='admin_layout_leftbar_logout_container'>
                  <div className='admin_layout_leftbar_logout_lable'>
                      <p>Logout</p>
                  </div>
                  <div className='admin_layout_leftbar_logout_icon'>
                      <FontAwesomeIcon icon={faRightFromBracket}/>
                  </div>
              </div>
          </Link>
      </div>
      <div className='admin_layout_leftbar_user_container'>
          <div className='admin_layout_leftbar_user_image_wrapper'>
              <div className='admin_layout_leftbar_user_image_container'>
                  <FontAwesomeIcon icon={faUser}/>
              </div>
          </div>
          <div className='admin_layout_leftbar_user_details'>
              <h4>{User.username}</h4>
              <p>{User.role}</p>
          </div>
      </div>
      <div className='admin_layout_leftbar_navigations'>
        {
            navList.map((item,index)=>{
                return(
                    <div key={index}>
                        <Item Item={item}/>
                    </div>
                )
            })
        }
      </div>
      <Watermark />
    </div>
  )
}

export default Index
