import React,{useState,useEffect} from 'react'
import Input from '../../../../../components/panelcontrols/panelinput'
import TextArea from '../../../../../components/panelcontrols/paneltextarea'
import Select from '../../../../../components/panelcontrols/panelselect'
import Button from '../../../../../components/panelcontrols/panelbuttonmain'
import Functions from '../function'
import DoctorFunctions from '../../../doctors/functions'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Index = ({selectedPayment,setSelectedPayment,toast,setList,setShowEdit}) => {
    const [description,setDescription] = useState('');
    const [doctor,setDoctor] = useState({value:0,name:'Select doctor'});
    const [doctorlist,setDoctorList] = useState([]);
    const [amount,setAmount] = useState(0);
    const [date,setDate] = useState('');
    useEffect(()=>{
        if(selectedPayment){
            setDescription(selectedPayment.description);
            setAmount(selectedPayment.amount);
            setDate(selectedPayment.date);
            DoctorFunctions.Get((data)=>{
              const sendData = [];
              for (let index = 0; index < data.length; index++) {
                const element = data[index];
                sendData.push({
                  value : parseInt(element.id),
                  name : element.firstname + " " + element.lastname + " / " + element.clinicname
                })
                if(element.id === selectedPayment.doctor){
                    setDoctor({
                        value : parseInt(element.id),
                        name : element.firstname + " " + element.lastname + " / " + element.clinicname
                    })
                }
              }
              setDoctorList(sendData)
            })
        }
        else{
            setDescription('');
            setDate('');
            setAmount(0);
            setDoctor({value:0,name:'Select doctor'});
        }
    },[selectedPayment])
    const reset = ()=>{
        setSelectedPayment(null)
        setShowEdit(false)
    }
  return (
    <div className='labadmin_product_edit_wrapper'>
        <div className='labadmin_product_edit_container'>
            <div className='labadmin_product_edit_close'>
                <button onClick={()=>{
                   reset(); 
                }}>
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            <div className='labadmin_product_formbox'>
                <h3>Edit Payment</h3>
                <div className='admin_billing_row'>
                <div className='admin_billing_col33'>
                    <Select Lable={'Doctor'} Value={doctor} setValue={setDoctor} Options={doctorlist}/>
                </div>
                <div className='admin_billing_col33'>
                    <Input Lable={'Date'} Type={'date'} Value={date} setValue={setDate}/>
                </div>
                <div className='admin_billing_col33'>
                    <Input Lable={'Amount'} Type={'number'} Value={amount} setValue={setAmount}/>
                </div>
                </div>
                <TextArea Lable={'Description'} Type={'text'} Value={description} setValue={setDescription}/>
                <Button Value={'Save'} Action={()=>{
                    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_CENTER});
                    if(doctor.value === 0 || amount === 0 || date === ''){
                      toast.update(toastid, { render: "Fields Empty", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_CENTER });
                      return;
                    }
                    Functions.Put(selectedPayment.id,{
                        doctor: doctor.value,
                        amount: amount,
                        date: date,
                        description: description
                    },setList,toast,toastid,reset)
                }}/>
            </div>
        </div>
    </div>
  )
}

export default Index
