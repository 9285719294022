import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './header'
import '../../css/public/layout.css'
import '../../css/public/components.css'
import Topbar from './topbar'
import Footer from './footer'
import Bottombar from './bottombar'
import './index.css'

const Index = () => {
  return (
    <div>
        <Topbar/>
        <Header/>
        <Outlet />
        <Footer/>
        <Bottombar/>
    </div>
  )
}

export default Index
