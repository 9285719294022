import React,{useState,useEffect} from 'react'
import Title from '../../../components/panelcontrols/paneltitle'
import './index.css'
import Functions from './function'
import DoctorFunctions from '../doctors/functions'
import UserList from './userList'
import MessageList from './messageList'

const Index = () => {
    const [user,setUser] = useState({value:0,name:'Select type'});
    const [userList,setUserList] = useState([]);
    useEffect(()=>{
      DoctorFunctions.Get((data)=>{
        const sendData = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          sendData.push({
            value : parseInt(element.user),
            name : element.firstname + " " + element.lastname + " / " + element.clinicname
          })
        }
        setUserList(sendData)
      })
    },[])
  return (
    <div className='labadmin_messages_container'>
        <Title Value={'Messages'}/>
        <div className='labadmin_messages_formbox'>
            <div className='labadmin_messages_row'>
                <div className='labadmin_messages_col30' style={{borderRight:"1px dashed #ededed"}}>
                    <UserList Value={user} setValue={setUser} Options={userList}/>
                </div>
                <div className='labadmin_messages_col70'>
                  {user.value !== 0 &&
                    <MessageList User={user.value}/>
                  }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Index
